import React, { useState } from "react";
import user from "../../../src/assets/user.png";
import cross from "../../assets/cross.svg";
import gender from "../../assets/gender.svg";
import imgupload from "../../assets/imgupload.svg";
import fileimport from "../../assets/fileimport.svg";
import addpatient from "../../assets/addpatient.svg";
import help from "../../assets/help.svg";
import sidevector from "../../assets/sidevector.svg";
import patientname from "../../assets/patientname.svg";
import emailid from "../../assets/emailid.svg";
import contact from "../../assets/contact.svg";
import patientage from "../../assets/patientage.svg";
import patientadhar from "../../assets/patientadhar.svg";
import labnumber from "../../assets/labnumber.svg";
import place from "../../assets/place.svg";
import patientid from "../../assets/patientid.svg";
import axios from "axios";
import API_ENDPOINTS from "../../service/api";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import useEmailValidation from '../../Hooks/useEmailValidation';
import { translations } from "../../translation/translate";
import { addPatient } from "../../Redux_Services/slices/patientSlice";
import AddPatientConfirmationModal from "../../Components/addPatientConfirmationModal";

import FAQ from "../FAQ";
import { number } from "yup";
const AddPatient = () => {
  const navigate = useNavigate();
  const login = useSelector((state) => state.authSlice.user);
  const [selectedGender, setSelectedGender] = useState("");
  const langData = useSelector((state) => state.langSlice.langData || []);
  const [loading, setLoading] = useState(false);
  const [locationFetching, setLocationFetching] = useState(false);
  const [location, setLocation] = useState("");
  //adding empty error in DOM
  const [error, setError] = useState("");
  //adding state to sotre temp capture image
  //adding state to sotre temp capture image
  const [imagePreview, setImagePreview] = useState(null);
  const [showUploadOptions, setShowUploadOptions] = useState(false);
  const [cameraMode, setCameraMode] = useState(false);
  const [modal, setModal] = useState(false);
  // const [Payload, setpayload] = useState({});
  // const [labNo, setLabNo] = useState("")
  const labNo = login.CentreID;
  // console.log("31", location);
  // console.log("login = ", login.CentreID);
  const handleRadio = (e) => {
    const { value } = e.target;
    setSelectedGender(value);
    setForm((form) => ({
      ...form,
      Gender: value, // Update Gender in form state
    }));
  };
  const [form, setForm] = useState({
    Adhaar: "",
    imageURL: null,
    dob: "",
    EmailId: "",
    Gender: selectedGender,
    Name: "",
    Place: "",
    labNo: "" || labNo,
    contactNumber: "",
  });

  // const [imagePreview, setImagePreview] = useState(null);
  const dispatch = useDispatch();
  console.log("form = ", form);
  function handleChangeEvent(event) {
    event.preventDefault();

    const { name, value } = event.target;

    setForm((form) => ({
      ...form,
      [name]: value,
    }));
  }
  const { validateEmail } = useEmailValidation();
  // modal functions
  const modalOpen = async () => {
    const isValidPhoneNumber = (number) => /^\d{10}$/.test(number.trim());
    const isValidAadhaar = (aadhaar) => /^\d{12}$/.test(aadhaar.trim());
    const isEmptyField = (field) => !field || field.trim() === "";
    const isValidGender = (gender) => ["male", "female", "other"].includes(gender);
    const isValidPlace = (place) => !isEmptyField(place);
    
    const isValidDate = (dateStr) => {
      const date = new Date(dateStr);
      const today = new Date();
      const matchesFormat = /^\d{4}-\d{2}-\d{2}$/.test(dateStr);
      const isNotFutureDate = date <= today;
      return !isNaN(date) && matchesFormat && isNotFutureDate;
    };
  
    const fieldLabels = {
      dob: "Date of birth",
      EmailId: "Email ID",
      contactNumber: "Contact number",
      name: "Name",
      address: "Address",
    };
  
    let isValid = true;
  
    if (isValid) {
      if (!isValidDate(form.dob)) {
        toast.error(translations[langData].dobError || "Invalid date of birth format", {
          position: toast.POSITION.TOP_CENTER,
        });
        isValid = false;
      }
  
      const isEmail = validateEmail(form.EmailId); // Use the new custom hook
      if (isEmail !== "DELIVERABLE") {
        toast.error(translations[langData].emailError || isEmail, {
          position: toast.POSITION.TOP_CENTER,
        });
        isValid = false;
      }
  
      if (!isValidGender(form.Gender)) {
        toast.error(translations[langData].genderError || "Please select a valid gender", {
          position: toast.POSITION.TOP_CENTER,
        });
        isValid = false;
      }
  
      if (!isValidPlace(form.Place)) {
        toast.error(translations[langData].placeError || "Place cannot be empty", {
          position: toast.POSITION.TOP_CENTER,
        });
        isValid = false;
      }
  
      if (!isValidPhoneNumber(form.contactNumber)) {
        toast.error(translations[langData].phoneError || "Invalid phone number (must be 10 digits)", {
          position: toast.POSITION.TOP_CENTER,
        });
        isValid = false;
      }
    }
  
    if (isValid) {
      setModal(true);
    } else {
      setLoading(false);
    }
  };

  const modalClose = () => {
    setModal(false);
  };

  const handleLocationRequest = () => {

    if(form.Place != ""){
      console.error("user is typing");
    }else{
      setLocationFetching(true);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            await fetchLocation(latitude, longitude);
            setLocationFetching(false);
          },
          (error) => {
            console.error("Error getting location:", error);
            setLocationFetching(false);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        setLocationFetching(false);
      }
    }
  };

  function handleChangeEvent(event) {
    event.preventDefault();
    const { name, value } = event.target;
    setForm((form) => ({
      ...form,
      [name]: value,
    }));
  }
  //capture photo

  const handleCapturePhoto = async () => {
    try {
      setCameraMode(true);
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const videoElement = document.querySelector("#video");
      videoElement.srcObject = stream;
    } catch (error) {
      console.error("Error accessing the camera:", error);
    }
  };

  // const captureImage = () => {
  //   const videoElement = document.querySelector("#video");
  //   const canvas = document.createElement("canvas");
  //   const context = canvas.getContext("2d");
  //   canvas.width = videoElement.videoWidth;
  //   canvas.height = videoElement.videoHeight;

  //   // Draw the video frame to the canvas
  //   context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

  //   // Convert the canvas to a data URL
  //   const imageDataUrl = canvas.toDataURL("image/png");
  //   setImagePreview(imageDataUrl);

  //   // Stop the video stream
  //   const stream = videoElement.srcObject;
  //   const tracks = stream.getTracks();
  //   tracks.forEach((track) => track.stop());

  //   setCameraMode(false); // Close camera mode
  //   setShowUploadOptions(false); // Close the modal
  //   console.log("255", imageDataUrl);
  // };
  const captureImage = async () => {
    const videoElement = document.querySelector("#video");
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
    const imageDataUrl = canvas.toDataURL("image/png");
    setImagePreview(imageDataUrl);
    const stream = videoElement.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
    setCameraMode(false);
    console.log("270", imageDataUrl);
    // Send the image to the backend
    try {
      const response = await axios.post(
        "https://api.superceuticals.in/patient/techPatientProfileUpload",
        { image: imageDataUrl },
        {
          headers: {
            Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MzU1NTAwMTB9.7LEid4GhspeECi_et8emoL9sPsDWsOEjAntt1RJzjTY`,
          },
        }
      );
      console.log("Image uploaded successfully:", response.data);
      if (response.data) {
        console.log("284");
        setShowUploadOptions(false); // Close the modal
        setCameraMode(false);
        console.log(response.data.imageURL);
        setForm((prevForm) => ({
          ...prevForm,
          imageURL: response.data.imageURL, // Update the Image field
        }));
      }
    } catch (error) {
      console.error("Error uploading the image:", error);
    }
  };
  const handleChooseFromFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setShowUploadOptions(false); // Close the modal after choosing file
  };
  const fetchLocation = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
      );
      const address = response.data.display_name;
      form.Place = address;
      form.Place = address;
      setLocation(address); // Display the address in the input field
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  // const handleChooseFromFile = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setImagePreview(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  //   setShowUploadOptions(false); // Close the modal after choosing file
  // };

  // const fetchLocation = async (latitude, longitude) => {
  //   try {
  //     const response = await axios.get(
  //       `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
  //     );
  //     const address = response.data.display_name;
  //     form.Place = address;
  //     form.Place = address;
  //     setLocation(address); // Display the address in the input field
  //   } catch (error) {
  //     console.error("Error fetching location:", error);
  //   }
  // };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  async function handleSubmit() {
    setLoading(true);

    // Helper functions for validations
    const isValidEmail = (email) =>
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim());
    const isValidPhoneNumber = (number) => /^\d{10}$/.test(number.trim());
    const isValidAadhaar = (aadhaar) => /^\d{12}$/.test(aadhaar.trim());
    const isEmptyField = (field) => !field || field.trim() === "";

    const isValidDate = (dateStr) => {
      console.log("__________________ dateStr", dateStr);
      const date = new Date(dateStr);
      const matchesFormat = /^\d{4}-\d{2}-\d{2}$/.test(dateStr); // Use .test() for boolean return
      console.log(
        "__________________ dateStr",
        date,
        !isNaN(date),
        matchesFormat
      );
      console.log("__________________ dateStr", !isNaN(date) && matchesFormat);
      return !isNaN(date) && matchesFormat;
    };

    // Validation checks
    if (
      Object.keys(form).some(
        (key) => key !== "Adhaar_Image" && isEmptyField(form[key])
      )
    ) {
      toast.error(translations[langData].emptyPatientDetails, {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
      return;
    }

    if (!isValidAadhaar(form.Adhaar)) {
      toast.error(
        translations[langData].AdhaarError || "Invalid Aadhaar number",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      setLoading(false);
      return;
    }

    // Use it before calculating age
    console.log(
      "_______________________ testing the code dob",
      !isValidDate(form.dob)
    );
    if (!isValidDate(form.dob)) {
      toast.error(
        translations[langData].dobError || "Invalid date of birth format",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      setLoading(false);
      return;
    }

    if (!isValidEmail(form.EmailId)) {
      toast.error(
        translations[langData].emailError || "Invalid email address",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      setLoading(false);
      return;
    }

    if (!isValidPhoneNumber(form.contactNumber)) {
      toast.error(
        translations[langData].phoneError ||
          "Invalid phone number (must be 10 digits)",
        { position: toast.POSITION.TOP_CENTER }
      );
      setLoading(false);
      return;
    }

    // Calculate age from date of birth
    const calculateAge = (dob) => {
      const dobDate = new Date(dob);
      const today = new Date();
      let age = today.getFullYear() - dobDate.getFullYear();
      const monthDiff = today.getMonth() - dobDate.getMonth();

      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < dobDate.getDate())
      ) {
        age--;
      }
      return age;
    };

    const age = calculateAge(form.dob);

    // Prepare payload
    const payload = {
      Adhaar: form.Adhaar,
      Image:form.Adhaar_Image || "https://adharimg.s3.ap-south-1.amazonaws.com/adhaar/Report-1725361112006.jpeg",
      Age: age,
      EmailId: form.EmailId,
      Gender: form.Gender,
      Name: form.Name,
      Place: form.Place,
      contactNumber: form.contactNumber,
    };

    // API request
    try {
      const resp = await axios.post(API_ENDPOINTS.addPatient, payload, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer YOUR_TOKEN_HERE", // Replace with actual token
        },
      });

      if (!resp?.data.error) {
        toast.success(translations[langData].toastPatientAdded, {
          position: toast.POSITION.TOP_CENTER,
        });

        const url = `https://api.superceuticals.in/patient/search-patient/${form.contactNumber}`;
        const response2 = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MjcxMDQzMTd9.rW70KCsDPR9DU8SDRPDxtTFcX2mT2nb_1lZoYgoVzEk", // Replace with your correct Bearer token
          },
        });
        // console.log("33", response);

        if (!response2.ok) {
          throw new Error(`HTTP error! status: ${response2.status}`);
        }

        const result = await response2.json();
        // console.log("result: ",result);
        // console.log("patientID: ",result.patients[0].PatientID);
        form.PatientID = result.patients[0].PatientID;

        payload.PatientID = result.patients[0].PatientID;

        dispatch(addPatient(payload));
        navigate("/tests");
        alert("Patient added successfully");
      } else {
        throw new Error(
          translations[langData].toastFailedToAddPatient ||
            "Failed to add patient"
        );
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="bg-[#C7CEE5] min-h-screen h-full">
      <div className=" p-4 sm:p-8 lg:py-10 lg:px-28">
        {/* Add Patient Form */}
        <div className="bg-white rounded-3xl h-[70%] w-full  shadow-lg p-6 lg:p-8 mx-auto z-10 relative">
          <Link
            to="/add-patient"
            className="flex justify-end -mt-8 -mr-8 lg:mt-[-20px] lg:mr-[-20px]"
          >
            <img src={cross} alt="crossicon" />
          </Link>

          <h1 className="text-2xl sm:text-3xl lg:text-4xl font-semibold text-[#9669E0] mb-6">
            {translations[langData].addPatient}
          </h1>
          <div className="grid grid-cols-1 mr-24 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-12 lg:mt-20">
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={patientname} alt="patientnameicon" />
              </span>
              <input
                name="Name"
                required
                type="text"
                value={form.Name}
                onChange={handleChangeEvent}
                placeholder={translations[langData].name}
                className="pl-10 pr-4 p-3 w-80 h-12 rounded-lg bg-[#D7D4F8] outline-none"
              />
            </div>

            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={patientid} alt="patientnameicon" />
              </span>
              <input
                name="Adhaar"
                type="number"
                value={form.Adhaar}
                onChange={handleChangeEvent}
                placeholder={translations[langData].adhaarNo || ""}
                maxLength="12" // Limit the input to 12 characters
                onInput={(e) => {
                  //adding validation in adhaar
                  if (e.target.value.length > 12) {
                    e.target.value = e.target.value.slice(0, 12); // Prevent more than 12 characters
                  }
                }}
                className="pl-10 pr-4 p-3 w-80 h-12 rounded-lg bg-[#D7D4F8] outline-none"
              />
            </div>

            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={labnumber} alt="patientnameicon" />
              </span>
              <input
                type="text"
                value={labNo || form.labNo}
                onChange={(e) => setForm({ ...form, labNo: e.target.value })}
                placeholder={translations[langData].labNo}
                className="pl-10 pr-4 p-3 w-40 h-12 rounded-lg bg-[#D7D4F8] outline-none"
              />
            </div>

            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={patientage} alt="patientnameicon" />
              </span>
              <input
                name="dob" // Changed name to dob
                type="date" // Changed type to date
                value={form.dob} // Changed from form.Age to form.dob
                onChange={handleChangeEvent}
                placeholder="Select date of birth" // Updated placeholder
                className="pl-10 pr-4 p-3 w-40 h-12 rounded-lg bg-[#D7D4F8] outline-none"
              />
            </div>

            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={emailid} alt="patientnameicon" />
              </span>
              <input
                name="EmailId"
                type="text"
                value={form.EmailId}
                onChange={handleChangeEvent}
                placeholder={translations[langData].email}
                className="pl-10 pr-4 p-3 w-72 h-12 rounded-lg bg-[#D7D4F8] outline-none"
              />
            </div>
            {/* 
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={patientid} alt="patientnameicon" />
              </span>
              <input
                name="PatientId"
                type="text"
                onChange={handleChangeEvent}
                placeholder="Patient ID"
                className="pl-10 pr-4 p-3 w-40 h-12 rounded-lg bg-[#D7D4F8] outline-none"
              />
            </div>  */}

            {/* Gender */}
            <div className="flex text-sm items-center space-x-2 mt-4">
              <img src={gender} alt="gendericon" />
              <span className="font-bold text-[#5C5CAB99]">Gender</span>
              <label className="space-x-0 cursor-pointer">
                <input
                  type="radio"
                  required
                  onChange={handleRadio}
                  name="Gender"
                  value="male"
                  checked={selectedGender === "male"}
                />
                <span>MALE</span>
              </label>
              <label className="space-x-0 cursor-pointer">
                <input
                  type="radio"
                  required
                  onChange={handleRadio}
                  name="Gender"
                  value="female"
                  checked={selectedGender === "female"}
                />
                <span>FEMALE</span>
              </label>
              <label className="space-x-0 cursor-pointer">
                <input
                  type="radio"
                  required
                  onChange={handleRadio}
                  name="Gender"
                  value="other"
                  checked={selectedGender === "other"}
                />
                <span>OTHER</span>
              </label>
            </div>

            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={place} alt="placeicon" />
              </span>
              <input
                name="Place"
                type="text"
                value={location || form.Place} // Display address or form value
                onChange={(e) => setForm({ ...form, Place: e.target.value })}
                onClick={handleLocationRequest} // Trigger location request on click
                placeholder={translations[langData].place}
                className="pl-10 pr-4 p-3 w-80 h-12 rounded-lg bg-[#D7D4F8] outline-none"
              />
              
            </div>

            <div className="relative ">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={contact} alt="patientnameicon" />
              </span>

              {/* Country code prefix with margin */}
              <span className="absolute inset-y-0 left-8 flex items-center text-black ml-1">
                +91
              </span>

              {/* Input field with adjusted padding to accommodate icon and prefix */}
              <input
                name="contactNumber"
                type="text" // Use type="text" to have more control over input validation
                required
                value={form.contactNumber}
                onChange={(e) => {
                  // Allow only digits
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleChangeEvent(e); // Call the existing event handler
                  }
                }}
                placeholder={translations[langData].phone}
                className="pl-16 ml-0 pr-4 p-3 w-80 h-12 rounded-lg bg-[#D7D4F8] outline-none"
                maxLength="10" // Limits the length of the input to 10 digits
              />
            </div>
          </div>

          {/* Upload Patient Photo */}
          <div className="absolute flex flex-col items-center right-4 lg:right-10 top-4 lg:top-[69px]">
            <div className="w-24 h-24 bg-[#D7D4F8] rounded-full flex border-4 border-[#9669E0] items-center justify-center overflow-hidden">
              <img
                src={imagePreview || user} // Display uploaded image or default image
                alt="Patient"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Upload Options */}
            <div className="flex mt-2 items-center space-x-2">
              <img src={imgupload} alt="Upload Icon" />
              <button
                onClick={() => setShowUploadOptions(true)} // Show upload options
                className="text-sm lg:text-base text-[#9669E0] cursor-pointer"
              >
                Upload Patient Photo
              </button>
            </div>
          </div>

          {/* Upload Options Modal */}
          {showUploadOptions && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
              <div className="bg-white rounded-lg p-6 w-96">
                <h3 className="text-lg font-semibold mb-4 text-center">
                  Choose an Option
                </h3>
                <div className="flex flex-col space-y-4">
                  <button
                    onClick={handleCapturePhoto}
                    className="flex items-center justify-center p-4 border rounded-lg bg-gray-100 hover:bg-gray-200"
                  >
                    <span className="text-[#9669E0] font-semibold">
                      Capture Photo
                    </span>
                    <span className="text-[#9669E0] font-semibold">
                      Capture Photo
                    </span>
                  </button>
                  <label className="flex items-center justify-center p-4 border rounded-lg cursor-pointer bg-gray-100 hover:bg-gray-200">
                    <span className="text-[#9669E0] font-semibold">
                      Choose from File
                    </span>
                    <span className="text-[#9669E0] font-semibold">
                      Choose from File
                    </span>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleChooseFromFile}
                      className="hidden"
                    />
                  </label>
                  <button
                    onClick={() => setShowUploadOptions(false)} // Close modal
                    className="text-sm font-bold bg-red-500 text-white p-2 rounded-lg"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Camera Mode */}
          {cameraMode && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
              <div className="bg-white rounded-lg p-6 w-full lg:w-96">
                <video
                  id="video"
                  autoPlay
                  className="w-full h-auto rounded-lg"
                ></video>
                <div className="flex justify-between mt-4">
                  <button
                    onClick={captureImage}
                    className="bg-green-500 text-white px-4 py-2 rounded-lg"
                  >
                    Capture
                  </button>
                  <button
                    onClick={() => setCameraMode(false)} // Close camera
                    className="bg-red-500 text-white px-4 py-2 rounded-lg"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start">
            {/* File Upload */}
            {/* <div className="mt-6 p-4 bg-[#D7D4F8] w-full lg:w-[45%] rounded-lg flex items-center justify-center flex-col">
              <img src={fileimport} alt="importicon" />
              <p className="text-sm lg:text-base mt-2">
                {" "}
                {translations[langData].uploadReport}
              </p>
              <p className="text-sm lg:text-base">OR</p>
              <button className="mt-2 w-28 lg:w-[111.55px] h-10 px-4 py-2 bg-[#9669E0] text-white rounded-lg">
                {translations[langData].addPatient}
              </button>
              <p className="mt-2 text-xs lg:text-[9.12px] text-black text-center">
                * {translations[langData].providedAadhaarCard}
              </p>
            </div> */}

            {/* Add Patient Button */}
            <button
              onClick={modalOpen}
              className="mt-6  lg:mt-32 text-sm lg:text-base font-bold h-12 w-56  px-8 py-3 bg-[#9669E0] text-white rounded-lg flex items-center justify-center space-x-3 lg:space-x-5"
            >
              <p>{translations[langData].addPatient} </p>
              <img src={addpatient} alt="addpatienticon" />
            </button>
            {error ? (
              <>
                <div className="">
                  {error && (
                    <p className="mt-6  lg:mt-32 text-sm lg:text-base font-bold h-12 w-56  px-8 py-3 bg-[red] text-white rounded-lg flex items-center justify-center space-x-3 lg:space-x-5">
                      *{error}
                    </p>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* Background Image */}

        {/* Help Icon */}
        <Link to="../faq" className="fixed z-10 bottom-8 right-28 ">
          <img src={help} alt="helpicon" className="w-10 lg:w-12" />
        </Link>
      </div>
      <ToastContainer />
      <div className="fixed bottom-0 right-0 z-0 w-full lg:w-auto">
        <img src={sidevector} alt="sidevector" className="w-full lg:w-auto" />
      </div>

      <AddPatientConfirmationModal
        isOpen={modal}
        isClose={modalClose}
        formData={form}
      />
      
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="w-10 h-10 border-4 border-t-red-500 border-gray-300 rounded-full animate-spin"></div>
        </div>
      )}

      {locationFetching && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="flex flex-col items-center">
            <div className="w-10 h-10 border-4 border-t-red-500 border-gray-300 rounded-full animate-spin"></div>
            <div className="text-white text-lg mt-2">Fetching Location...</div>
          </div>
        </div>
      )}

    </div>
  );
};

export default AddPatient;
