import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addReferedBy, addTests } from "../../Redux_Services/slices/testSlice";
import { useNavigate } from "react-router-dom";
import useRiskScoreAssessment from "../../Hooks/SwassariskscoreAssessment";

const subFields = [
  {
    fieldId: "",
    fieldName: "Dry Cough Count",
    fieldObjectName: "dry_cough_count",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Wet Cough Count",
    fieldObjectName: "wet_cough_count",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "COPD Summary",
    fieldObjectName: "COPD_Summary",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "COPD Risk Score",
    fieldObjectName: "COPD_risk_score",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Asthma Risk Score",
    fieldObjectName: "asthma_risk_score",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Asthma Severity",
    fieldObjectName: "asthma_severity",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Covid Risk",
    fieldObjectName: "covid_risk",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Covid Summary",
    fieldObjectName: "covid_summary",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Cough Risk Score",
    fieldObjectName: "cough_risk_score",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "TB Likely",
    fieldObjectName: "tb_likely",
    unitAndRange: "",
    value: "",
  },

  {
    fieldId: "",
    fieldName: "Cough Pattern",
    fieldObjectName: "cough_pattern",
    unitAndRange: "",
    value: "",
  },

  {
    fieldId: "",
    fieldName: "Risk",
    fieldObjectName: "risk",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "COPD Likely",
    fieldObjectName: "COPD_likely",
    unitAndRange: "",
    value: "",
  },

  {
    fieldId: "",
    fieldName: "COPD Severity",
    fieldObjectName: "COPD_severity",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Covid Risk Score",
    fieldObjectName: "covid_risk_score",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "TB Risk",
    fieldObjectName: "tb_Risk",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "TB Risk Score",
    fieldObjectName: "tb_RiskScore",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "TB Summary",
    fieldObjectName: "tb_Summary",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Asthma Summary",
    fieldObjectName: "asthma_Summary",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "LHI",
    fieldObjectName: "lhi",
    unitAndRange: "",
    value: "",
  },
];

export default function TestParameter() {
  const patientDetails = useSelector((state) => state.patientSlice.patientData);
  const tests = useSelector((state) => state.testSlice.testsData || []);
  const referedBy = useSelector((state) => state.testSlice.referedBy || "");
  const [testData, setTestData] = useState(tests);
  const [referby, setReferby] = useState(referedBy);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const coughData = useSelector((state) => state.diseaseSlice);
  const [apiResponse, setApiResponse] = useState({});
  const response = useRiskScoreAssessment();
  const height = useRef();
  const weight = useRef();
  const wet = useRef();

  const fetchData = async () => {
    console.log(response)
      try {
        if (response.status === 200) {
          console.log("Data submitted successfully", response);
          const data = response.data.data;
          delete data.height;
          delete data.weight;
          setApiResponse(data);
          // testData[0].fields[0].subFields = subFields;
          // console.log("Test Data", testData[0]);
          const updatedTestData = testData.map((test, testIndex) => {
            if (testIndex === 0) {
              // Assuming you're updating the first test's fields
              return {
                ...test,
                fields: test.fields.map((field, fieldIndex) => {
                  if (fieldIndex === 0) {
                    // Assuming you're updating the first field
                    return {
                      ...field,
                      subFields: [...subFields], // Update subFields immutably
                    };
                  }
                  return field;
                }),
              };
            }
            return test;
          });

          const updatedValue = updatedTestData.map((test, testIndex) => {
            if (testIndex === 0) {
              return {
                ...test,
                fields: test.fields.map((field, fieldIndex) => {
                  if (fieldIndex === 0) {
                    return {
                      ...field,
                      subFields: field.subFields.map(
                        (subField, subFieldIndex) => {
                          console.log(subField.fieldName);
                          // if (subField.fieldName === "Height") {
                          //   return false;
                          // } else if (subField.fieldName === "Weight") {
                          //   return false
                          const updatedValue =
                            response.data.data[subField.fieldObjectName] ||
                            subField.value;
                          if (subField.fieldName === "Wet Cough Count") {
                            return {
                              ...subField,
                              value: "0",
                            };
                          }
                          // // Check if apiResponse has the key corresponding to fieldObjectName
                          // else {
                          else {
                            return {
                              ...subField,
                              value: updatedValue, // Update the value
                            };
                          }
                          // }
                        }
                      ),
                    };
                  }
                  return field; // Preserve other fields if fieldIndex !== 0
                }),
              };
            }
            return test; // Preserve other tests if testIndex !== 0
          });

          setTestData(updatedValue); // Update state
          console.log("Test Data", updatedValue[0]);
        } else {
          console.error("Error:", response.status, response.statusText);
        }
      } catch (err) {
        console.error("Error submitting data:", err);
      }
  };
  useEffect(() => {
    fetchData(response);
    console.log("api data", apiResponse);
  }, [apiResponse, response]);
  
  function replaceSpacesWithUnderscores(inputString) {
    return inputString.replace(/ /g, "_");
  }
  const add_subfields = () => {
    const updatedValue = testData.map((test, testIndex) => {
      if (testIndex === 0) {
        return {
          ...test,
          fields: test.fields.map((field, fieldIndex) => {
            if (fieldIndex === 0) {
              return {
                ...field,
                subFields: field.subFields.map((subField, subFieldIndex) => {
                  if (subField.fieldName === "Height") {
                    return {
                      ...subField,
                      value: height.current, // Update the value
                    };
                  } else if (subField.fieldName === "Weight") {
                    return {
                      ...subField,
                      value: weight.current, // Update the value
                    };
                  } else if (subField.fieldName === "Wet Cough Count") {
                    return {
                      ...subField,
                      value: wet.current, // Update the value
                    };
                  } else {
                    return {
                      ...subField,
                    };
                  }
                }),
              };
            }
            return field; // Preserve other fields if fieldIndex !== 0
          }),
        };
      }
      return test; // Preserve other tests if testIndex !== 0
    });
    console.log(updatedValue);
    setTestData(updatedValue); // Update state
  };
  
  useEffect(() => {
    const fetchData = () => {
      if (response?.status === 200) {
        setApiResponse(response.data.data);
        const updatedTestData = testData.map((test, testIndex) => {
          if (testIndex === 0) {
            return {
              ...test,
              fields: test.fields.map((field, fieldIndex) => {
                if (fieldIndex === 0) {
                  return {
                    ...field,
                    subFields: subFields.map((subField) => ({
                      ...subField,
                      value: response.data.data[subField.fieldObjectName] || subField.value,
                    })),
                  };
                }
                return field;
              }),
            };
          }
          return test;
        });
        setTestData(updatedTestData);
      } else {
        console.error("Error fetching data", response);
      }
    };

    fetchData();
  }, [response, testData]);

  const handleGenerateReport = (e) => {
    e.preventDefault();
    dispatch(addTests(testData));
    dispatch(addReferedBy(referby));
    navigate("/tests/testId/report");
  };

  return (
    <div className="test-parameter mt-10 flex w-full items-center justify-center" style={{ fontFamily: "poppins" }}>
      <form onSubmit={handleGenerateReport} className="w-4/5 bg-white rounded-lg shadow-sm">
        <div className="grid grid-cols-4 bg-purple-600 text-white text-sm py-4 px-4 rounded-t-lg">
          <div className="font-medium">TEST CATEGORY</div>
          <div className="font-medium">TEST NAME</div>
          <div className="font-medium">RESULTS</div>
          <div className="font-medium">UNIT & RANGE</div>
        </div>
        <div className="p-4">
          <table className="w-full border-separate border-spacing-0">
            <tbody>
              {Object.entries(apiResponse).map(([key, value]) => (
                <tr key={key} className="border-b border-blue-100">
                  <td className="text-purple-600 py-2 w-1/4 pl-4">{key.toUpperCase()}</td>
                  <td className="py-2 px-4">
                    <input
                      disabled
                      type="text"
                      name={key}
                      value={key === "patientId" ? patientDetails?.PatientID : value}
                      className="h-8 w-full px-3 rounded bg-purple-100 focus:bg-purple-50 focus:outline-none focus:ring-1 focus:ring-purple-400"
                      placeholder={`Enter ${key}`}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between pt-6 mt-4 border-t border-gray-200">
          <div className="flex items-center mx-4 gap-4">
            <span className="text-purple-600">REFERRED BY:</span>
            <input
              type="text"
              name="referredBy"
              value={referby}
              onChange={(e) => setReferby(e.target.value)}
              className="w-48 h-8 px-3 rounded bg-purple-100 focus:bg-purple-50 focus:outline-none focus:ring-1 focus:ring-purple-400"
              placeholder="Enter name"
            />
          </div>
          <button
            type="submit"
            style={{ backgroundColor: '#6B46C1' }}
            className="m-4 text-white font-bold py-2 px-4 rounded"
          >
            GENERATE REPORT
          </button>
        </div>
      </form>
    </div>
  );
}
