import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_ENDPOINTS from '../../../service/api';
import { useSelector } from 'react-redux';

export default function Confirmation() {
    const [paymentStatus, setPaymentStatus] = useState('processing');
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate();
    const UserData = useSelector((state) => state.authSlice.user);
      
    useEffect(() => {
        // Get transaction ID from URL path  
        const query = new URLSearchParams(window.location.search);
        const transactionId = query.get('transactionId');

        if (!transactionId) {
            setError('Transaction ID not found');
            return;
        }
        console.log("transactionId: ",transactionId);

        let progressInterval;
        let attempts = 0;
        const maxAttempts = 20; // Maximum number of attempts (10 seconds total)

        const checkPaymentStatus = async () => {
            try {
                // Validate transaction ID format
                if (transactionId.length < 25 || transactionId.length > 35) {
                    setError('Invalid transaction ID format');
                    clearInterval(progressInterval);
                    return;
                }

                const regex = /^[a-zA-Z0-9_-]+$/;
                if (!regex.test(transactionId)) {
                    setError('Invalid transaction ID format');
                    clearInterval(progressInterval);
                    return;
                }

                const response = await axios.post(
                    API_ENDPOINTS.getPaymentStatus,
                    { transactionId },
                    {
                        headers: {
                            Authorization: `Bearer ${UserData.token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );
              
                if (response.data) {
                    console.log('Payment verification response:', response.data);
                    
                    if (response.data.error === false || !response.data.msg == "Your payment is successful.") {
                        // Payment successful
                        clearInterval(progressInterval);
                        navigate('/general-test');
                        return;
                    } else if (response.data.error === true || response.status === 400 || response.status === 402) {
                        // Payment failed
                        setError(response.data.msg || 'Payment verification failed');
                        setPaymentStatus('failed');
                        clearInterval(progressInterval);
                        return;
                    }
                }
                
                // Keep processing if no definitive response
                setPaymentStatus('processing');
                
            } catch (error) {
                console.error('Error checking payment status:', error);
                if (attempts >= maxAttempts || error.response?.status === 400) {
                    const errorMessage = error.response?.data?.msg || 
                                        error.response?.data?.message || 
                                        'Failed to verify payment';
                    setError(errorMessage);
                    setPaymentStatus('failed');
                    clearInterval(progressInterval);
                }
            }
        };

        // Start progress bar
        progressInterval = setInterval(() => {
            setProgress(prev => {
                const newProgress = prev + 5;
                if (newProgress >= 100) {
                    attempts++;
                    return 0; // Reset progress bar
                }
                return newProgress;
            });
            checkPaymentStatus();
        }, 500); // Check every 500ms

        return () => {
            clearInterval(progressInterval);
        };
    }, [UserData.token, navigate]);

    const handleContinue = () => {
        navigate('/general-test'); // or wherever you want to redirect after payment
    };

    if (error) {
        return (
           <div className='min-h-screen flex items-center justify-center'>
             <div className="p-6 h-[20%] w-[20%] mx-auto bg-white rounded-xl shadow-md">
                <div className="text-red-600 text-center mb-4">
                    <h2 className="text-xl font-bold">Error</h2>
                    <p>{error}</p>
                </div>
                <button
                    onClick={() => navigate('/payment/method1')}
                    className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700"
                >
                    Return to Payment
                </button>
            </div>
           </div>
        );
    }

    return (
        <div className="min-h-screen bg-[#c7cee5] flex items-center justify-center bg-gradient-to-r from-blue-50 to-indigo-50">
            <div className="p-8 max-w-md w-full rounded-2xl shadow-xl">
                <div className="text-center">
                    {paymentStatus === 'processing' && (
                        <>
                            <div className="mb-6 relative">
                                {/* Payment Processing Animation */}
                                <div className="w-24 h-24 mx-auto mb-4 relative">
                                    <div className="absolute inset-0 rounded-full border-4 border-blue-200 animate-pulse"></div>
                                    <div className="absolute inset-0 rounded-full border-4 border-blue-500 border-t-transparent animate-spin"></div>
                                    <div className="absolute inset-0 flex items-center justify-center">
                                        <svg className="w-10 h-10 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </div>
                                <h2 className="text-2xl font-bold text-gray-800 mb-2">Processing Payment</h2>
                                <p className="text-gray-600">Please wait while we confirm your payment...</p>
                                {/* Animated Dots */}
                                <div className="flex justify-center gap-1 mt-4">
                                    <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce" style={{animationDelay: '0s'}}></div>
                                    <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce" style={{animationDelay: '0.2s'}}></div>
                                    <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce" style={{animationDelay: '0.4s'}}></div>
                                </div>
                            </div>
                        </>
                    )}
                    {paymentStatus === 'failed' && (
                        <div className="bg-red-50 p-6 rounded-lg">
                            <div className="text-red-600 mb-4">
                                <svg className="w-16 h-16 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <h2 className="text-2xl font-bold text-red-700 mb-2">Payment Failed</h2>
                            <p className="text-red-600">Sorry, your payment could not be processed.</p>
                            <button
                                onClick={() => navigate('/payment/method1')}
                                className="mt-4 px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
                            >
                                Try Again
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
