import * as UIs from "../translate/testSummery";

const DynamicRender = ({ componentName }) => {
  const SelectedComponent = UIs[componentName];
  console.log("5 ", componentName);
  if (!SelectedComponent) {
    console.error(`Component not found for: ${componentName}`);
    return <div></div>;
  }
  return <SelectedComponent />; 
};

export default DynamicRender;
