export const translations = {
  english: {
    
    GoBack: "Go Back",
    noPatient: "No patient with this name",
    emptyNotification: "There is nothing here, should we redirect you to",
    FAQ1Q: "What skills are essential for a technician?",
    FAQ2Q: "What safety measures should I be aware of work?",
    FAQ3Q: "What is the ideal process?",
    FAQ4Q: "What are the best practices?",
    FAQ5Q: "What should I do if I encounter a problem?",
    FAQ1: "Technical skills, attention to detail, and problem-solving abilities are essential for a technician.",
    FAQ2: "Always wear protective gear, follow safety protocols, and stay informed about potential hazards.",
    FAQ3: "The ideal process involves planning, executing, monitoring, and evaluating tasks efficiently and effectively.",
    FAQ4: "Best practices include continuous learning, effective communication, and consistent adherence to standards.",
    FAQ5: "Consult relevant resources, seek guidance from colleagues or supervisors, and be proactive in expanding your knowledge.",
    addPatient: "Add Patient", 
    age: "Age",
    email: "Email",
    uploadPhoto: "Upload Patient Photo",
    athelete: "Athelete",
    average: "Average",
    adhaarNo: "Adhaar No.",
    frequentlyAskedQA: "FREQUENTLY ASKED QUESTIONS 'FAQS'",
    providedAadhaarCard: "Provide Patient Aadhaar Card (IMAGE/PDF)",
    fat: "Fat",
    albumin: "Albumin",
    albuminGlobulinRatio: "Albumin : Globulin Ratio",
    alkalinePhosphataseALP: "Alkaline Phosphatase (ALP)",
    astAltRatio: "AST : ALT Ratio",
    back: "Back",
    betaHCG: "Beta HCG",
    bilirubinDirect: "Direct Bilirubin",
    bilirubinIndirect: "Indirect Bilirubin",
    bilirubinTotal: "Total Bilirubin",
    bloodOxygenSaturation: "Blood Oxygen Saturation",
    bloodPressure: "Blood Pressure",
    bodyAge: "Body Age",
    bodyComposition: "Body Composition",
    bodyFat: "Body Fat",
    bodyType: "Body Type",
    bmi: "BMI",
    bunCreatinineRatio: "BUN : Creatinine Ratio",
    cardiac: "Cardiac",
    cardiacInParentheses: "(Cardiac)",
    chikungunya: "Chikungunya",
    chikungunyaIgMIGG: "Chikungunya IgM/IgG",
    ckmb: "CK-MB",
    completeBloodCount: "Complete Blood Count",
    crp: "CRP",
    dengue: "Dengue",
    dengueIgMIGG: "Dengue IgM/IgG",
    dengueNS1AG: "Dengue NS1 AG",
    diastolic: "Diastolic",
    dDimer: "D-Dimer",
    eAG: "eAG",
    electroCardiogram: "Electrocardiogram",
    elephantiasis: "Elephantiasis",
    enterValidName: "Enter a valid name!",
    error: "Error",
    fastingGlucose: "Fasting Glucose",
    fastingGlucoseNonInvasive: "Fasting Glucose - Non-Invasive",
    fetchLatestResult: "Fetch Latest Results",
    fetchPreviousResult: "Fetch Previous Results",
    finish: "Finish",
    generateReport: "Generate Report",
    general: "General",
    gender: "Gender",
    globulin: "Globulin",
    glucose: "Glucose",
    glucoseNonInvasive: "Glucose - Non-Invasive",
    gfrCategory: "GFR Category",
    gfrEstimated: "Estimated GFR",
    ggtp: "GGTP",
    greeting: "Hello",
    hba1c: "HbA1c",
    hbsAG: "HbsAG",
    hcv: "HCV",
    height: "Height",
    heartRate: "Heart Rate",
    hemoglobin: "Hemoglobin",
    hiv1: "HIV-1",
    hiv1And2: "HIV 1&2",
    hiv2: "HIV-2",
    hdl: "High-Density Lipoprotein (HDL)",
    hsCRP: "Hs-CRP",
    infectionMarker: "Infection Marker",
    impedenceData: " Impedence Data",
    kft: "KFT",
    labNo: "Lab Number",
    latestResult: " Tests Results",
    leptospira: "Leptospira",
    lessThan1Negative: "<1.0 - Negative",
    lessThan100Negative: "<100 - Negative",
    lh: "LH",
    lipidProfile: "Lipid Profile",
    loading: "Loading ...",
    lymphocyteNumber: "Lymphocyte #",
    lymphocytesPercentage: "Lymphocytes %",
    maleria: "Malaria",
    meanCorpuscularHemoglobin: "Mean Corpuscular Hemoglobin",
    meanCorpuscularHemoglobinConcentration:
      "Mean Corpuscular Hemoglobin Concentration",
    meanCorpuscularVolume: "Mean Corpuscular Volume",
    meanPlateletVolume: "Mean Platelet Volume",
    mgDl: "mg/dL",
    midCellNumber: "Mid Cell #",
    midCellPercentage: "Mid Cell %",
    multivital: "Multivital 2.0",
    name: "Name",
    next: "Next",
    nonHdlCholesterol: "Non-HDL Cholesterol",
    nonReactive: "Non-Reactive",
    notValidNumber: "This is not a valid number!",
    ntProBNP: "NT ProBNP",
    others: "Others",
    patientDetails: "Patient Details",
    patientid: "PatientId",
    phone: "Phone",
    place: "Place",
    plateletCount: "Platelet Count",
    plateletDistributionWidthCV: "Platelet Distribution Width - CV",
    plateletDistributionWidthSD: "Platelet Distribution Width - SD",
    plateletLargeCellCount: "Platelet Large Cell Count",
    plateletLargeCellRatio: "Platelet Large Cell Ratio",
    plateletcrit: "Plateletcrit",
    postPrandialGlucose: "Postprandial Glucose",
    postPrandialGlucoseNonInvasive: "Postprandial Glucose - Non-Invasive",
    potassium: "Potassium",
    rapid: "Rapid",
    reactive: "Reactive",
    referrdBY: "REFERRED BY",
    required: "This is required!",
    restingMetabolism: "Resting Metabolism",
    rbcCount: "RBC Count",
    rbcDistributionWidthCV: "RBC Distribution Width - CV",
    rbcDistributionWidthSD: "RBC Distribution Width - SD",
    result: "Result",
    rsvAG: "RSV AG",
    sPneumonia: "S. Pneumonia",
    scAn: "SC-AN",
    select: "Select",
    skeletalMuscle: "Skeletal Muscle",
    scrubTyphus: "Scrub Typhus",
    search: "Search",
    searchPatient: "Searched Patients Will Appear Here.",
    sgptALT: "SGPT (ALT)",
    sgotAST: "SGOT (AST)",
    strepAAG: "Strep A AG",
    sugar: "Sugar",
    systolic: "Systolic",
    temperature: "Temperature",
    test: "Test",
    testAutomatic: "TEST AUTOMATIC",
    testName: "Test Name",
    threeParts: "Three Parts",
    tnl: "TNL",
    totalCholesterol: "Total Cholesterol (TC)",
    totalLeucocyteCount: "Total Leucocyte Count",
    totalProtein: "Total Protein",
    triglycerides: "Triglycerides (TG)",
    tsh: "TSH",
    emptyAdhaar: "Adhaar Number invalid",
    emptyPatientDetails: "All fields are required",
    AdhaarError: "Adhaar number should be of 12 digits",
    tshWB: "TSH WB",
    typhoid: "Typhoid",
    unitAndRange: "Unit and Range",
    uAlbumin: "U-Albumin",
    urea: "Urea",
    ureaNitrogenBlood: "Urea Nitrogen Blood",
    uricAcid: "Uric Acid",
    vectorBorneMarkers: "Vector Borne Markers",
    visceralFat: "Visceral Fat",
    weight: "Weight",
    // enterValidName: "Enter a valid name!",
    // generateReport: "Generate Report"
    SelectSymptoms:"Select Symptoms",
    proceed:"Proceed",
    RecordYourAudio:"Record Your Audio",
    startRecording:"Start Recording",
    stopRecording:"Stop Recording",
    submit:"Submit",
    Reset:"Reset",
    ProceedfortheTest:"Proceed for the Test",
    Variesbytest:"Varies by test",

    // Cough modal
    coughAtNight: "Cough at night",
    sputum: "Sputum",
    wheezing: "Wheezing",
    frequentCough: "Frequent cough",
    painInChest: "Pain in chest",
    shortnessOfBreath: "Shortness of breath",
    fatigue: "Fatigue",
    sweatHeavyAtNight: "Sweat heavy at night",
    lossOfAppetite: "Loss of appetite",
    weightLoss: "Weight loss",
    coughWithSputumAndBlood: "Cough with sputum and blood",
    eveningRiseInTemperature: "Evening rise in temperature",
    knownPersonHavingActiveTb: "Known person having active TB",
    activeTbInThePast: "Actice TB in the past",
    familyHistoryOfAsthma: "Family history of asthma",
    activeAsthmaInThePast: "Active asthma in the past",
    smoking: "Smoking",
    exposureToAnyKindOfSmoke: "Exposure to any kind of smoke",
    temperatureF: "Temperature (°F)",
    pulseRate: "Pulse rate",
    respiratoryRate: "Respiratory rate",
    oxygenSaturation: "Oxygen saturation",
    bloodPressure: "Blood pressure",
    frequentCoughDuration: "Frequent cough duration",
  },
  hindi: {
    GoBack: "वापस जाओ",
    noPatient: "इस नाम का कोई मरीज़ नहीं",
    emptyNotification:
      "यहां कुछ भी नहीं है, क्या हमें आपको पुनर्निर्देशित करना चाहिए",
    FAQ1Q: "एक तकनीशियन के लिए कौन से कौशल आवश्यक हैं?।",
    FAQ2Q: "मुझे काम के दौरान किन सुरक्षा उपायों के बारे में पता होना चाहिए?",
    FAQ3Q: "आदर्श प्रक्रिया क्या है?",
    FAQ4Q: "सर्वोत्तम प्रथाएँ क्या हैं?",
    FAQ5Q: "यदि मुझे कोई समस्या आती है तो मुझे क्या करना चाहिए?",
    FAQ1: "एक तकनीशियन के लिए तकनीकी कौशल, विस्तार पर ध्यान और समस्या-समाधान क्षमताएं आवश्यक हैं।",
    FAQ2: "हमेशा सुरक्षात्मक गियर पहनें, सुरक्षा प्रोटोकॉल का पालन करें और संभावित खतरों के बारे में सूचित रहें।",
    FAQ3: "आदर्श प्रक्रिया में कार्यों की कुशलतापूर्वक और प्रभावी ढंग से योजना बनाना, क्रियान्वयन करना, निगरानी करना और मूल्यांकन करना शामिल है।",
    FAQ4: "सर्वोत्तम प्रथाओं में निरंतर सीखना, प्रभावी संचार और मानकों का लगातार पालन शामिल है।",
    FAQ5: "प्रासंगिक संसाधनों से परामर्श लें, सहकर्मियों या पर्यवेक्षकों से मार्गदर्शन लें और अपने ज्ञान का विस्तार करने में सक्रिय रहें।",
    email: "ईमेल",
    addPatient: "मरीज जोड़ें",
    age: "उम्र",
    adhaarNo: "आधार संख्या",
    athelete: "खिलाड़ी",
    frequentlyAskedQA: "अक्सर पूछे जाने वाले प्रश्न 'एफएक्यू",
    fat: "मोटा",
    average: "औसत",
    uploadPhoto: " रोगी का फोटो अपलोड करें",
    providedAadhaarCard: " रोगी को आधार कार्ड प्रदान करें (छवि/पीडीएफ)",
    albumin: "एल्ब्यूमिन",
    albuminGlobulinRatio: "एल्ब्यूमिन : ग्लोब्यूलिन अनुपात",
    alkalinePhosphataseALP: "अल्कलाइन फास्फेटेज (ALP)",
    astAltRatio: "AST : ALT अनुपात",
    back: "वापस",
    betaHCG: "बीटा एचसीजी",
    bilirubinDirect: "डायरेक्ट बिलिरुबिन",
    bilirubinIndirect: "इंडायरेक्ट बिलिरुबिन",
    bilirubinTotal: "कुल बिलिरुबिन",
    bloodOxygenSaturation: "रक्त ऑक्सीजन संतृप्ति",
    bloodPressure: "रक्तचाप",
    bodyAge: "शरीर की आयु",
    bodyComposition: "शरीर की संरचना",
    bodyFat: "शरीर में वसा",
    bodyType: "शरीर के प्रकार",
    bmi: "बीएमआई",
    bunCreatinineRatio: "BUN : क्रिएटिनिन अनुपात",
    cardiac: "कार्डियक",
    cardiacInParentheses: "(कार्डियक)",
    chikungunya: "चिकुनगुन्या",
    chikungunyaIgMIGG: "चिकनगुनिया आईजीएम/आईजीजी",
    ckmb: "सीके-एमबी",
    completeBloodCount: "पूर्ण रक्त गणना",
    crp: "सीआरपी",
    dengue: "डेंगू",
    dengueIgMIGG: "डेंगू आईजीएम/आईजीजी",
    dengueNS1AG: "डेंगू एनएस1 एजी",
    diastolic: "डायस्टोलिक",
    dDimer: "डी डेमर",
    eAG: "ईएजी",
    emptyPatientDetails: "सभी विवरण आवश्यक हैं",
    AdhaarError: "आधार नंबर 12 अंकों का होना चाहिए",
    electroCardiogram: "इलेक्ट्रो कार्डियोग्राम",
    elephantiasis: "हाथीपांव",
    enterValidName: "एक मान्य नाम दर्ज करें!",
    error: "एरर",
    fastingGlucose: "उपवास ग्लूकोज",
    fastingGlucoseNonInvasive: "उपवास ग्लूकोज - गैर आक्रामक",
    fetchLatestResult: "नवीनतम परिणाम प्राप्त करें",
    fetchPreviousResult: "पिछले परिणाम प्राप्त करें",
    finish: "समाप्त",
    generateReport: "रिपोर्ट उत्पन्न करें",
    general: "सामान्य",
    gender: "लिंग",
    globulin: "ग्लोब्यूलिन",
    glucose: "ग्लूकोज",
    glucoseNonInvasive: "ग्लूकोज - गैर आक्रामक",
    gfrCategory: "GFR श्रेणी",
    gfrEstimated: "GFR अनुमानित",
    ggtp: "GGTP",
    greeting: "नमस्ते",
    hba1c: "एचबीए1सी",
    hbsAG: "एचबीएसएजी",
    heartRate: "हृदय दर",
    hcv: "एचसीवी",
    height: "ऊचाई",
    hemoglobin: "हीमोग्लोबिन",
    hiv1: "एचआईवी-1",
    hiv1And2: "एचआईवी 1&2",
    hiv2: "एचआईवी-2",
    hdl: "उच्च घनत्व लिपोप्रोटीन (एचडीएल)",
    hsCRP: "एचएससीआरपी",
    infectionMarker: "संक्रमण मार्कर",
    impedenceData: "प्रतिबाधा डेटा",
    kft: "KFT",
    labNo: "लैब संख्या",
    latestResult: "परीक्षा परिणाम",
    leptospira: "लेप्टोस्पाइरा",
    lessThan1Negative: "<1.0 - नकारात्मक",
    lessThan100Negative: "<100 - नकारात्मक",
    lh: "एलएच",
    lipidProfile: "लिपिड प्रोफाइल",
    loading: "लोड हो रहा है",
    lymphocyteNumber: "लिम्फोसाइट #",
    lymphocytesPercentage: "लिम्फोसाइट्स %",
    maleria: "मलेरिया",
    meanCorpuscularHemoglobin: "औसत कोशिकीय हीमोग्लोबिन",
    meanCorpuscularHemoglobinConcentration: "औसत कोशिकीय हीमोग्लोबिन सांद्रता",
    meanCorpuscularVolume: "औसत कोशिकीय मात्रा",
    meanPlateletVolume: "औसत प्लेटलेट मात्रा",
    mgDl: "मि.ग्रा/डीएल",
    midCellNumber: "मिडसेल #",
    midCellPercentage: "मिडसेल %",
    multivital: "मल्टीवाइटल 2.0",
    name: "नाम",
    next: "अगला",
    nonHdlCholesterol: "गैर-एचडीएल कोलेस्ट्रॉल",
    nonReactive: "गैर-प्रतिक्रियाशील",
    notValidNumber: "यह एक मान्य संख्या नहीं है!",
    ntProBNP: "एनटी प्रोबीएनपी",
    others: "अन्य",
    patientDetails: "रोगी विवरण",
    patientid: "रोगी आईडी",
    phone: "फ़ोन",
    place: "स्थान",
    plateletCount: "प्लेटलेट गणना",
    plateletDistributionWidthCV: "प्लेटलेट वितरण चौड़ाई - CV",
    plateletDistributionWidthSD: "प्लेटलेट वितरण चौड़ाई - SD",
    plateletLargeCellCount: "प्लेटलेट बड़े कोशिका गणना",
    plateletLargeCellRatio: "प्लेटलेट बड़े कोशिका अनुपात",
    plateletcrit: "प्लेटलेटक्रिट",
    postPrandialGlucose: "पोस्टप्रांडियल ग्लूकोज",
    postPrandialGlucoseNonInvasive: "पोस्टप्रांडियल ग्लूकोज - गैर आक्रामक",
    potassium: "पोटेशियम",
    rapid: "रैपिड",
    reactive: "प्रतिक्रियाशील",
    referrdBY: "द्वारा उल्लिखित",
    required: "यह आवश्यक है!",
    restingMetabolism: "आराम की चयापचय",
    rbcCount: "आरबीसी गणना",
    rbcDistributionWidthCV: "आरबीसी वितरण चौड़ाई - सीवी",
    rbcDistributionWidthSD: "आरबीसी वितरण चौड़ाई - एसडी",
    result: "परिणाम",
    rsvAG: "आरएसवी एजी",
    sPneumonia: "एस.निमोनिया",
    scAn: "एससी - एएन",
    select: "चुनें",
    skeletalMuscle: "कंकाल पेशी",
    scrubTyphus: "स्क्रब सन्निपात",
    search: "खोज",
    searchPatient: "खोजे गए मरीज यहां दिखेंगे।",
    sgptALT: "एसजीपीटी (एएलटी)",
    sgotAST: "एसजीओटी (एएसटी)",
    strepAAG: "स्ट्रेप ए एजी",
    sugar: "चीनी",
    systolic: "सिस्टोलिक",
    temperature: "तापमान",
    test: "जाँच",
    testAutomatic: "स्वचालित परीक्षण करें",
    testName: "परीक्षण का नाम",
    threeParts: "तीन भाग",
    tnl: "टीएनएल",
    totalCholesterol: "कुल कोलेस्ट्रॉल (TC)",
    totalLeucocyteCount: "कुल ल्यूकोसाइट गणना",
    totalProtein: "कुल प्रोटीन",
    triglycerides: "ट्राइग्लिसराइड्स (TG)",
    tsh: "टीएसएच",
    tshWB: "टीएसएच डब्ल्यूबी",
    typhoid: "टाइफाइड",
    unitAndRange: "यूनिट और रेंज",
    uAlbumin: "यू-एल्बुमिन",
    urea: "यूरिया",
    ureaNitrogenBlood: "यूरिया नाइट्रोजन रक्त",
    uricAcid: "यूरिक एसिड",
    vectorBorneMarkers: "वेक्तर जनित मार्कर",
    visceralFat: "आंत वसा",
    weight: "वज़न",

    //coughTestModal
    coughAtNight: "रात में खांसी",
    sputum: "बलगम",
    wheezing: "सांस लेने में सीटी की आवाज",
    frequentCough: "बार-बार खांसी",
    painInChest: "सीने में दर्द",
    shortnessOfBreath: "सांस की तकलीफ",
    fatigue: "थकान",
    sweatHeavyAtNight: "रात में ज्यादा पसीना",
    lossOfAppetite: "भूख न लगना",
    weightLoss: "वजन घटना",
    coughWithSputumAndBlood: "बलगम और खून के साथ खांसी",
    eveningRiseInTemperature: "शाम को बुखार का बढ़ना",
    knownPersonHavingActiveTb: "सक्रिय टीबी वाले व्यक्ति को जानना",
    activeTbInThePast: "पिछले टीबी के मामले",
    familyHistoryOfAsthma: "अस्थमा का पारिवारिक इतिहास",
    activeAsthmaInThePast: "पिछले सक्रिय अस्थमा के मामले",
    smoking: "धूम्रपान",
    exposureToAnyKindOfSmoke: "किसी भी प्रकार के धुएं के संपर्क में आना",
    temperatureF: "तापमान (°F)",
    pulseRate: "नाड़ी दर",
    respiratoryRate: "श्वसन दर",
    oxygenSaturation: "ऑक्सीजन संतृप्ति",
    bloodPressure: "रक्तचाप",
    frequentCoughDuration: "बार-बार खांसी की अवधि",

    submit: "जमा करना",
    Reset: "रीसेट करें",
    stopRecording: "रिकॉर्डिंग बंद करें",
    startRecording: "रिकॉर्डिंग प्रारंभ करें",
    RecordYourAudio: "अपना ऑडियो रिकॉर्ड करें",
    proceed: "आगे बढ़ना",
    SelectSymptoms: "लक्षण चुनें",
    ProceedfortheTest: "परीक्षण के लिए आगे बढ़ें",
    Variesbytest: "परीक्षण के अनुसार भिन्न होता है",
  },
};
