import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SuperceuticalsLogo from "../../../assets/superceuticals-logo.png";

const WebPaymentPage = () => {
  const [amount, setAmount] = useState(0);
  const [PatientID, setPatientID] = useState(null);
  const [isValidToken, setIsValidToken] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const amountFromQuery = query.get('amount');
    const transactionToken = query.get('token');
    const patientID = query.get('PatientID');
    if (amountFromQuery) setAmount(amountFromQuery);
    if (patientID) setPatientID(patientID);

    const validateToken = async () => {
      try {
        const response = await axios.post('https://api.superceuticals.in/payment/validateToken', { token: transactionToken });
        if (response.data.valid) {
          setIsValidToken(true);
        } else {
          setIsValidToken(false);
        }
      } catch (error) {
        console.error('Token validation failed', error);
        setIsValidToken(false);
      }
    };

    if (transactionToken) validateToken();
  }, []);

  const handlePayment = async () => {
    if (!isValidToken) {
      alert('Invalid transaction token. Cannot proceed with payment.');
      return;
    }
    try {
        const response = await axios.post('https://api.superceuticals.in/payment/makeWebPayment', {
          PatientID:PatientID,
          amount: amount*100
        });
        console.log(response);
        window.location.href = response.data.payUrl.url;
      } catch (error) {
        console.error('Payment failed', error);
    }
  };

  const query = new URLSearchParams(window.location.search);
  const status = query.get('status');

  if (status === 'success') {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-2xl font-bold">Payment Done</h1>
        <p>You can close the tab now.</p>
      </div>
    );
  } else if (status === 'failed') {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-2xl font-bold">Payment Failed</h1>
        <p>Please try again.</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <img src={SuperceuticalsLogo} alt="Superceuticals Logo" className="mb-4 w-64" />
      <h1 className="text-xl mb-2">You are paying Superceuticals</h1>
      <p className="text-lg font-semibold mb-6">₹ {amount}</p>
      {isValidToken ? (
        <button onClick={handlePayment} className="px-6 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700">Pay</button>
      ) : (
        <p className="text-red-600 font-bold">Invalid transaction token</p>
      )}
    </div>
  );
};

export default WebPaymentPage;
