const useEmailValidation = () => {
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!email) {
      return "Email cannot be empty.";
    }
    if (email.length > 320) {
      return "Email exceeds maximum length of 320 characters.";
    }
    if (!emailRegex.test(email)) {
      return "Invalid email format.";
    }
    if (email.includes("..")) {
      return "Email cannot contain consecutive dots.";
    }

    const [localPart, domain] = email.split("@");
    if (localPart.length > 64) {
      return "Local part exceeds maximum length of 64 characters.";
    }
    if (domain.length > 255) {
      return "Domain part exceeds maximum length of 255 characters.";
    }
    if (localPart.startsWith(".") || localPart.endsWith(".")) {
      return "Local part cannot start or end with a dot.";
    }

    // Split the domain into parts
    const domainParts = domain.split(".");
    if (domainParts.length > 3) {
      return "Domain cannot have more than two subdomains (e.g., example.com or example.co.in).";
    }

    const tld = domainParts[domainParts.length - 1];
    if (!/^[a-zA-Z]{2,}$/.test(tld)) {
      return "Invalid TLD in domain.";
    }

    // Ensure no segment of the domain is empty
    if (domainParts.some((part) => part.length === 0)) {
      return "Domain cannot contain empty parts.";
    }

    return "DELIVERABLE";
  };

  return { validateEmail };
};

export default useEmailValidation;
