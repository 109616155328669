import React, { useState } from "react";
import API_ENDPOINTS from "../service/api";
import { translations } from "../translation/translate";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import userImg from "../assets/user.png";
import { useNavigate } from "react-router-dom";
import { addPatient } from "../Redux_Services/slices/patientSlice";

const AddPatientConfirmationModal = ({ isOpen, isClose, formData = {} }) => {
  const langData = useSelector((state) => state.langSlice.langData || []);
  const [loading, setLoading] = useState(false);
  const [headUser, setHeadUser] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!isOpen) return null;

  const checkHead = async () =>{
    try {
      setLoading(true);
      const newForm = { ...formData };

      const resp = await axios.get(`${API_ENDPOINTS.checkHead}?contactNumber=${newForm.contactNumber}`, {
        headers: {
          "Content-Type": "application/json",
        }
      });

      console.log("_____________ resp",resp);

      if (!resp?.data.error) {
        const confirmed = window.confirm("A Patient with this Number is Present You will be get added as subUser of it.");
        if (confirmed) {
          setLoading(false);
          handleSubmit();
        } else {
          setLoading(false);
          isClose();
        }
      } else {
        setLoading(false);
        handleSubmit();
      }
    } catch (error) {
      setLoading(false);
      handleSubmit();
    } finally {
      setLoading(false);
      handleSubmit();
    }
  };

  // // Keep the prop `formData` as is
  const handleSubmit = async () => {
    setLoading(true);
    const newForm = { ...formData }; // Avoid shadowing `form`
    const calculateAge = (dob) => {
      const dobDate = new Date(dob);
      const today = new Date();
      let age = today.getFullYear() - dobDate.getFullYear();
      const monthDiff = today.getMonth() - dobDate.getMonth();

      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < dobDate.getDate())
      ) {
        age--;
      }
      return age;
    };
    console.log("42", newForm);
    const age = calculateAge(newForm.dob);

    // Prepare the final form object
    const finalForm = {
      Adhaar: newForm.Adhaar,
      imageURL: newForm.imageURL || userImg,

      Age: age,
      EmailId: newForm.EmailId,
      Gender: newForm.Gender,
      Name: newForm.Name,
      Place: newForm.Place,
      contactNumber: newForm.contactNumber,
    };

    try {
      const resp = await axios.post(API_ENDPOINTS.addPatient, finalForm, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!resp?.data.error) {
        toast.success(translations[langData].toastPatientAdded, {
          position: toast.POSITION.TOP_CENTER,
        });

        const url = `https://api.superceuticals.in/patient/search-patient/${newForm.contactNumber}`;
        const response2 = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MjcxMDQzMTd9.rW70KCsDPR9DU8SDRPDxtTFcX2mT2nb_1lZoYgoVzEk", // Replace with your correct Bearer token
          },
        });

        if (!response2.ok) {
          throw new Error(`HTTP error! status: ${response2.status}`);
        }

        const result = await response2.json();
        finalForm.PatientID = result.patients[0].PatientID;
        console.log("148", finalForm);
        dispatch(addPatient(finalForm));
        navigate("/tests");
      } else {
        throw new Error(
          translations[langData].toastFailedToAddPatient ||
            "Failed to add patient"
        );
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center  bg-gray-800 bg-opacity-50 z-50">
      <div className="w-full max-w-xl rounded-lg p-4 bg-white/80 backdrop-blur-sm">
        <h2 className="text-3xl font-bold  text-[#9669E0] text-center">
          Confirm Your Details
        </h2>

        <div className="space-y-4">
          <table className="w-full">
            <tbody>
              {[
                { label: "Name", value: formData.Name },
                { label: "Adhaar No.", value: formData.Adhaar },
                { label: "Contact Number", value: formData.contactNumber },
                { label: "Gender", value: formData.Gender },
                { label: "Place", value: formData.Place },
                { label: "Date of Birth", value: formData.dob },
                { label: "Email", value: formData.EmailId },
              ].map((item, index) => (
                <tr
                  key={item.label}
                  className={index % 2 === 0 ? "bg-purple-50/50" : ""}
                >
                  <td className="py-4 px-6 font-medium text-gray-700 w-1/3">
                    {item.label}
                  </td>
                  <td className="py-4 px-6 text-gray-600">
                    {item.value || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <hr className="my-6" />

        <div className="flex justify-between gap-4">
          <button
            onClick={checkHead}
            className="bg-[#9669E0] hover:bg-[#8456D0] rounded-lg text-white px-6 py-2 flex items-center gap-2"
          >
            Confirm & Submit
          </button>
          <button
            variant="outline"
            onClick={isClose}
            className="border-gray-300 text-gray-700 px-6 py-2 rounded-lg flex items-center gap-2 bg-gray-300 hover:bg-gray-100"
          >
            Edit Details
          </button>
        </div>
      </div>

      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="w-10 h-10 border-4 border-t-red-500 border-gray-300 rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
};

export default AddPatientConfirmationModal;
