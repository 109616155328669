import React, { useEffect, useState } from 'react';
import axios from 'axios';
import formatDate from '../Utils/formatDate';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import reportRecord from "../../src/translate/reportRecord";
import translations from "../../src/translate/translate";
import { addTests, addReferedBy, addDateTime } from "../Redux_Services/slices/testSlice";
import API_ENDPOINTS from '../service/api';

export default function TestRecordModal({ isOpen, onClose }) {
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const patientDetails = useSelector((state) => state.patientSlice.patientData);

    // const getUnitAndRange = (testName, fieldName) => {
    //     for (const test of translations) {
    //         if (test.testFieldName.toLowerCase() === testName.toLowerCase()) {
    //             for (const field of test.fields) {
    //                 if (field.fieldName.toLowerCase() === fieldName.toLowerCase()) {
    //                     return {
    //                         unitAndRange: field.unitAndRange || "N/A",
    //                         bioReferenceInterval: field.bioReferenceInterval || "-",
    //                     };
    //                 }
    //                 if (field.subFields) {
    //                     for (const subField of field.subFields) {
    //                         if (subField.fieldName.toLowerCase() === fieldName.toLowerCase()) {
    //                             return {
    //                                 unitAndRange: subField.unitAndRange || "N/A",
    //                                 bioReferenceInterval: subField.bioReferenceInterval || "-",
    //                             };
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }

    //     // Default values if no match found
    //     return { unitAndRange: "N/A", bioReferenceInterval: "-" };
    // };

    // const transformTestData = (testData) => {
    //     const transformedData = [];

    //     try {
    //         Object.entries(testData).forEach(([testName, fields]) => {
    //             const processedFields = Object.entries(fields).map(([key, value]) => {
    //                 const { unitAndRange, bioReferenceInterval } = getUnitAndRange(testName, key);
                   
    //                 return {
    //                     fieldId: key.toLowerCase().replace(/[^a-z0-9]/g, ""), // Clean field ID
    //                     fieldName: key,
    //                     value: value != null ? value.toString() : "-",
    //                     unitAndRange,
    //                     bioReferenceInterval,
    //                     subFields: [],
    //                 };
    //             });

    //             transformedData.push({
    //                 testFieldName: testName,
    //                 fields: processedFields,
    //             });
    //         });
    //     } catch (error) {
    //         console.error("Error transforming test data:", error);
    //     }

    //     return transformedData;
    // };

    useEffect(() => {
        if (isOpen) {
            fetchTestResults();
        }
    }, [isOpen]);
    
    const fetchTestResults = async () => {
        try {
            setLoading(true);
            setError(null);
            console.log("Fetching test results for PatientID:", patientDetails.PatientID);
            const url = `${API_ENDPOINTS.getTestResultsLocal}${patientDetails.PatientID}`;
            console.log("API URL:", url);
            const response = await axios.get(url, {
                headers: {
                    Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiR3V3YWh0aSBUZXN0ZXIiLCJkZXNpZ25hdGlvbiI6IlRlY2huaWNpYW4iLCJMYWJUZWNobmljaWFuSUQiOiJURUNINDMzODUzIiwicm9sZSI6InRlY2giLCJpYXQiOjE3MjgzNjEwMzV9.3isyIl_D11QawjIi-h-9LInwhRiRD533W7tZz4Uxtdg",
                },
            });
    
            console.log("API Response:", response.data);
    
            if (response.data && response.data.testResults) {
                console.log("_______________ dsjhdsbf", response.data.testResults);
                const extractedData = response.data.testResults.map((item) => ({
                    Created: item.createdAt ? new Date(item.createdAt).toLocaleString() : 'N/A',
                    ReferredBy: item.ReferredBy || '-',
                    ReportStatus: item.ReportStatus || 'Pending',
                    TestData: item.TestData ? item.TestData.map(test => ({
                        testFieldName: test.testFieldName || 'Unknown',
                        tests: test.tests ? test.tests.map(innerTest => ({
                            testCode: innerTest.testCode,
                            name: innerTest.name,
                            value: innerTest.value,
                            units: innerTest.units,
                            unitAndRange: innerTest.unitAndRange,
                            bioReferenceInterval: innerTest.bioReferenceInterval
                        })) : [],
                        fields: test.fields || ["kuch nhi h"]
                    })) : [],
                }));
    
                console.log("Extracted Data:", extractedData);
                setRecords(extractedData);
            } else {
                setRecords([]);
            }
        } catch (error) {
            console.error("Error fetching test results:", error.response ? error.response.data : error.message);
            setError("Failed to fetch test results. Please try again later.");
        } finally {
            setLoading(false);
        }
    };
    // const handleContinue = (record) => {
    //     try {
    //         if (!record) {
    //             console.error("No record selected.");
    //             return;
    //         }
    //         console.log("Selected Record:", record);
    //         console.log("Original TestData: ", record.TestData);
    
    //         // const formattedTestData = record.TestData.map((item) => {
    //         //     const formattedFields = (item.tests || []).map((test) => ({
    //         //         fieldName: test.name,
    //         //         bioReferenceInterval: test.bioReferenceInterval,
    //         //         value: test.value,
    //         //         unitAndRange: test.unitAndRange,
    //         //         fieldId: test.testCode || "",
    //         //         subFields: []
    //         //     }));
    
    //         //     return {
    //         //         testFieldName: item.testFieldName,
    //         //         fields: formattedFields
    //         //     };
    //         // });
    
    //         // console.log("Formatted TestData: ", formattedTestData);
            
    //         //Here we have to filter the data and we have to remove the object whose fieldName start with the sample , eg "sampletyphoidigmigg"  , so can you do that 
            
    //         dispatch(addTests(record.TestData));
    //         dispatch(addReferedBy(record.ReferredBy || ""));
    
    //         navigate("/tests/testId/report");
    //     } catch (error) {
    //         console.error("Error handling continue action:", error);
    //     }
    // };


    const handleContinue = (record) => {
        try {
            if (!record) {
                console.error("No record selected.");
                return;
            }
            console.log("Selected Record:", record);
            console.log("Original TestData: ", record.TestData);
    
            // Filter out objects with fields containing `fieldName` that starts with "sample"
            const filteredTestData = record.TestData.map((item) => {
                const filteredFields = item.fields.filter(
                    (field) => !field.fieldName.startsWith("sample")
                );
    
                return {
                    ...item,
                    fields: filteredFields
                };
            });
    
            console.log("Filtered TestData: ", filteredTestData);
    
            // Dispatching the filtered data
            dispatch(addTests(filteredTestData));
            dispatch(addDateTime(record.Created));
            dispatch(addReferedBy(record.ReferredBy || ""));
    
            navigate("/tests/testId/report", { state: { fromGeneralPage: false } });
        } catch (error) {
            console.error("Error handling continue action:", error);
        }
    };
    
    
    
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
            <div className="bg-white h-2/3 max-h-70 overflow-y-scroll rounded-lg shadow-lg w-full max-w-3xl">
                <div className="flex justify-between items-center border-b border-gray-200 px-6 py-4">
                    <h2 className="text-xl font-semibold text-purple-500">Patient Test Records</h2>
                    <button
                        className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        onClick={onClose}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <div className="p-6">
                    {loading ? (
                        <div className="text-center">Loading...</div>
                    ) : error ? (
                        <div className="text-red-500 text-center">No report found for this patient!</div>
                    ) : records.length > 0 ? (
                        <table className="min-w-full table-auto">
                            <thead>
                                <tr className="bg-purple-100 text-purple-600 uppercase text-sm leading-normal">
                                    <th className="py-3 px-6 text-left">Sr.No</th>
                                    <th className="py-3 px-6 text-left">Date</th>
                                    <th className="py-3 px-6 text-left">Test Status</th>
                                    <th className="py-3 px-6 text-left">Referred By</th>
                                    <th className="py-3 px-6 text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-600 text-sm font-light">
                                {records.map((record, index) => (
                                    <tr key={index} className="border-b border-gray-200 hover:bg-purple-50">
                                        <td className="py-3 px-6 text-left">{index + 1}</td>
                                        <td className="py-3 px-6 text-left">{formatDate(record.Created)}</td>
                                        <td className="py-3 px-6 text-left">{record.ReportStatus}</td>
                                        <td className="py-3 px-6 text-left">{record.ReferredBy}</td>
                                        <td className="py-3 px-6 text-center">
                                            <button
                                                className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 focus:outline-none"
                                                onClick={() => handleContinue(record)}
                                            >
                                                Continue
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="text-center">No records available.</div>
                    )}
                </div>
            </div>
        </div>
    );
}
