import { createSlice } from "@reduxjs/toolkit";

// Retrieve stored patient data from localStorage
const storedPatient = JSON.parse(localStorage.getItem("patientData")) || null;

const initialState = {
  patientData: storedPatient || null, // Initialize with stored data
};

const patientSlice = createSlice({
  name: "patientSlice",
  initialState,
  reducers: {
    addPatient: (state, action) => {
      state.patientData = action.payload;
      localStorage.setItem("patientData", JSON.stringify(action.payload)); // Store in localStorage
    },
  },
});

export const { addPatient } = patientSlice.actions;
export default patientSlice.reducer;
