import React, { useState } from "react";
import CrossIcon from "../../assets/icons/cross.svg";
import Stethoscope from "../../assets/stethoscope.jpeg";
import { useSelector } from "react-redux";

const StethoscopeModal = ({ isOpen, onClose }) => {
  const [duration, setDuration] = useState(10); // Default duration is 10 seconds
  const [amplification, setAmplification] = useState(8); // Default amplification is 8
  const [filter, setFilter] = useState("nofilter"); // Default filter is No-filter
  const [loading, setLoading] = useState(false);
  const [showNextModal, setShowNextModal] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [verificationModal, setVerificationModal] = useState(false);
  const [genrateReport, setgenrateReport] = useState(false);
  const [reportUrl, setReportUrl] = useState(null);
  const [genBtn, setGenbtn] = useState("Generate");
  const [formData, setFormData] = useState({
    web_report: null,
    signal_type: null,
  });

  const patientId = useSelector(
    (state) => state.patientSlice.patientData.PatientID
  );

  const handleStartAction = async () => {
    setLoading(true);

    const filename = `${Date.now()}-${patientId}.wav`;
    const data = {
      name: filename,
      duration: duration.toString(),
      record: "true",
      amplification: amplification.toString(),
      filter: filter.toLowerCase(),
    };

    try {
      const response = await fetch("http://localhost:8000/record_playback/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (result.status === "successful") {
        setShowNextModal(true);
        setLoaderVisible(true);

        // Start the loader for the specified duration
        setTimeout(async () => {
          setLoaderVisible(false); // Hide loader
          // setShowNextModal(false); // Close next modal

          // Update formData with filename and filter
          const updatedFormData = {
            ...formData,
            web_report: filename,
            signal_type: filter,
          };
          setFormData(updatedFormData);

          // Call the submission API
          // await handleAudioSubmission(updatedFormData);
          await handleSampleVerification();

          // Close the modal
          // onClose();
        }, duration * 1000);
      }
      if (result.status === "failed") {
        alert("Failed to initiate record and playback.");
        onClose();
      }

      // Show the loader and transition to the next modal
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to initiate record and playback.");
      onClose();

      // Start the loader for the specified duration
      setTimeout(async () => {
        setLoaderVisible(false); // Hide loader
        // setShowNextModal(false); // Close next modal

        // Update formData with filename and filter
        const updatedFormData = {
          ...formData,
          web_report: filename,
          signal_type: filter,
        };
        setFormData(updatedFormData);

        // Call the submission API
        // await handleAudioSubmission(updatedFormData);
        await handleSampleVerification();

        // Close the modal
        // onClose();
      }, duration * 1000);
    } finally {
      setLoading(false);
    }
  };

  const fetchUrl = async (url, intervalId) => {
    try {
      console.log("105", url);
      // `${url}`
      const response = await fetch(`${url}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      console.log("119", result.res.status);

      if (result.res.status === "success") {
        clearInterval(intervalId);
        const reportUrl = result.res.data.location.destination[0];
        console.log(reportUrl); // Stop further API calls
        setReportUrl(reportUrl);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleGenerate = async () => {
    setLoading(true);
    setReportUrl("Your report is being generated...");
    setGenbtn("Generating...");

    try {
      const response = await fetch("http://localhost:8000/report/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      let attempts = 0;
      const maxAttempts = 6; // 20 seconds / 5 seconds = 4 attempts

      if (result.status === "failed") {
        alert("Failed to submit audio data.");
        onClose();
        return;
      }
      const callapi = setInterval(async () => {
        attempts++;

        await fetchUrl(result.url, callapi);

        if (attempts >= maxAttempts) {
          clearInterval(callapi);
          setLoading(false);
          setReportUrl("Report generation failed! Try again!");

          setTimeout(() => {
            onClose();
          }, 2000);
        }
      }, 5000);
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to submit audio data.");
      onClose();
    }
  };

  const handleSampleVerification = async () => {
    try {
      setVerificationModal(true);
      const response = await fetch("http://localhost:8000/fnames", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();

      if (result.status === "success") {
        setShowNextModal(false);
        setVerificationModal(false);
        setgenrateReport(true);
      }
      if (result.status === "failed") {
        alert("sample verification failed");
        onClose();
      }
    } catch (error) {
      console.error("Error:", error);

      alert("verification failed");
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Main Modal */}
      {isOpen && !showNextModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          {loading && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-[100]">
              <div className="w-10 h-10 border-4 border-t-[#845BB3] border-gray-300 rounded-full animate-spin"></div>
            </div>
          )}
          <div className="bg-[#845BB3] overflow-y-scroll rounded-lg shadow-lg w-[40%] h-[60%] p-6 relative">
            {/* Close Button */}
            <button
              onClick={onClose}
              className="absolute top-3 right-3 text-white hover:text-gray-200 text-xl font-bold"
            >
              <img src={CrossIcon} alt="Close" />
            </button>
            <div>
              <h2 className="text-2xl font-semibold text-center text-white mb-6">
                Stethoscope Modal
              </h2>
              <div className="space-y-6 top-5">
                {/* Duration */}
                <div>
                  <label className="block font-medium text-white mb-2">
                    Duration (seconds)
                  </label>
                  <input
                    type="number"
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    className="w-full border border-gray-300 rounded-md p-2"
                    min="1"
                    max="30"
                  />
                </div>

                {/* Amplification */}
                <div>
                  <label className="block font-medium text-white mb-2">
                    Amplification
                  </label>
                  <div className="flex items-center space-x-4">
                    <input
                      type="range"
                      value={amplification}
                      onChange={(e) => setAmplification(e.target.value)}
                      min="2"
                      max="25"
                      className="flex-grow appearance-none h-2 bg-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-white transition"
                    />
                    <span className="text-center text-white font-semibold">
                      {amplification}
                    </span>
                  </div>
                </div>

                {/* Filter Type */}
                <div>
                  <label className="block font-medium text-white mb-2">
                    Filter Type
                  </label>
                  <div className="flex space-x-4">
                    {"nofilter heart lungs".split(" ").map((type) => (
                      <label
                        key={type}
                        className={`flex items-center justify-center p-2 rounded-lg border transition cursor-pointer ${
                          filter === type
                            ? "bg-[#6A48A0] text-white"
                            : "bg-gray-100 text-gray-700 border-gray-300"
                        } hover:border-gray-400`}
                      >
                        <input
                          type="radio"
                          name="filter"
                          value={type}
                          checked={filter === type}
                          onChange={(e) => setFilter(e.target.value)}
                          className="hidden"
                        />
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Start Action Button */}
            <div className="mt-8 flex justify-center">
              <button
                onClick={handleStartAction}
                className="px-8 py-2 bg-[#6A48A0] text-white font-semibold rounded-md hover:bg-[#56408B] transition"
              >
                Start Action
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Next Modal */}
      {/* Next Modal */}
      {showNextModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          {/* Loader overlay */}
          {loaderVisible && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-60">
              <div className="text-white text-lg">Recording...</div>
            </div>
          )}

          <div
            className={`bg-[#845BB3] rounded-lg shadow-lg w-[30%] h-[60%] p-6 relative ${
              loaderVisible ? "pointer-events-none" : ""
            }`}
          >
            {/* Close Button */}
            <button
              onClick={onClose}
              className="absolute top-3 right-3 text-white hover:text-gray-200 text-xl font-bold"
            >
              <img src={CrossIcon} alt="Close" />
            </button>
            <div className="flex flex-col items-center justify-center h-full">
              {/* Stethoscope Image */}
              <div className="flex mb-3 w-[70%] h-[70%]">
                <img src={Stethoscope} alt="" />
              </div>
              {/* Text */}
              <div className="flex items-center justify-center mb-3">
                <h2 className="text-white text-2xl text-center">
                  Please Place the Stethoscope on the Patient
                </h2>
              </div>
              {/* Circular Loader */}
              {loaderVisible && (
                <div className="flex items-center justify-center mt-4">
                  <div className="w-10 h-10 border-4 border-t-[#6A48A0] border-gray-300 rounded-full animate-spin"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {verificationModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-[#845BB3] rounded-lg shadow-lg w-[30%] h-[40%] p-8 flex flex-col items-center justify-center relative">
            <button
              onClick={onClose}
              className="absolute top-3 right-3 text-white hover:text-gray-200 text-xl font-bold"
            >
              <img src={CrossIcon} alt="Close" />
            </button>
            {/* Loader */}
            <div className="w-12 h-12 border-4 border-t-[#6A48A0] border-gray-300 rounded-full animate-spin"></div>

            {/* Text */}
            <h2 className="text-white text-lg text-center mt-6">
              Verifying Your Sample...
            </h2>
          </div>
        </div>
      )}

      {genrateReport && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-[#845BB3] rounded-lg shadow-lg w-[30%] h-[40%] p-8 flex flex-col items-center justify-center relative">
            <button
              onClick={onClose}
              className="absolute top-3 right-3 text-white hover:text-gray-200 text-xl font-bold"
            >
              <img src={CrossIcon} alt="Close" />
            </button>
            {/* Loader */}
            {loading ? (
              <div className="w-12 h-12 border-4 border-t-[#6A48A0] border-gray-300 rounded-full animate-spin"></div>
            ) : (
              <></>
            )}
            {/* Generate Button */}
            {reportUrl === null ? (
              <button
                className="mt-6 bg-white text-[#845BB3] px-6 py-2 rounded-lg font-semibold hover:bg-gray-200 transition"
                onClick={handleGenerate}
              >
                {genBtn}
              </button>
            ) : (
              <a
                href={reportUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-6 text-white-600 underline text-center"
              >
                {reportUrl}
              </a>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default StethoscopeModal;
