import React from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SelectTests({
  tests,
  selectedGroups,
  setSelectedGroups,
}) {
  // Accessing user details and version
  const login = useSelector((state) => state.authSlice.user);
  const version = login.version;

  const handleOptionChange = (e, testFieldName, fieldName) => {
    const isChecked = e.target.checked;
    console.log("17", testFieldName, "17 = ", fieldName);
    setSelectedGroups((prevSelected) => {
      if (
        isChecked &&
        fieldName === "Cancer Screening" &&
        prevSelected.length > 0
      ) {
        toast.error(
          "You cannot select 'Cancer Screening' when other tests are selected."
        );
        return prevSelected;
      }
      if (
        isChecked &&
        fieldName === "Cough Analysis" &&
        prevSelected.length > 0
      ) {
        toast.error(
          "You cannot select 'Cough Analysis' when other tests are selected."
        );
        return prevSelected;
      }
      if (isChecked && fieldName === "ENT" && prevSelected.length > 0) {
        toast.error("You cannot select 'ENT' when other tests are selected.");
        return prevSelected;
      }
      if (
        isChecked &&
        fieldName === "Stethoscope amplifier" &&
        prevSelected.length > 0
      ) {
        toast.error(
          "You cannot select 'Stethoscope amplifier' when other tests are selected."
        );
        return prevSelected;
      }
      if (isChecked && fieldName === "General" && prevSelected.length > 0) {
        // If "General" is selected while other tests are already selected
        toast.error(
          "You cannot select 'General' when other tests are selected."
        );
        return prevSelected; // Prevent selection
      }

      // If another test is selected while "General" is already selected
      if (
        isChecked &&
        prevSelected.some((group) => group.name === "Body Analysis") &&
        fieldName !== "General"
      ) {
        console.log("33", prevSelected);
        toast.error(
          "You cannot select another test when 'General' is selected."
        );
        return prevSelected; // Prevent selection
      }
      if (
        isChecked &&
        prevSelected.some((group) => group.name === "AI Analysis") &&
        fieldName !== "Cough Analysis"
      ) {
        console.log("33", prevSelected);
        toast.error(
          `You cannot select another test when ${
            prevSelected.find((group) => group.name === "AI Analysis")
              .options[0]
          } is selected.`
        );
        return prevSelected; // Prevent selection
        // Prevent selection
      }

      if (
        isChecked &&
        fieldName === "Complete Renal Profile" &&
        prevSelected.length === 0
      ) {
        return [
          ...prevSelected,
          {
            name: testFieldName,
            options: [
              "Complete Renal Profile",
              "Creatinine",
              "Urea",
              "Uric Acid",
              "Sodium",
              "Potassium",
              "Total Protein",
              "Albumin",
            ],
          },
        ];
      }
      if (
        !isChecked &&
        fieldName === "Complete Renal Profile" &&
        prevSelected.length > 0
      ) {
        console.log("Deselecting");
        return (prevSelected.options = []);
      }

      if (
        isChecked &&
        fieldName === "Complete Liver Function Tests" &&
        prevSelected.length === 0
      ) {
        return [
          ...prevSelected,
          {
            name: testFieldName,
            options: [
              "Complete Liver Function Tests",
              "SGOT/ AST",
              "SGPT/ ALT",
              "GGTP",
              "ALP",
              "Bilirubin Total",
              "Bilirubin Direct",
              "Total Protein",
              "Albumin",
            ],
          },
        ];
      }
      if (
        !isChecked &&
        fieldName === "Complete Liver Function Tests" &&
        prevSelected.length > 0
      ) {
        console.log("Deselecting");
        return (prevSelected.options = []);
      }

      // Logic for selecting/deselecting options
      const groupIndex = prevSelected.findIndex(
        (group) => group.name === testFieldName
      );
      const updatedTests = isChecked
        ? [...(prevSelected[groupIndex]?.options || []), fieldName]
        : (prevSelected[groupIndex]?.options || []).filter(
            (test) => test !== fieldName
          );

      if (groupIndex > -1) {
        if (updatedTests.length > 0) {
          return [
            ...prevSelected.slice(0, groupIndex),
            { name: testFieldName, options: updatedTests },
            ...prevSelected.slice(groupIndex + 1),
          ];
        } else {
          return [
            ...prevSelected.slice(0, groupIndex),
            ...prevSelected.slice(groupIndex + 1),
          ];
        }
      } else {
        return [...prevSelected, { name: testFieldName, options: [fieldName] }];
      }
    });
  };

  const handleSelectAll = (testFieldName) => {
    setSelectedGroups((prevSelected) => {
      const groupIndex = prevSelected.findIndex(
        (group) => group.name === testFieldName
      );
      const allOptions = tests
        .find((test) => test.testFieldName === testFieldName)
        .fields.map((field) => field.fieldName);

      if (groupIndex > -1) {
        const group = prevSelected[groupIndex];

        if (group.options.length === allOptions.length) {
          return prevSelected.filter((group) => group.name !== testFieldName);
        } else {
          return [
            ...prevSelected.slice(0, groupIndex),
            { name: testFieldName, options: allOptions },
            ...prevSelected.slice(groupIndex + 1),
          ];
        }
      } else {
        return [...prevSelected, { name: testFieldName, options: allOptions }];
      }
    });
  };

  const isSelected = (testFieldName, fieldName) => {
    const group = selectedGroups.find((group) => group.name === testFieldName);
    return group ? group.options.includes(fieldName) : false;
  };

  const isGroupSelected = (testFieldName) => {
    const group = selectedGroups.find((group) => group.name === testFieldName);
    const allOptions = tests
      .find((test) => test.testFieldName === testFieldName)
      .fields.map((field) => field.fieldName);
    return group ? group.options.length === allOptions.length : false;
  };

  return (
    <div>
      <ToastContainer />
      <div className="flex select-none flex-wrap gap-16 items-start justify-center">
        {tests
          .filter((test) => {
            // Conditions based on version
            if (version === "2.1") {
              const allowedOthersFields = [
                // "iFOB",
                // "Electro Cardiogram",
                // "Colon Cancer - iFOB",
                "Typhoid",
                "Cholera",
                "Chikungunya",
                "Leptospira",
                "HIV",
                "H. Pylori",
                "Hepatitis B Ag",
                "Scrub Typhus",
                "Hepatitis C",
                "Elephantiasis",
                "Malaria pf PAN",
                "Dengue",
              ];

              if (test.testFieldName === "Others") {
                // Filter the fields array to include only allowed field names
                const filteredFields = test.fields.filter((field) =>
                  allowedOthersFields.includes(field.fieldName)
                );
                return filteredFields.length > 0; // Return true if there are any matching fields
              }

              // For other testFieldName categories

              return [
                "General",
                "Lipid Profile",
                "Hemoglobin",
                "AI Analysis",
                "Glucose",
                "Others",
                "Body Analysis",
              ].includes(test.testFieldName);
            }
            if (version === "2.2") {
              return ![
                "Liver Markers",
                "Kidney Markers",
                "Glucose",
                "Lipid Profile",
                "Urinalysis",
                "Complete Blood Count",
                "Blood Markers",
              ].includes(test.testFieldName);
            }
            if (version === "2.3") {
              return ![
                "Glucose",
                "Lipid Profile",
                "Urinalysis",
                "Hemoglobin",
              ].includes(test.testFieldName);
            }
            return true; // Show all tests for other versions
          })
          .map((test, index) => (
            <div
              key={index}
              className="relative bg-white rounded-3xl shadow-md w-full min-w-[20rem] max-w-fit h-full min-h-[25rem]"
            >
              <div>
                <div className="bg-[#845BB3] rounded-t-3xl py-5 text-center text-white">
                  <span className="font-semibold">{test.testFieldName}</span>
                </div>
                <div
                  className={`mt-4 pt-2 pb-2 px-8 grid gap-x-14 ${
                    test.fields.length > 6 ? "grid-cols-2" : "grid-cols-1"
                  }`}
                >
                  {test.fields.map((field, idx) => {
                    if (!field.fieldName) return null;

                    return (
                      <label key={idx} className="mt-3 gap-2 flex items-center">
                        <input
                          type="checkbox"
                          id={field.fieldName}
                          name={field.fieldName}
                          checked={isSelected(
                            test.testFieldName,
                            field.fieldName
                          )}
                          onChange={(e) =>
                            handleOptionChange(
                              e,
                              test.testFieldName,
                              field.fieldName
                            )
                          }
                          className="h-4 w-4 px-5 z-10"
                        />
                        <p className="text-[#845BB3] font-medium">
                          {field.fieldName}
                        </p>
                      </label>
                    );
                  })}
                </div>
              </div>
              {!["Body Analysis", "AI Analysis"].includes(
                test.testFieldName
              ) && (
                <button
                  onClick={() => handleSelectAll(test.testFieldName)}
                  className="bg-[#845BB3] text-white rounded-md m-6 px-6 py-2 text-sm font-bold absolute bottom-0 right-0"
                >
                  {isGroupSelected(test.testFieldName)
                    ? "DESELECT ALL"
                    : "SELECT ALL"}
                </button>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}
