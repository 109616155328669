import React from "react";
// import DownloadApp from '../../components/AppDownload/DownloadApp';

import AccordionSummary from "@mui/material/AccordionSummary";

import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Nav from "../Components/Navbar";
import Footer from "../Components/Footer";

function Refund() {
  return (
    <div>
      <Nav />
      <div className="bg-[#C7CEE5] min-h-[80vh]   px-4 sm:px-8 md:px-10 lg:px-30 bg-wave-background py-[4rem]">
        <div>
          <h3 className="font-extrabold px-[1rem] py-[2rem] text-[40px] text-purple-500">
            Cancellation & Refund Policy
          </h3>
        </div>
        <div className="bg-white shadow-mg p-4">
          <Typography>
            <h1 class="font-bold text-xl py-2">
              Last updated on Jun 19th 2023
            </h1>
            <p class="text-left py-2">
              Superceuticals Private Limited believes in helping its customers
              as far as possible and has, therefore, a liberal cancellation
              policy/refound policy. Under this policy:
            </p>
            <p class="text-left py-2">
              Order Cancellation Policy/ Refund Policy Cancellation Charges Any
              time after the transaction and before the phlebo's visit to the
              patient's home INR 0 Phlebo visits the patient premises but the
              booking is cancelled. Sample is not collected. INR 200 After
              Sample Pick up 100% (No Refund) 0 Cancellation Charges Rs. 150
              100% (No Refund) • In case a request for refund reaches the
              company, amount paid will be credited into patient account/card
              for within a maximum of 10 (Business days) days from date of
              cancellation. • No refund will be given after 45 days from the
              date of preferred slot have been booked by patient. •
              Cancellations received after the stated deadline will not be
              eligible for a refund. • Refund requests must include the name of
              the attendee and/or transaction number.
            </p>
          </Typography>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Refund;
