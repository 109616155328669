import React, { useState, useEffect } from "react";
import SelectTests from "../../Components/SelectTests";
import tests from "../../translate/translate"; // Assuming this is where you have your test data
import PatientProfile from "../../assets/patient-profile.png";
import EditIcon from "../../assets/icons/edit.svg";
import ManualTestingIcon from "../../assets/icons/manual-testing.svg";
import AutomaticTestingIcon from "../../assets/icons/automatic-testing.svg";
import UserIcon from "../../assets/icons/user2.svg";
import CrossIcon from "../../assets/icons/cross.svg";
import AgeIcon from "../../assets/icons/age.svg";
import FlaskIcon from "../../assets/icons/flask.svg";
import GenderIcon from "../../assets/icons/gender.svg";
import MapPinIcon from "../../assets/icons/mao-pin.svg";
import UserIdIcon from "../../assets/icons/user-id.svg";
import { useDispatch, useSelector } from "react-redux";
import { addTests } from "../../Redux_Services/slices/testSlice";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { translations } from "../../translation/translate";
import { addLang } from "../../Redux_Services/slices/langSlice";
import { addPatient } from "../../Redux_Services/slices/patientSlice";

export default function Tests() {
  const [selectedGroups, setSelectedGroups] = useState([]);
  const patientDetails = useSelector((state) => state.patientSlice.patientData);
  console.log("patientDetails = ", patientDetails);

  const login = useSelector((state) => state.authSlice.user);
  const langData = useSelector((state) => state.langSlice.langData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   console.log("30 = ", technicianDetailes);
  console.log("selected Group = ", selectedGroups);
  const labNo = login.CentreID;

  // Use useEffect to dispatch patient details only when they change
  useEffect(() => {
    if (patientDetails) {
      dispatch(addPatient(patientDetails));
      console.log("Patient details dispatched successfully!!");
    }
  }, [patientDetails, dispatch]);

  const formatSelectedGroups = (selectedGroups, formattedTranslations) => {
    return selectedGroups.map((group) => {
      const testGroup = formattedTranslations.find(
        (test) => test.testFieldName === group.name
      );

      return {
        testFieldName: group.name,
        fields: group.options.map((option) => {
          const field = testGroup.fields.find((f) => f.fieldName === option);
          return {
            fieldName: field.fieldName,
            unitAndRange: field.unitAndRange || "",
            value: "",
            subFields: field.subFields || [],
            // toShow: field.toShow,
            fieldId: field.fieldId,
            bioReferenceInterval: field.bioReferenceInterval,
          };
        }),
      };
    });
  };

  const handleRedirect = () => {
    // Check if no tests are selected
    console.log("This is the handleRedirect: ", selectedGroups);
    if (!selectedGroups.length) {
      toast.error("Please select at least one test.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const indexCbc = selectedGroups.findIndex(obj => 
      obj.options.includes("Complete Blood Count")
    );
    const bloodIndex = selectedGroups.findIndex(
      (opt) => opt.name === "Blood Markers"
    );

    if (indexCbc !== -1 && bloodIndex !== -1) {
      if (selectedGroups[bloodIndex].options.length > 1) {
        const fieldsToRemove = ["Hemoglobin"];
        selectedGroups[bloodIndex].options = selectedGroups[
          bloodIndex
        ].options.filter((option) => !fieldsToRemove.includes(option));
      } else {
        const fieldsToRemove = [];
        selectedGroups[bloodIndex].options = selectedGroups[bloodIndex].options.filter(
          (option) => !fieldsToRemove.includes(option)
        );
      }
    }

    const indexCompleteLiverFunctionTest = selectedGroups.findIndex(obj => 
      obj.options.includes("Complete Liver Function Tests")
    );
    const liverIndex = selectedGroups.findIndex(
      (opt) => opt.name === "Liver Markers"
    );

    console.log(liverIndex);
    if (indexCompleteLiverFunctionTest !== -1 && liverIndex !== -1) {
      if (selectedGroups[liverIndex].options.length > 1) {
        const fieldsToRemove = [
          "SGOT/ AST",
          "SGPT/ ALT",
          "GGTP",
          "ALP",
          "Bilirubin Total",
          "Bilirubin Direct",
          "Total Protein",
          "Albumin",
        ];
        selectedGroups[liverIndex].options = selectedGroups[
          liverIndex
        ].options.filter((option) => !fieldsToRemove.includes(option));
      } else {
        const fieldsToRemove = [];
        selectedGroups[liverIndex].options = selectedGroups[
          liverIndex
        ].options.filter((option) => !fieldsToRemove.includes(option));
      }
    }

    const indexCompleteRenalProfile = selectedGroups.findIndex(obj => 
      obj.options.includes("Complete Renal Profile")
    );
    const kidneyIndex = selectedGroups.findIndex(
      (opt) => opt.name === "Kidney Markers"
    );
    if (indexCompleteRenalProfile !== -1 && kidneyIndex !== -1) {
      if (selectedGroups[kidneyIndex].options.length > 1) {
        const fieldsToRemove = [
          "Creatinine",
          "Albumin",
          "Urea",
          "Uric Acid",
          "Sodium",
          "Potassium",
          "Total Protein",
        ];
        selectedGroups[kidneyIndex].options = selectedGroups[
          kidneyIndex
        ].options.filter((option) => !fieldsToRemove.includes(option));
        console.log(indexCompleteRenalProfile, "^^^", kidneyIndex);
      } else {
        const fieldsToRemove = [];
        selectedGroups[kidneyIndex].options = selectedGroups[
          kidneyIndex
        ].options.filter((option) => !fieldsToRemove.includes(option));
      }
    }
    console.log("Updated groups", selectedGroups);

    // Check if "General" is among the selected test groups
    const isGeneralSelected = selectedGroups.some(
      (group) => group.name === "Body Analysis"
    );

    if (isGeneralSelected) {
      // Filter "General" test data
      const generalTestData = selectedGroups.filter(
        (group) => group.name === "Body Analysis"
      );

      // Dispatch Redux action to update state for "General" test
      dispatch(addTests(formatSelectedGroups(generalTestData, tests)));

      navigate("/body-composition");
      return;
    }

    // For other test groups, dispatch the action and navigate to /general-test
    dispatch(addTests(formatSelectedGroups(selectedGroups, tests)));
    // navigate("/general-test");
    navigate("/payment/method1");
  };

  return (
    <div className="font-popins flex flex-col justify-center w-full max-w-10xl mx-auto">
      <div className="w-full max-w-4xl mx-auto rounded-2xl px-6 py-5 my-16 bg-white flex items-center justify-between shadow-lg relative">
        {/* Close Button */}
        <Link
          to={"/add-patient"}
          className="absolute top-3 right-3 cursor-pointer"
        >
          <img src={CrossIcon} alt="Close Icon" />
        </Link>
        {/* Patient Details */}
        <div>
          <h1 className="text-3xl font-bold text-[#845BB3] mb-4 px-2">
            {translations[langData].patientDetails}
          </h1>
          <div className="mt-2 flex flex-wrap gap-8 w-full max-w-xl">
            <div className="flex flex-col gap-3">
              <span className="flex gap-2 items-center py-2.5 px-3.5 font-semibold rounded-lg bg-[#D7D4F8] text-[#5C5CAB] w-full">
                <img src={UserIcon} alt="User Icon" className="w-6 h-6" />
                <p>
                  {translations[langData].name} : {patientDetails?.Name}
                </p>
              </span>
              <span className="flex items-center p-2 font-semibold rounded-lg bg-[#D7D4F8] text-[#5C5CAB] w-full">
                <img
                  src={MapPinIcon}
                  alt="Location Icon"
                  className="w-6 h-6 mr-2"
                />
                <p>
                  {translations[langData].place} : {patientDetails?.Place}
                </p>
              </span>
              <span className="flex items-center p-2 font-semibold rounded-lg bg-[#D7D4F8] text-[#5C5CAB] w-full">
                <img
                  src={GenderIcon}
                  alt="Gender Icon"
                  className="w-6 h-6 mr-2"
                />
                <p>
                  {translations[langData].gender} : {patientDetails?.Gender}
                </p>
              </span>
            </div>
            <div className="flex flex-col gap-3">
              <span className="flex items-center p-2 font-semibold rounded-lg bg-[#D7D4F8] text-[#5C5CAB] w-full">
                <img src={AgeIcon} alt="Age Icon" className="w-6 h-6 mr-2" />
                <p>
                  {translations[langData].age} : {patientDetails?.Age}
                </p>
              </span>
              <span className="flex items-center p-2 font-semibold rounded-lg bg-[#D7D4F8] text-[#5C5CAB] w-full">
                <img
                  src={UserIdIcon}
                  alt="Patient ID Icon"
                  className="w-6 h-6 mr-2"
                />
                <p>
                  {translations[langData].patientid}:{" "}
                  {patientDetails?.PatientID}
                </p>
              </span>
              <span className="flex items-center p-2 font-semibold rounded-lg bg-[#D7D4F8] text-[#5C5CAB] w-full">
                <img src={FlaskIcon} alt="Lab Icon" className="w-6 h-6 mr-2" />
                <p>
                  {translations[langData].labNo} : {labNo}
                </p>
              </span>
            </div>
          </div>
        </div>

        {/* Patient Profile and Actions */}
        <div className="flex flex-col items-center">
          <img
            className="w-28 h-28 rounded-full border-4 border-[#845BB3] mb-4"
            src={patientDetails?.imageURL}
            alt="Patient Profile"
          />
          {/* <img
            src={EditIcon}
            alt="Edit Icon"
            className="w-5 h-5 mb-4 cursor-pointer"
          /> */}

          <button
            onClick={handleRedirect}
            className="flex items-center bg-[#845BB3] text-white text-sm font-semibold px-4 py-2 rounded-lg"
          >
            <p className="mr-2">{translations[langData].testAutomatic}</p>
            <img
              src={AutomaticTestingIcon}
              alt="Automatic Testing Icon"
              className="w-6 h-6"
            />
          </button>
        </div>
      </div>

      {/* Select Tests Component */}
      <SelectTests
        tests={tests}
        selectedGroups={selectedGroups}
        setSelectedGroups={setSelectedGroups}
      />

      <div className="h-24"></div>
    </div>
  );
}
