// src/hooks/useFetchPatients.js
import { useState, useEffect } from "react";
import useDebounce from "./useDebounce"; // Import the debounce hook
import { useSelector } from "react-redux";
import axios from "axios";

const useCoughVerification = (formData) => {
  const loginData = useSelector((state) => state.authSlice.user.CentreID);
  const tests = useSelector((state) => state.testSlice.testsData || []);
  const [testData, setTestData] = useState(tests);
  const [response, setResponse] = useState([]);
  const [loader, setLoading] = useState(false);
  const [err, setError] = useState(null);
  // Debounce the searchTerm with a delay of 2000 milliseconds
  //   const debouncedSearchTerm = useDebounce(searchTerm, 2000);

  useEffect(() => {
    if (!formData) return;
    const verifyCoughSample = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          "https://swaasa.sandbox.swaasa.ai/api/verifycough",
          formData,
          {
            headers: {
              accessToken:
                "eyJhbGciOiJIUzUxMiIsImlhdCI6MTcyMTcxODU2OSwiZXhwIjoxOTgwOTE4NTY5fQ.eyJ1c2VySWQiOm51bGwsImxvZ2luSWQiOiJkaXNlYXNlX3ByZWRAc3dhYXNhLmFpIiwiclR5cGUiOiJhZG1pbiIsInBsYW4iOiJ2aXRhbHMscGx1cyx0Yixjb3BkLGFzdGhtYSxzcGlyb21ldHJ5IiwiYWNjZXNzVG9rZW4iOm51bGwsInVzZXJEZXRhaWxzIjpudWxsLCJwYXJlbnRJZCI6bnVsbCwiY29uZmlndXJhdGlvbiI6e319.w8l-tbdal_61Omqw6qB5lE6lsNqr-SN3HKcHsdf-xbiF61PXiWO71MzwX411gBAFk6QhWbg3BH9Mco57jyb07A", // Replace with your actual access token
              Authorization:
                "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MjcxMDQzMTd9.rW70KCsDPR9DU8SDRPDxtTFcX2mT2nb_1lZoYgoVzEk", // Replace with your actual JWT token
            },
            // withCredentials: true, // Ensure credentials are included in the request
          }
        );
        setResponse(response);
        console.log(response)
      } catch (err) {
        console.log(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    verifyCoughSample();
  }, [formData]);

  return { response, loader, err };
};

export default useCoughVerification;
