import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import API_ENDPOINTS from "../service/api";
import { loginReducer } from "../Redux_Services/slices/authSlice";

export default function useLogin() {
  const [form, setForm] = useState({
    technicianId: "",
    MachineId: "",
    password: "",
    rememberMe: false,
  });
  const [select, setSelect] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangeEvent = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (form.technicianId.trim() && form.MachineId.trim() && form.password.trim()) {
      if (!select) {
        toast.error("Please select version!", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      try {
        const resp = await axios.post(API_ENDPOINTS.signUpApi, {
          LabTechnicianID: form.technicianId,
          MachineID: form.MachineId,
          password: form.password,
        });

        if (!resp?.data.error) {
          resp.data.version = select;

          dispatch(loginReducer(resp.data));
          localStorage.setItem("cookieFallback", resp.data.token);
          localStorage.setItem("userDetails", JSON.stringify(resp.data));

          toast.success("Login successful!", {
            position: toast.POSITION.TOP_CENTER,
          });
          navigate("/");
        } else {
          toast.error("Login failed. Please check your credentials.", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.error(error.response?.data?.message || "An error occurred");
        toast.error(error.response?.data?.message || "An error occurred", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      toast.error("Please fill out all fields.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return {
    form,
    setForm,
    select,
    setSelect,
    handleChangeEvent,
    handleSubmit,
  };
}
