// src/Components/Navbar.js
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/icons/superceuticals-logo.png";

export default function Footer({ searchInput, handleInputChange }) {
  const nav = useNavigate();
  return (
    <div className="bg-[#845BB3]">
      <div className="pt-[4rem] pl-[4rem] pb-[4rem] flex items-center">
        <div className="w-[40%]">
          <img
            src={logo}
            alt="Superceuticals Logo"
            height="100px"
            width="400px"
          />
        </div>
        <h2
          onClick={() => nav("/privacypolicy")}
          className="w-[15%] pr-[10px] text-[20px] cursor-pointer hover:underline"
        >
          Privacy Policy
        </h2>
        <h2
          onClick={() => nav("/terms-and-condition")}
          className="w-[20%] text-[20px] cursor-pointer hover:underline"
        >
          Terms and Condition
        </h2>
        <h2
          onClick={() => nav("/refund-and-cancellation")}
          className="w-[20%] text-[20px] cursor-pointer hover:underline"
        >
          Refund's and Cancellation
        </h2>
      </div>
      <div className="flex justify-center">
        <hr className="w-[80%] border-none h-[1px] bg-[#ffffff40]" />
      </div>
      <div className="flex justify-center">
        <h2 className="text-[20px] py-[10px] text-[#ffffff]">
          2025 © Superceuticals Engineering Healthcare
        </h2>
      </div>
    </div>
  );
}
