import { createSlice } from "@reduxjs/toolkit";

// Retrieve stored data from localStorage
const storedTest = JSON.parse(localStorage.getItem("testsData")) || null;

const initialState = {
  testsData: storedTest || "", // Initialize with stored data
  referedBy: "",
  testInstruction: null,
  dateTime: null
};

const testSlice = createSlice({
  name: "testSlice",
  initialState,
  reducers: {
    addTests: (state, action) => {
      state.testsData = action.payload;
      localStorage.setItem("testsData", JSON.stringify(action.payload)); // Store in localStorage
    },
    addDateTime: (state, action) => {
      state.dateTime = action.payload;
      localStorage.setItem("dateTime", JSON.stringify(action.payload)); // Store in localStorage
    },
    addReferedBy: (state, action) => {
      state.referedBy = action.payload;
      localStorage.setItem("referedBy", JSON.stringify(action.payload));
    },
    addTestInstruction: (state, action) => {
      state.testInstruction = action.payload;
      localStorage.setItem("testInstruction", JSON.stringify(action.payload));
    },
  },
});

export const { addTests, addReferedBy, addTestInstruction,addDateTime } = testSlice.actions;
export default testSlice.reducer;
