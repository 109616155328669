// import { bloodPressure, routinepanel } from "./testSummery";

import { samplecardiac } from "./testSummery";

// import { urinecreatinine } from "./testSummery";

export const commentsData = [
  {
    hormonal: {
      comments: [
        "Beta HCG levels rise geometrically in the serum in the first 8 weeks of pregnancy.",
        "Detectable amounts of beta HCG are present 8-11 days after conception.",
        "During the second to fifth week, HCG levels double in about 1.5 days.",
        "After 5 weeks of gestation, the doubling time gradually increases to 2-3 days.",
        "Serial determination of HCG is helpful when abnormal pregnancy is suspected.",
        "In ectopic pregnancy and spontaneous abortion, HCG concentration increases slowly or decreases.",
        "Ultrasonography should detect a gestational sac in the uterus of all patients having HCG concentration > 6500 mIU/mL.",
        "Failure to detect a gestational sac 24 days or more after conception is presumptive evidence of ectopic pregnancy.",
        "The presence of twins approximately doubles the HCG concentration.",
      ],
      note: [
        "Consistently elevated HCG levels may be due to the presence of heterophilic antibodies, non-specific protein binding & HCG-like substances. False negative/positive results may be seen in patients receiving mouse monoclonal antibodies for diagnosis or therapy. This test is not recommended to screen Germ cell tumors in the general population.",
      ],
      clinical_use: [
        "Detect pregnancy & its abnormalities (ectopic & molar pregnancy).",
        "Screening of Down Syndrome & Trisomy 18.",
        "An aid in the management of Trophoblastic tumors.",
        "Monitoring Germ cell tumors, Non-seminomatous testicular tumors & less frequently Seminomas.",
        "Persistent HCG levels following therapy indicate the presence of residual disease.",
        "During chemotherapy, weekly HCG measurement is recommended. After remission, yearly HCG measurement is recommended.",
      ],
      increased_levels: [
        "Testicular tumors.",
        "Ovarian Germ cell tumors.",
        "Gestational Trophoblastic disease.",
        "Non-germ cell tumors – Melanoma & Carcinomas of breast, GI Tract, Lung & Ovary.",
        "Benign conditions like Cirrhosis, Duodenal ulcer, and Inflammatory bowel disease.",
      ],
    },
  },
  {
    cardiac: {
      comments: [
        "CK-MB is normally present in minute quantities and is undetectable with the tests available.",
        "Elevated CK-MB along with classic symptoms such as chest pain are indicative of a recent heart attack.",
        "Levels that are constantly fluctuating indicate a second or ongoing heart attack.",
        "If CK-MB is elevated, then it’s indicative of heart damage.",
        "Any form of strenuous exercise can also increase CK-MB levels in the blood.",
        "High CK-MB levels can also be seen if the kidney is damaged.",
      ],
      note: [
        "CPK-MB is a cardiac marker which assists in the diagnosis of Acute Myocardial Infarction (AMI). Increase in serum levels occurs between 3-5 hours after the onset of infarction, peaks at 16-20 hours, and returns to normal by 48-72 hours. The sensitivity of CPK-MB is dependent upon the time at which a sample is taken; thus, it is recommended to draw the sample at 0, 3 & 6 hours after the onset of chest pain. The decline in CPK-MB follows a predictable course; if the decline shows a sudden increase, it is suggestive of re-infarction.",
      ],
      bio_reference_interval: ["negative: <5 ng/mL", " positive: >5 ng/mL"],
      increased_levels: [
        "Acute Myocardial Infarction",
        "Myocarditis",
        "Rhabdomyolysis",
        "Stroke",
        "Electric Cardioversion",
      ],
    },
  },
  {
    ddimer: {
      D_DimerReference_Range: [
        "Normal: <500 ng/mL",
        "High: >500 ng/mL",
        "Note:",
        "D-Dimer is a fibrin degradation product, a small fragment of protein present in blood after a blood clot is degraded by fibrinolysis.",
        "D-Dimer is increased in deep venous thrombosis (DVT), pulmonary embolism (PE), or disseminated intravascular coagulation (DIC).",
        "In cases of high D-Dimer results, further testing for DVT and PE should be performed, mainly including radiological tests.",
        "A negative D-Dimer report virtually rules out thromboembolism.",
        "False positive readings can be due to various causes: liver disease, high rheumatoid factor, inflammation, malignancy, trauma, pregnancy, recent surgery, and advanced age.",
      ],

      comments: [
        "D-Dimer is a fibrin degradation product, a small fragment of protein present in blood after a blood clot is degraded by fibrinolysis.",
        "D-Dimer is increased in deep venous thrombosis (DVT), pulmonary embolism (PE), or disseminated intravascular coagulation (DIC).",
        "In cases of high D-Dimer results, further testing for DVT and PE should be performed, mainly including radiological tests.",
        "A negative D-Dimer report virtually rules out thromboembolism.",
        "False positive readings can be due to various causes: liver disease, high rheumatoid factor, inflammation, malignancy, trauma, pregnancy, recent surgery, as well as advanced age.",
      ],
      note: [
        "The degree of D-Dimer increase does not definitely correlate with the clinical severity of the associated disease state.",
        "Increased levels have a high probability of venous thromboembolism (VTE) and require clinical correlation.",
        "Lipemia falsely decreases D-Dimer levels.",
      ],
      bio_reference_interval: ["Normal: <500 ng/mL", "High: >500 ng/mL"],
      increased_levels: [
        "Deep venous thrombosis (DVT)",
        "Pulmonary embolism (PE)",
        "Disseminated intravascular coagulation (DIC)",
        "Large vessel thrombosis",
        "Soft tissue hematomas",
        "Recent surgery",
        "Active or recent bleeding",
        "Pregnancy",
        "Liver disease",
        "Malignancy",
        "Hyper-coagulable states",
      ],
    },
  },
  {
    ntprobnp: {
      comments: [
        "NT-ProBNP is a marker of atrial and ventricular distension due to increased intra-cardiac pressure, hence it is used as an aid in the diagnosis of congestive heart failure (CHF).",
        "The diagnostic strength of NT-ProBNP is its high sensitivity for ruling out heart failure; however, as the value increases, heart failure becomes more likely.",
      ],
      note: [
        "NT-pro-BNP value increases with age; elevated levels can be seen in apparently healthy individuals with increasing age.",
        "NT-pro-BNP values need to be interpreted in conjunction with the medical history, clinical findings, and other information.",
        "NT pro-BNP value <125 pg/ml excludes cardiac dysfunction with a high level of certainty in patients presenting with dyspnea.",
        "Lack of NT-ProBNP elevation has been reported if congestive heart failure (CHF) is very acute (first hour) or if there is ventricular inflow obstruction.",
      ],
      bio_reference_interval: [
        "Heart Failure Ruled Out (pg/ml):",
        "Under 75 Years: <125",
        "Over 75 Years: <450",
        "Rule Out Cut Off (All Patients): <300",
        "Optimal Cut Off (pg/ml):",
        "Under 50 Years: >450",
        "50 – 75 Years: >900",
        "Over 75 Years: >1800",
      ],
      clinical_uses: [
        "As an aid in the diagnosis of suspected cases of congestive heart failure (CHF).",
        "Detection of mild forms of cardiac dysfunction.",
        "To assess the severity of heart failure in already diagnosed cases of CHF.",
        "For risk stratification of patients with Acute Coronary Syndrome (ACS) and CHF.",
        "For monitoring therapy in patients with Left Ventricular dysfunction.",
      ],
    },
  },
  {
    CKMB: {
      comments: [
        "CPK-MB is a cardiac marker which assists in the diagnosis of Acute Myocardial Infarction (AMI). Increase in serum levels occurs between 3-5 hours after the onset of infarction, peaks at 16-20 hours and returns to normal by 48-72 hours. The sensitivity of CPK-MB is dependent upon the time at which a sample is taken, thus it is recommended to draw the sample at 0, 3 & 6 hours after the onset of chest pain. The decline in CPK-MB follows a predictable course & if the decline shows a sudden increase, it is suggestive of re-infarction",
      ],
      Interpretations: [
        "1. CK-MB is normally present in minute quantities and are undetectable with the tests available.",
        "2. Elevated CK-MB along with classic symptoms such as chest pain are indicative of a recent heart attack. Levels that are constantly fluctuating indicate a second or ongoing heart attack.",
        "3. If CK-MB is elevated, then it’s indicative of heart damage",
        "4. Any form of strenuous exercise can also increase can also show high levels of this enzyme in the blood. High CK-MB level can also be seen if the kidney is damaged.",
      ],
      bio_reference_interval: ["Negative: <5 ng/mL", "Positive: >5 ng/mL"],
      increased_levels: [
        "Acute Myocardial infarction, Myocarditis, Rhabdomyolysis, Stroke & Electric cardioversion",
      ],
    },
  },
  {
    tnl: {
      summary: [
        "Troponins are a family of proteins found in skeletal and heart (cardiac) muscle fibres that are responsible for muscle contraction. Troponin-I detects the presence of troponin in blood and presence in excessive amounts can be indicative of heart attack.",
      ],
      bio_reference_interval: [
        "Normal Levels: <0.05 ng/mL",
        "Observational Zone/Above Optimal: 0.05 – 0.50 ng/mL",
        "High/Requires Attention: >0.50 ng/mL",
      ],
      clinical_uses: [
        " A high troponin and even slight elevations can pinpoint towards some degree of heart damage. When a person has significantly elevated troponin levels and, in particular, a rise in the results from a series of tests done over several hours, then in all probabilities it means that the person has had a recent heart attack. When the test shows normal troponin, values measured over specific time intervals, it rules out the possibility of any heart damage. In such a case, signs and symptoms could be due to causes that are not pertaining to the heart.",
      ],
    },
  },
  // {
  //   hscrp: {
  //     comments: [
  //       "High sensitivity C Reactive Protein (hsCRP) significantly improves cardiovascular risk assessment as it is the strongest predictor of future coronary events.",
  //       "hsCRP reveals the risk of future myocardial infarction and stroke among healthy men and women, independent of traditional risk factors.",
  //       "It identifies patients at risk of first myocardial infarction even with low to moderate lipid levels.",
  //       "The risk of recurrent cardiovascular events correlates well with hsCRP levels.",
  //       "hsCRP is a powerful independent risk determinant in the prediction of incident diabetes.",
  //     ],
  //     note: [
  //       "To assess vascular risk, it is recommended to test hsCRP levels 2 or more weeks apart and calculate the average.",
  //     ],
  //     bio_reference_interval: [
  //       "Low: <1.0 mg/L",
  //       "Average: 1.0 – 3.0 mg/L",
  //       "High: 3.0 – 10.0 mg/L",
  //       "Persistent elevation may represent Non-Cardiovascular Inflammation.",
  //     ],
  //     clinical_uses: [
  //       "Assessment of cardiovascular risk.",
  //       "Prediction of future myocardial infarction and stroke.",
  //       "Identification of patients at risk for diabetes.",
  //     ],
  //   },
  // },
  {
    NT_pro_BNP: {
      Category_Heart_Failure_Ruled_Out: [
        "<75 Years old <125",
        ">75 Years old <450",
      ],
    },
  },
  // {
  //   LipidProfile: {
  //     "Summary": [
  //       "Cholesterol is a vital chemical present in the body and performs many functions such as coating the cells of the body, forming the cell membrane, and producing certain components called bile acids which aid in the digestion of fats.",
  //       "Additionally, it performs an imperative role in the production of certain vital nutrients like vitamin D and sex hormones like estrogen, progesterone, and testosterone (steroid hormones).",
  //       "Elevated levels of cholesterol indicate a risk for heart disease, atherosclerosis, and stroke."
  //     ],
  //     "As_recommended_by_National_Lipid_Association_2014": [],
  //     "for_adults": [
  //       {
  //         "Analyte": "Cholesterol, Total",
  //         "Optimal (mg/dL)": "<200",
  //         "Above Optimal (mg/dL)": "-",
  //         "Borderline High (mg/dL)": "200 – 239",
  //         "High (mg/dL)": ">= 240",
  //         "Very High (mg/dL)": "-"
  //       },
  //       {
  //         "Analyte": "Triglycerides",
  //         "Optimal (mg/dL)": "<150",
  //         "Above Optimal (mg/dL)": "-",
  //         "Borderline High (mg/dL)": "150 – 199",
  //         "High (mg/dL)": "200 – 499",
  //         "Very High (mg/dL)": ">=500"
  //       },
  //       {
  //         "Analyte": "HDL",
  //         "Optimal (mg/dL)": ">40 (males), >50 (females)",
  //         "Above Optimal (mg/dL)": "-",
  //         "Borderline High (mg/dL)": "-",
  //         "High (mg/dL)": "-",
  //         "Very High (mg/dL)": "-"
  //       },
  //       {
  //         "Analyte": "LDL",
  //         "Optimal (mg/dL)": "<100",
  //         "Above Optimal (mg/dL)": "101 - 129",
  //         "Borderline High (mg/dL)": "130 – 159",
  //         "High (mg/dL)": "160 – 189",
  //         "Very High (mg/dL)": ">=190"
  //       },
  //       {
  //         "Analyte": "Non HDL Cholesterol",
  //         "Optimal (mg/dL)": "<130",
  //         "Above Optimal (mg/dL)": "130 – 159",
  //         "Borderline High (mg/dL)": "160 - 189",
  //         "High (mg/dL)": "190 – 219",
  //         "Very High (mg/dL)": ">=220"
  //       }
  //     ]
  //   }
  // }
  // {
  // {
  //   HbA1c: {
  //     summery: [],
  //   },
  // },

  { diabetic: {} },
  { albumin: {} },
  { Alp: {} },
  { amylase: {} },
  { BetaHCG: {} },
  { BilirubinTotal: {} },
  { bloodmarkers: {} },
  { BloodPressure: {} },
  { bloodPressure: {} },
  { completebloodcount: {} },
  { Chikungunya: {} },
  // { ChikungunyaIgM: {} },
  { Cholera: {} },
  { CKMB: {} },
  { covid19Ag: {} },
  // { creatinine: {} },
  { CRP: {} },
  { ddimer: {} },
  { DengueDuo: {} },
  { DengueIgM: {} },
  { DengueNS1: {} },
  { ecg: {} },
  { Elephantiasis: {} },
  { FT4FreeThyroxine: {} },
  { ggtp: {} },
  { Glucose: {} },
  { hemoglobin: {} },
  { HepatitisB: {} },
  { hepatitisbag: {} },
  { HepatitisC: {} },
  { hepatitisc: {} },
  { hiv: {} },
  { HIV12: {} },
  { hpylori: {} },
  { HsCRP: {} },
  { IFob: {} },
  { completerenalprofile: {} },
  { kidneymarkers: {} },
  { Leishmania: {} },
  { LegionellaAg: {} },
  { Leptospira: {} },
  { lhluteinizinghormone: {} },
  { lipase: {} },
  { liverprofile: {} },
  { LFT: {} },
  { malariapfpan: {} },
  { malariapfpv: {} },
  { NTProBNP: {} },
  { PCT: {} },
  { physicalparameters: {} },
  { potassium: {} },
  { RsvAg: {} },
  { routinepanel: {} },
  { samplepct: {} },
  { scrubtyphus: {} },
  { sgotast: {} },
  { SGOTSGPT: {} },
  { sgptalt: {} },
  { sodium: {} },
  { spo2: {} },
  { spneumoniaag: {} },
  { syphillishivcombo: {} },
  { temperature: {} },
  { totalt4thyroxine: {} },
  { totalprotein: {} },
  { TnI: {} },
  { TSH: {} },
  { Typhoid: {} },
  // { urea: {} },
  { uricacid: {} },
  { urinecreatininemicroalbumin: {} },
  // {urinecreatinine:{}},
  { ggtp: {} },
  { alp: {} },
  { bilirubindirect: {} },
  { bilirubintotal: {} },
  { amylase: {} },
  { lipase: {} },
  { livermarkers: {} },
  { hemoglobin: {} },
  { bloodmarkers: {} },
  { streptococcusaag: {} },
  { UrineAlbumin: {} },
  { t3: {} },
  { freet4thyroxine: {} },
  { t4: {} },
  { denguens1ag: {} },
  { dengueigmigg: {} },
  { dengueigmigg: {} },
  { hemoglobin: {} },
  { samplecardiac: {} },
  { kidneymarkers: {} },
];
 