import React, { useRef, useCallback, useState, useEffect } from "react";
import Webcam from "react-webcam";

const CameraModal = ({ isOpen, onClose, onCapture }) => {
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [videoConstraints, setVideoConstraints] = useState({
    facingMode: "user",
  });
  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);

  // Fetch available video input devices
  useEffect(() => {
    const fetchDevices = async () => {
      const allDevices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = allDevices.filter(
        (device) => device.kind === "videoinput"
      );
      setDevices(videoDevices);
      if (videoDevices.length > 0) {
        setSelectedDeviceId(videoDevices[0].deviceId); // Default to the first device
        setVideoConstraints({ deviceId: videoDevices[0].deviceId });
      }
    };

    fetchDevices();
  }, []);

  // Capture Image from Webcam
  const captureImage = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setCapturedImage(imageSrc);
      } else {
        console.error("Failed to capture image.");
      }
    }
  }, [webcamRef]);

  // Handle Submit
  const handleSubmit = () => {
    if (capturedImage) {
      onCapture(capturedImage);
      onClose();
    }
  };

  // Handle Camera Selection
  const handleCameraChange = (event) => {
    const deviceId = event.target.value;
    setSelectedDeviceId(deviceId);
    setVideoConstraints({ deviceId });
  };

  if (!isOpen) {
    return null; // Do not render anything if modal is not open
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded-md relative">
        {capturedImage ? (
          // Display the captured image if available
          <img
            src={capturedImage}
            alt="Captured"
            className="w-full h-auto rounded-md mb-4"
          />
        ) : (
          <>
            <select
              value={selectedDeviceId || ""}
              onChange={handleCameraChange}
              className="mb-4 p-2 border rounded-md w-full"
            >
              {devices.map((device) => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label || `Camera ${device.deviceId}`}
                </option>
              ))}
            </select>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/png"
              videoConstraints={videoConstraints}
              className="w-full h-auto rounded-md mb-4"
            />
          </>
        )}

        <div className="flex justify-between">
          {!capturedImage ? (
            <button
              onClick={captureImage}
              className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
            >
              Capture Image
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-md"
            >
              Submit
            </button>
          )}
          <button
            onClick={onClose}
            className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CameraModal;
