// src/hooks/useFetchPatients.js
import { useState, useEffect } from "react";
import useDebounce from "./useDebounce"; // Import the debounce hook
import { useSelector } from "react-redux";
import axios from "axios";
const useRiskScoreAssessment = () => {
  const loginData = useSelector((state) => state.authSlice.user.CentreID);
  const tests = useSelector((state) => state.testSlice.testsData || []);
  const assessmentId = useSelector(
    (state) => state?.diseaseSlice?.diseaseData?.assessmentId
  );
  const symptoms = useSelector(
    (state) => state?.diseaseSlice?.diseaseData?.symptoms
  );
  const [response, setApiResponse] = useState({});

  const [testData, setTestData] = useState(tests);
  // Debounce the searchTerm with a delay of 2000 milliseconds
  //   const debouncedSearchTerm = useDebounce(searchTerm, 2000);

  useEffect(() => {
    const form = new FormData();
    form.append("assessmentId", assessmentId);
    form.append(
      "symptoms",
      JSON.stringify({
        cough_at_night: symptoms.cough_at_night || 0,
        sputum: symptoms.sputum || 0,
        wheezing: symptoms.wheezing || 0,
        frequent_cough: symptoms.frequent_cough || 0,
        pain_in_chest: symptoms.pain_in_chest || 0,
        shortness_of_breath: symptoms.shortness_of_breath || 0,
        fatigue: symptoms.fatigue || 0,
        sweat_heavy_at_night: symptoms.sweat_heavy_at_night || 0,
        loss_of_appetite: symptoms.loss_of_appetite || 0,
        weight_loss: symptoms.weight_loss || 0,
        cough_with_sputum_and_blood: symptoms.cough_with_sputum_and_blood || 0,
        evening_rise_in_temperature: symptoms.evening_rise_in_temperature || 0,
        known_person_having_active_tb:
          symptoms.known_person_having_active_tb || 0,
        active_tb_past: symptoms.active_tb_past || 0,
        family_history_of_asthma: symptoms.family_history_of_asthma || 0,
        active_asthma_past: symptoms.active_asthma_past || 0,
        smoking: symptoms.smoking || 0,
        exposure_to_any_kind_of_smoke:
          symptoms.exposure_to_any_kind_of_smoke || 0,
        temperature_F: symptoms.temperature_F || 0,
        pulse_rate: symptoms.pulse_rate || 0,
        respiratory_rate: symptoms.respiratory_rate || 0,
        oxygen_saturation: symptoms.oxygen_saturation || 0,
        blood_pressure: symptoms.blood_pressure || 0,
        frequent_cough_duration: symptoms.frequent_cough_duration || 0,
      })
    );
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://swaasa.sandbox.swaasa.ai/api/riskscore_assessment",
          form,
          {
            headers: {
              Authorization:
                "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiR3V3YWh0aSBUZXN0ZXIiLCJkZXNpZ25hdGlvbiI6IlRlY2huaWNpYW4iLCJMYWJUZWNobmljaWFuSUQiOiJURUNINDMzODUzIiwicm9sZSI6InRlY2giLCJpYXQiOjE3MzA5NTIwOTB9.nUbcXwmia3ovc6DUAQpV4iVHzGrBzI90lxQ2gYB8yD0",
              "x-api-key": "Asdwz1pKsRqIIFnXH",
              "content-type": "multipart/form-data",
            },
          }
        );
        setApiResponse(response);
      } catch (err) {
        console.error("Error submitting data:", err);
      }
    };

    fetchData();
  }, [assessmentId, symptoms]);

  return response;

};

export default useRiskScoreAssessment;
