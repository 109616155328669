import React, { useState, useEffect, useRef } from "react";
import reload from "../../assets/icons/reload.svg";
import beaker1 from "../../assets/icons/beaker-1.svg";
import beaker2 from "../../assets/icons/beaker-2.svg";
import report from "../../assets/icons/report.svg";
import compass from "../../assets/icons/compass.svg";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addTests, addReferedBy } from "../../Redux_Services/slices/testSlice";
import axios from "axios";
import CoughModal from "../../Components/CoughModal";
import CameraModal from "../../Components/CameraModal";
import { translations } from "../../translation/translate";
import CoughTestModal from "./CoughTestModal";
import StethoscopeModal from "./StethoscopeModal";
import { toast } from "react-toastify";
import useHandleLatestResult from "../../Hooks/handleLatestResult";
import MultipleCaptureModal from "../../Components/MultipleCaptureModal";

const GeneralTest = () => {
  const [testCodeFields, setTestCodeFields] = useState([]);
  const dispatch = useDispatch();
  const tests = useSelector((state) => state.testSlice.testsData || []);
  console.log("____________________ tests", tests);
  const referedBy = useSelector((state) => state.testSlice.referedBy || "");
  const langData = useSelector((state) => state.langSlice.langData || []);
  const loginUser = useSelector((state) => state.authSlice.user);
  const patientDetails = useSelector((state) => state.patientSlice.patientData);
  console.log("__login", loginUser);

  const [testData, setTestData] = useState(tests);
  const [tempData, setTempData] = useState();
  // const [extractedProfile, setExtractedProfile] = useState(null);
  const [referby, setReferby] = useState(referedBy);
  const [results, setResults] = useState([]);
  const [hbA1cValue, setHbA1cValue] = useState(null);
  const hdlValue = useRef();
  const ldlValue = useRef();
  const bun = useRef();
  const creatinine = useRef();
  const albumin = useRef();
  const globulin = useRef();
  const sgot = useRef();
  const sgpt = useRef();
  const [hdlldlvalue, setHdlldlvalue] = useState(0);
  const [eAGValue, setEAGValue] = useState(0);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(null); // Track which modal to open
  const [capturedImage, setCapturedImage] = useState([]); // State to hold captured image
  const [loading, setLoading] = useState(false);
  const [btn, setBtn] = useState(false);
  const [selectedThypoid, setSelectedThypoid] = useState("");
  const [selectedLeishmania, setSelectedLeishmania] = useState("");
  const [fieldId, setFieldId] = useState("");
  const [status, setStatus] = useState([]);
  // const response = useHandleLatestResult(fieldId)
  // const [selectedLeishmania, setSelectedLeishmania] = useState("");
  const redirect = () => {
    navigate("/tests", { replace: true });
  };
  const checkData = () => {
    if (testData.length === 0) {
      toast.error("Resubmission Not allowed!", {
        position: toast.POSITION.TOP_CENTER,
      });
      // alert("Resubmission not allowed! Return to test page!")
      redirect();
    }
  };

  useEffect(() => {
    checkData();
  }, []);

  const extractField = async () => {
    // Create a copy of the current state
    let extractedField;
    let updatedData = [];
    let noSubfield = [];
    let last = 0;
    updatedData = testData.map((obj) => ({
      ...obj,
      fields: obj.fields.filter((field) => {
        console.log(field.subFields.length);
        if (field.subFields.length !== 0) {
          extractedField = field; // Set extracted profile
          let newData = {
            testFieldName: extractedField.fieldName,
            fields: [extractedField],
          };
          updatedData.push(newData);
          console.log(updatedData);
          last += 1;
          console.log(last);
        } else {
          console.log("NoSubfields", field);
          noSubfield.push(field);
          last += 1;
          console.log(last, "&&", obj.fields.length);
        }
        if (last === obj.fields.length) {
          let newData = {
            testFieldName: obj.testFieldName,
            fields: noSubfield,
          };
          updatedData.push(newData);
          console.log(newData);
          last = 0;
          noSubfield = [];
        }
        if (updatedData.find((item) => item.fields.length === 0)) {
          const a = updatedData.findIndex((item) => item.fields.length === 0);
          console.log(a);
          console.log(updatedData[a]);
          updatedData.splice(a, 1);
        }
        setTestData(updatedData);
        return true;
      }),
    }));
  };

  // const handleCapture = async(image) => {
  //   setCapturedImage(image);
  //   // Optionally, you can do something with the captured image here,
  //   // like sending it to a server or displaying it elsewhere
  //   console.log("Captured Image:", image);
  //   try{
  //     const response = await axios.post("https://api.superceuticals.in/octoscope/add-octoscope",
  //       image,{
  //         headers:{

  //         }
  //       }
  //     )
  //   }
  //   catch(error){

  //   }
  // };

  // const handleCapture = async (image) => {
  //   setCapturedImage(image);

  //   // Log the captured image for debugging
  //   console.log("Captured Image:", image);

  //   try {
  //     // const token = localStorage.getItem("authToken"); // Example method to retrieve token

  //     // if (!token) {
  //     //   throw new Error("Authorization token is missing");
  //     // }

  //     // Make the API request
  //     const response = await axios.post(
  //       "https://api.superceuticals.in/octoscope/add-octoscope",
  //       image,
  //       {
  //         headers: {
  //           Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MzQwNzIxMTd9.QAXnTbPa1MCM7NaLP-fwG4wgJCF-2m1tsdq60f8ehjs`,
  //         },
  //       }
  //     );

  //     console.log("API Response:", response.data);
  //     console.log("CONFIDENCE: ",response.data.Confidence);
  //     console.log("Prediction: ",response.data.Prediction);

  //     console.log("testDataxyz: ",testData);
  //   } catch (error) {
  //     // Handle errors gracefully
  //     console.error("Error while capturing the image:", error.message);
  //   }
  // };

  const handleCapture = async (image) => {
    setCapturedImage(image);

    console.log("Captured Image:", image);

    try {
      if (image) {
        setLoading(true);

        const response = await axios.post(
          "https://api.superceuticals.in/octoscope/add-octoscope",
          image,
          {
            headers: {
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MzQwNzIxMTd9.QAXnTbPa1MCM7NaLP-fwG4wgJCF-2m1tsdq60f8ehjs`,
            },
          }
        );

        console.log("API Response:", response.data);

        setLoading(false);
        const { Confidence, Prediction } = response.data;

        // Create a deep copy of the test data to avoid mutation
        const updatedTestData = JSON.parse(JSON.stringify(testData));

        // Update the values in the nested structure
        updatedTestData.forEach((test) => {
          test.fields.forEach((field) => {
            field.subFields.forEach((subField) => {
              if (subField.fieldName === "Confidence") {
                subField.value = Confidence;
              } else if (subField.fieldName === "Prediction") {
                subField.value = Prediction;
              } else if (subField.fieldName === "Cancer-Octoscope") {
                subField.value = "True";
              }
            });
          });
        });

        // Log the updated data for debugging
        console.log("Updated Test Data:", updatedTestData);

        // Update the state with the modified data
        setTestData(updatedTestData);
        console.log("setTestData(updatedTestData): ");
      } else {
        toast.error("Error in capturing image.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error("Error while capturing the image:", error.message);
    }
  };

  const handleMultipleCapture = (imageSources) => {
    setCapturedImage(imageSources);
    if (!Array.isArray(imageSources) || imageSources.length === 0) {
      console.error("Invalid image sources provided.");
      return;
    }

    // Create a deep copy of testData to avoid mutations
    const updatedTestData = JSON.parse(JSON.stringify(testData));

    updatedTestData.forEach((test) => {
      test.fields.forEach((field) => {
        if (field.fieldName === "ENT") {
          // Ensure subFields exists
          if (!field.subFields) {
            field.subFields = [];
          }

          // Add each image as a new subField
          imageSources.forEach((imageSrc, index) => {
            const uniqueFieldName = `Image${field.subFields.length + 1}`; // Ensure unique naming
            field.subFields.push({
              fieldName: uniqueFieldName,
              value: imageSrc,
              parentFieldName: "ENT",
              unitAndRange: "",
            });
          });

          // Update the field's value to "1" to indicate images are attached
          field.value = "1";
        }
      });
    });

    // Log updated test data for debugging
    console.log("Updated Test Data:", updatedTestData);
    // Update the testData state
    setTestData(updatedTestData);
    setTempData(updatedTestData);
    setBtn(true);
  };

  const openModal = (fieldName) => {
    setActiveModal(fieldName);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setActiveModal(null); // Reset active modal when closing
  };
  const machineId = loginUser.MachineID;
  console.log("machineId", machineId);

  useEffect(() => {
    const testArray = Array.isArray(tests) ? tests : [];
    setTestData(testArray); // Updates testData state
    setReferby(referedBy || "");
    const kidneyIndex = testData.findIndex(
      (opt) => opt.testFieldName === "Kidney Markers"
    );
    // if (kidneyIndex !== -1 && testData[kidneyIndex]?.fields.length > 1) {
    //   if (kidneyIndex !== -1) {
    //     extractRenal();
    //   }
    // } else {
    extractField();
    // }
    console.log(testData);
  }, [tests, referedBy]);

  // useEffect(() => {
  //   setHdlldlvalue((hdlValue / ldlValue).toFixed(2));
  //   const updatedTestData = testData.map((test) => ({
  //     ...test,
  //     fields: test.fields.map((field) => {
  //       if (field.fieldName === "HDL/LDL") {
  //         return { ...field, value: hdlldlvalue };
  //       }
  //       return field;
  //     }),
  //   }));
  //   setTestData(updatedTestData);
  // }, [hdlValue, ldlValue, hdlldlvalue]);
  console.log("74 = ", eAGValue);
  const handleChange = (event) => {
    setSelectedThypoid(event.target.value); // Update the selected value
  };

  const calEAG = (data) => {
    const eAG = Math.round(28.7 * +data - 46.7).toFixed(2);
    setEAGValue(eAG);
    console.log("94", data);
    const updatedTestData = testData.map((test) => ({
      ...test,
      fields: test.fields.map((field) => {
        field.subFields.map((subField) => {
          if (subField.fieldName === "eAG") {
            return { ...field, value: eAG };
          }
          return subField;
        });
        return field;
      }),
    }));
    setTestData(updatedTestData);
  };

  const handleInputChange = (sectionIndex, fieldIndex, value) => {
    const updatedTestData = testData.map((test, sIndex) => {
      if (sIndex === sectionIndex) {
        return {
          ...test,
          fields: test.fields.map((field, fIndex) => {
            if (field.fieldName === "HDL/LDL") {
              const val = (hdlValue.current / ldlValue.current).toFixed(2);
              return {
                ...field,
                value: val,
              };
            }
            if (field.fieldName === "Typhoid") {
              // console.log(selectedThypoid);
              return {
                ...field,
                value: value,
              };
            } else if (field.fieldName === "Cholera") {
              // console.log(selectedCholerae);
              return {
                ...field,
                value: value,
              };
            } else if (field.fieldName === "Chikungunya") {
              return {
                ...field,
                value: value,
              };
            } else if (field.fieldName === "Leptospira") {
              return {
                ...field,
                value: value,
              };
            } else if (field.fieldName === "HIV") {
              return {
                ...field,
                value: value,
              };
            } else if (field.fieldName === "H. Pylori") {
              return {
                ...field,
                value: value,
              };
            } else if (field.fieldName === "Hepatitis B Ag") {
              return {
                ...field,
                value: value,
              };
            } else if (field.fieldName === "Scrub Typhus") {
              return {
                ...field,
                value: value,
              };
            } else if (field.fieldName === "Hepatitis C") {
              return {
                ...field,
                value: value,
              };
            } else if (field.fieldName === "Elephantiasis") {
              return {
                ...field,
                value: value,
              };
            } else if (field.fieldName === "Malaria pf PAN") {
              return {
                ...field,
                value: value,
              };
            } else if (field.fieldName === "Malaria Pf/Pv") {
              return {
                ...field,
                value: value,
              };
            } else if (field.fieldName === "Syphillis/HIV Combo") {
              return {
                ...field,
                value: value,
              };
            } else if (field.fieldName === "Dengue Duo") {
              return {
                ...field,
                value: value,
              };
            } else if (field.fieldName === "Leishmania") {
              console.log("Leishmania", selectedLeishmania);
              return {
                ...field,
                value: value,
              };
            }
            // else if (field.fieldName === "Total Cholesterol") {
            //   return {
            //     ...field,
            //     value: value,
            //   };
            // }
            // else if (field.fieldName === "Triglycerides (TG)") {
            //   return {
            //     ...field,
            //     value: value,
            //   };
            // }
            else if (fIndex === fieldIndex) {
              console.log(fIndex, "&&", fieldIndex);
              return {
                ...field,
                value: value,
              };
            }
            return field;
          }),
        };
      }
      return test;
    });
    setTestData(updatedTestData);
  };
  const handleSubTestInputChange = (
    sectionIndex,
    fieldIndex,
    subFieldIndex,
    value
  ) => {
    const updatedTestData = testData.map((test, sIndex) => {
      if (sIndex === sectionIndex) {
        return {
          ...test,
          fields: test.fields.map((field, fIndex) => {
            if (
              fIndex === fieldIndex &&
              field.subFields &&
              Array.isArray(field.subFields)
            ) {
              return {
                ...field,
                subFields: field.subFields.map((subField, subIndex) => {
                  if (subField.fieldName === "eAG") {
                    const val = Math.round(28.7 * +value - 46.7).toFixed(2);
                    return {
                      ...subField,
                      value: val,
                    };
                  } else if (subField.fieldName === "HDL/LDL") {
                    const val = (hdlValue.current / ldlValue.current).toFixed(
                      2
                    );
                    return {
                      ...subField,
                      value: val,
                    };
                  } else if (subField.fieldName === "Ratio BUN: Creatinine") {
                    const val = (bun.current / creatinine.current).toFixed(2);
                    return {
                      ...subField,
                      value: val,
                    };
                  } else if (subField.fieldName === "Ratio Albumin: Globulin") {
                    const val = (albumin.current / globulin.current).toFixed(2);
                    return {
                      ...subField,
                      value: val,
                    };
                  } else if (subField.fieldName === "Ratio SGOT: SGPT") {
                    const val = (sgot.current / sgpt.current).toFixed(2);
                    return {
                      ...subField,
                      value: val,
                    };
                  } else if (subIndex === subFieldIndex) {
                    return {
                      ...subField,
                      value: value,
                    };
                  }
                  return subField;
                }),
              };
            }
            return field;
          }),
        };
      }
      return test;
    });

    setTestData(updatedTestData);
  };

  //  console.log("testData = " , testData.fields[0].fieldName)
  // console.log("92 = ", testData[0].fields[0].subFields);
  const handleReferBy = (e) => {
    setReferby(e.target.value);
  };

  const fieldIds = testData.flatMap((test) =>
    test.fields.map((field) => field.fieldId)
  );

  let fieldName1 = false;
  tests[0]?.fields.map((field) => {
    if (field.fieldName === "Complete Blood Count") {
      fieldName1 = true;
    }
  });
  console.log(fieldName1);
  const handlePreviousResults = async () => {
    console.log("Fetching latest results...");
    setLoading(true);
    setFieldId(fieldId);
    try {
      const response = await axios(
        `https://api.superceuticals.in/test-one/getCbc?MachineID=${machineId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MjcxMDQzMTd9.rW70KCsDPR9DU8SDRPDxtTFcX2mT2nb_1lZoYgoVzEk",
          },
        }
      );

      const data = response.data;
      console.log(data.getTest[0].mydata);
      if (data.getTest && Array.isArray(data.getTest[0].mydata)) {
        const resultMap = {};
        data.getTest[0].mydata.forEach((testItem) => {
          resultMap[testItem.test_code] = testItem.result;
        });
        console.log(resultMap);
        const updateFields = (fields) => {
          return fields.map((field) => {
            if (field.subFields && Array.isArray(field.subFields)) {
              if (field.fieldName === "Hemoglobin") {
                return {
                  ...field,
                  value: resultMap[field.fieldId] || field.value,
                };
              }

              return {
                ...field,
                // value: resultMap[field.fieldId] || field.value,
                subFields: field.subFields.map((item) => {
                  return {
                    ...item,
                    value: resultMap[item.fieldId] || item.value,
                  };
                }),
              };
            }
            return field;
          });
        };
        const updatedTestData = testData.map((test) => {
          return {
            ...test,
            fields: updateFields(test.fields),
          };
        });
        console.log(updatedTestData);
        setTestData(updatedTestData);
      } else {
        return { res: "No Data", code: fieldId, error: "noData" };
      }
      return { res: data.note, code: fieldId, error: "false" };
    } catch (error) {
      console.error("Error fetching latest results:", error);
      if (error.response) {
        console.error("Server responded with:", error.response.data);
      }
      return { res: "No Test Code", code: fieldId, error: "noCode" };
    } finally {
      setLoading(false);
    }
  };

  console.log("282", testCodeFields);
  const handleStethoscope = async (fieldName) => {
    try {
      const response = await axios.get("http://localhost:8000/status", {
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MjcxMDQzMTd9.rW70KCsDPR9DU8SDRPDxtTFcX2mT2nb_1lZoYgoVzEk",
        },
      });
      console.log("Response:", response.data); // Handle response as needed
      openModal(fieldName);
    } catch (error) {
      openModal(fieldName);
      console.error("Error fetching status:", error); // Log error for debugging
    }
  };

  const handleLatestResult = async (fieldId) => {
    console.log("Fetching latest results...", fieldId);
    setLoading(true);
    setFieldId(fieldId);
    try {
      const response = await axios.post(
        `https://api.superceuticals.in/test-two/getResults/${machineId}`,
        { test_code: fieldId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MjcxMDQzMTd9.rW70KCsDPR9DU8SDRPDxtTFcX2mT2nb_1lZoYgoVzEk",
          },
        }
      );

      const data = response.data;
      console.log(data);
      if (data.getTest && Array.isArray(data.getTest.mydata)) {
        const resultMap = {};
        data.getTest.mydata.forEach((testItem) => {
          resultMap[testItem.test_code] = testItem.result;
        });
        const updateFields = (fields) => {
          let hba1cVAL;
          return fields.map((field) => {
            if (
              field.fieldId &&
              resultMap[field.fieldId] &&
              field.subFields.length === 0
            ) {
              return {
                ...field,
                value: resultMap[field.fieldId] || field.value,
              };
            }
            if (
              field.subFields &&
              field.subFields != 0 &&
              Array.isArray(field.subFields)
            ) {
              return {
                ...field,
                subFields: field.subFields.map((item) => {
                  if (item.fieldName === "HbA1c") {
                    hba1cVAL = resultMap[item.fieldId] || item.value;
                  }
                  if (item.fieldName === "eAG") {
                    const calVal = Math.round(28.7 * +hba1cVAL - 46.7).toFixed(
                      2
                    );
                    return {
                      ...item,
                      value: calVal,
                    };
                  }
                  return {
                    ...item,
                    value: resultMap[item.fieldId] || item.value,
                  };
                }),
              };
            }
            return field;
          });
        };
        const updatedTestData = testData.map((test) => {
          return {
            ...test,
            fields: updateFields(test.fields),
          };
        });
        console.log(updatedTestData);
        setTestData(updatedTestData);

      } else {
        return { res: "No Data", code: fieldId, error: "noData" };
      }
      return { res: data.note, code: fieldId, error: "false" };
    } catch (error) {
      console.error("Error fetching latest results:", error);
      if (error.response) {
        console.error("Server responded with:", error.response.data);
      }
      return { res: "No Test Code", code: fieldId, error: "noCode" };
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (
      testData.find((test) => {
        if (test.testFieldName === "AI Analysis") {
          const testField = test.fields.find(
            (field) => field.fieldName === "ENT"
          );
          return testField;
        }
      })
    ) {
      console.log("Found ENT");
      dispatch(addTests(testData));
      dispatch(addReferedBy(referby));
      navigate("/tests/testId/report", { state: { fromGeneralPage: true } });
      return;
    }
    dispatch(addTests(testData));
    dispatch(addReferedBy(referby));

    navigate("/tests/testId/report", { state: { fromGeneralPage: true } });
  };

  function removeNumericWords(inputString) {
    return inputString.replace(/[^a-zA-Z/%]+/g, " ").trim();
  }

  const isAllFieldsFilled =
    testData.every((test) =>
      test.fields.every((field) => {
        // Check if the main field value is filled
        const isFieldFilled =
          field.value !== "" &&
          field.value !== undefined &&
          field.value !== null;

        // Check if subfields are present and their values are filled
        const areSubfieldsFilled =
          Array.isArray(field.subFields) &&
          field.subFields.length &&
          field.subFields.every((subField) => {
            return (
              subField.value !== "" &&
              subField.value !== undefined &&
              subField.value !== null
            );
          });

        console.log(isFieldFilled, "Filled fields");
        console.log(areSubfieldsFilled, "Subfields");

        // Return true if either the main field or subfields are filled
        return isFieldFilled || areSubfieldsFilled;
      })
    ) && referby.trim() !== "";

  console.log(isAllFieldsFilled, "Is All Fields Filled?");

  const submitTestResult = async () => {};

  // Corrected logic to include referby check

  const processStatus = (status, fieldId) => {
    if (status?.error === "false") {
      if (status?.code === fieldId) {
        return status?.res;
      }
    } else if (status?.error === "noData") {
      if (status?.code === fieldId) {
        return status?.res;
      }
    } else if (status?.error === "noCode") {
      if (status?.code === fieldId) {
        return status?.res;
      }
    }
    return "";
  };

  const removeImage = (indexToRemove) => {
    setCapturedImage((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );

    const updatedTestData = JSON.parse(JSON.stringify(testData));

    updatedTestData.forEach((test) => {
      test.fields.forEach((field) => {
        if (field.subFields && field.subFields.length > indexToRemove) {
          // Remove the subField at the specified index
          field.subFields.splice(indexToRemove, 1);
        }
      });
    });
    if (capturedImage?.length == 0) {
      setBtn(false);
    }
    // Set the updated data (if needed)
    setTestData(updatedTestData);
    console.log(updatedTestData);
  };

  return (
    <div className="flex flex-col overflow-x-hidden items-center justify-center h-screen w-screen m-0">
      <div className="flex flex-col w-[80%] h-[85%] bg-white rounded-2xl my-5 mx-auto">
        <div className="relative w-full h-[15%] bg-[#845BB3] rounded-t-2xl flex items-center justify-center">
          <div
            onClick={() => navigate("/tests", { replace: true })}
            className="absolute left-10 text-[#845BB3] bg-white px-4 py-2 text-md font-bold font-sans rounded cursor-pointer shadow-sm"
          >
            {translations[langData].GoBack}
          </div>
          <div className="font-poppins text-2xl py-6 font-semibold text-white">
            {translations[langData].latestResult}
          </div>
        </div>
        <div className="w-full h-[50em] max-h-16 flex overflow-hidden">
          <div className="flex-grow-[4] border-r border-b border-[#845BB3] flex justify-center items-center">
            <img src={beaker1} alt="icon" className="w-7 h-7 mr-2" />
            <p className="text-center"> {translations[langData].testName}</p>
          </div>
          <div className="flex-grow border-r border-b border-[#845BB3] flex justify-center items-center">
            <img src={beaker2} alt="icon" className="w-6 h-6 mr-2" />
            <p className="text-center"> {translations[langData].result}</p>
          </div>
          <div className="flex-grow border-b border-[#845BB3] flex justify-center items-center">
            <img src={compass} alt="icon" className="w-6 h-6 mr-2" />
            <p className="text-center">
              {" "}
              {translations[langData].unitAndRange}
            </p>
          </div>
        </div>
        <div className="flex-grow w-full flex flex-col gap-4 p-4 overflow-y-auto">
          {testData.map((test, sectionIndex) => (
            <div key={sectionIndex} className="w-full flex flex-col gap-4">
              <div className="flex items-center mb-4">
                <button className="bg-[#D7D4F8] rounded-md text-center px-4 py-2 font-poppins font-semibold text-[#845BB3] text-xl">
                  {test.testFieldName}
                </button>
              </div>
              <div className="w-full flex flex-col gap-4">
                {test.fields &&
                  Array.isArray(test.fields) &&
                  test.fields.map((field, index) => {
                    // Skip rendering the field if fieldId is "1"
                    if (field.fieldId === "1") return null;

                    return (
                      <div key={index} className="flex items-center">
                        <div className="w-full text-left px-2 py-1 flex flex-col font-poppins text-lg font-medium text-[#845BB3] gap-6">
                          {/* Check if the field has subfields */}
                          {field.subFields &&
                          Array.isArray(field.subFields) &&
                          field.subFields.length > 0 ? (
                            // If subfields exist, display each subfield as if it were a regular field
                            field.subFields.map((subfield, subIndex) => (
                              <div
                                key={subIndex}
                                className="flex items-center mb-2"
                              >
                                {/* Subfield Name */}
                                <span className="w-3/5">
                                  {subfield.fieldName}
                                </span>
                                {/* Subfield Input */}
                                <div className="flex items-center justify-center w-1/5">
                                  {subfield.fieldName === "Cough Analysis" ||
                                  subfield.fieldName === "Cancer-Octoscope" ? (
                                    <button
                                      onClick={() =>
                                        openModal(subfield.fieldName)
                                      } // Open modal for Cancer-Octoscope test
                                      className="w-full max-w-[12rem] py-2 bg-[#845BB3] hover:bg-[#7535bd] text-white font-medium rounded-md ml-2"
                                    >
                                      Proceed for the Test
                                    </button>
                                  ) : subfield.fieldName ===
                                    "Scrub Typhus IgG" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Scrub Typhus IgM" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName === "Typhoid IgG" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Hepatitis C Surface Antigen (HBsAg)" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName === "Typhoid IgM" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Vibrio cholerae O1 Antigen" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Vibrio cholerae O139 Antigen" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Chikungunya IgM" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Chikungunya IgG" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Leptospira IgM" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Leptospira IgG" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName === "HIV 1" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Reactive">Reactive</option>

                                      <option value="Non-Reactive">
                                        Non-Reactive
                                      </option>
                                    </select>
                                  ) : subfield.fieldName === "HIV 2" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Reactive">Reactive</option>

                                      <option value="Non-Reactive">
                                        Non-Reactive
                                      </option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "H. Pylori Antigen" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Hepatitis B Surface Antigen (HBsAg)" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Filarial Antigen Detection" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Plasmodium falciparum HRP-II" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Plasmodium species pLDH" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Plasmodium falciparum HRP-II" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Plasmodium vivax pLDH" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "HIV-1 Antibodies" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Reactive">Reactive</option>
                                      <option value="Non-Reactive">
                                        Non-Reactive
                                      </option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "HIV-2 Antibodies" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Reactive">Reactive</option>

                                      <option value="Non-Reactive">
                                        Non-Reactive
                                      </option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Syphilis Antibodies (TPP)" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Reactive">Reactive</option>

                                      <option value="Non-Reactive">
                                        Non-Reactive
                                      </option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Dengue NS1 Antigen" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Dengue IgM Antibodies" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Dengue IgG Antibodies" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.fieldName ===
                                    "Leishmania Antigen" ? (
                                    <select
                                      // value={selectedScrubTyphus}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          value
                                        ); // Pass the value to the handler
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Positive">Positive</option>
                                      <option value="Negative">Negative</option>
                                    </select>
                                  ) : subfield.parentFieldName === "ENT" ? (
                                    <>
                                      <img
                                        src={subfield?.value}
                                        alt={`Captured ${index + 1}`}
                                        className="w-[200px] h-[150px] object-cover rounded-md"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        type="text"
                                        value={
                                          subfield.value !== ""
                                            ? subfield.value
                                            : subfield.fieldName === "eAG"
                                            ? eAGValue
                                            : subfield.fieldName === "HbA1c"
                                            ? hbA1cValue
                                            : subfield.fieldName === "HDL/LDL"
                                            ? hdlldlvalue
                                            : subfield.fieldName ===
                                              "Ratio BUN: Creatinine"
                                            ? bun.current / creatinine.current
                                            : subfield.fieldName ===
                                              "Ratio Albumin : Globulin"
                                            ? albumin.current / globulin.current
                                            : subfield.fieldName ===
                                              "Ratio SGOT: SGPT"
                                            ? sgot.current / sgpt.current
                                            : subfield.value
                                        }
                                        onChange={(e) => {
                                          if (subfield.fieldName === "HbA1c") {
                                            setHbA1cValue(e.target.value);
                                            calEAG(e.target.value);
                                          }
                                          if (
                                            subfield.fieldName ===
                                            "High Density Lipoprotein (HDL)"
                                          ) {
                                            hdlValue.current = e.target.value;
                                          }
                                          if (
                                            subfield.fieldName ===
                                            "Low Density Lipoprotein (LDL)"
                                          ) {
                                            ldlValue.current = e.target.value;
                                            setHdlldlvalue(
                                              (
                                                hdlValue.current /
                                                ldlValue.current
                                              ).toFixed(2)
                                            );
                                          }
                                          if (
                                            subfield.fieldName ===
                                            "Blood Urea Nitrogen"
                                          ) {
                                            bun.current = e.target.value;
                                          }
                                          if (
                                            subfield.fieldName === "Creatinine"
                                          ) {
                                            creatinine.current = e.target.value;
                                          }
                                          if (
                                            subfield.fieldName === "Albumin"
                                          ) {
                                            albumin.current = e.target.value;
                                          }
                                          if (
                                            subfield.fieldName === "Globulin"
                                          ) {
                                            globulin.current = e.target.value;
                                          }
                                          if (
                                            subfield.fieldName === "SGOT/ AST"
                                          ) {
                                            sgot.current = e.target.value;
                                          }
                                          if (
                                            subfield.fieldName === "SGPT/ ALT"
                                          ) {
                                            sgpt.current = e.target.value;
                                          }
                                          // calEAG();
                                          handleSubTestInputChange(
                                            sectionIndex,
                                            index,
                                            subIndex,
                                            e.target.value
                                          );
                                        }}
                                        className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                      />
                                    </>
                                  )}
                                </div>
                                {/* Subfield Unit and Range */}
                                <div className="w-[12%] text-right px-4 py-1 font-poppins text-sm font-medium text-[#845BB3]">
                                  {removeNumericWords(subfield.unitAndRange)}
                                </div>
                                <div className="w-[10%] text-sm ml-4 text-[#3fce52]">
                                  {processStatus(status, subfield.fieldId) ===
                                  "last data" ? (
                                    <p className="text-[#3fce52]">
                                      {processStatus(status, subfield.fieldId)}
                                    </p>
                                  ) : (
                                    <p className="text-[red]">
                                      {processStatus(status, subfield.fieldId)}
                                    </p>
                                  )}
                                </div>
                                {subfield.parentField ===
                                  "Complete Blood Count" ||
                                subfield.fieldName === "eAG" ||
                                subfield.fieldName === "Ratio SGOT: SGPT" ||
                                subfield.fieldName ===
                                  "Ratio Albumin: Globulin" ||
                                subfield.fieldName === "HDL/LDL" ||
                                subfield.fieldName ===
                                  "Ratio BUN: Creatinine" ? (
                                  <>
                                    <button
                                      disabled
                                      className="w-1/3 max-w-[8rem] py-1 bg-[transparent] text-[transparent] text-sm rounded-md "
                                    >
                                      Fetch Result
                                    </button>
                                  </>
                                ) : test.fields.find(
                                    (field) =>
                                      subfield.parentFieldName === "ENT"
                                  ) ? (
                                  <>
                                    {btn ? (
                                      <button
                                        className="bg-[#845BB3] rounded-md text-white text-sm font-semibold py-2 px-4 flex items-center"
                                        onClick={() => removeImage(subIndex)}
                                      >
                                        <span className="mr-2">Delete</span>
                                      </button>
                                    ) : (
                                      <button
                                        className="bg-[#b791e3] rounded-md text-white text-sm font-semibold py-2 px-4 flex items-center"
                                        onClick={() =>
                                          handlePreviousResults().then((res) =>
                                            setStatus(res)
                                          )
                                        }
                                      >
                                        <span className="mr-2">
                                          No pictures
                                        </span>
                                      </button>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {/* <span>{status}</span> */}
                                    <button
                                      onClick={() =>
                                        handleLatestResult(
                                          subfield.fieldId
                                        ).then((res) => setStatus(res))
                                      }
                                      className="w-1/3 max-w-[8rem] py-1 bg-[#845BB3] text-white text-sm rounded-md hover:bg-[#7535bd]"
                                    >
                                      Fetch Result
                                    </button>
                                  </>
                                )}
                              </div>
                            ))
                          ) : (
                            // Otherwise, display the field directly if no subfields are present
                            <div className="flex items-center mb-2">
                              {/* Field Name */}
                              <span className="w-3/5">{field.fieldName}</span>

                              <div className="flex items-center justify-center w-1/5">
                                {/* Field Input or Button */}
                                {field.fieldName === "Cough Analysis" ||
                                field.fieldName === "Cancer-Octoscope" ||
                                field.fieldName === "ENT" ? (
                                  <button
                                    onClick={() => openModal(field.fieldName)}
                                    className="w-full max-w-[12rem] py-2 bg-[#845BB3] hover:bg-[#7535bd] text-white font-medium rounded-md ml-2"
                                  >
                                    {/* Proceed for the Test  */}
                                    {translations[langData].ProceedfortheTest}
                                  </button>
                                ) : field.fieldName ===
                                  "Stethoscope amplifier" ? (
                                  <button
                                    onClick={() =>
                                      handleStethoscope(field.fieldName)
                                    }
                                    className="w-full max-w-[12rem] py-2 bg-[#845BB3] hover:bg-[#7535bd] text-white font-medium rounded-md ml-2"
                                  >
                                    {/* Proceed for the Test  */}
                                    {translations[langData].ProceedfortheTest}
                                  </button>
                                ) : field.fieldName === "Typhoid" ? (
                                  <select
                                    // value={selectedThypoid}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // setSelectedThypoid(value); // Update state for Typhoid selection
                                      handleInputChange(
                                        sectionIndex,
                                        index,
                                        value
                                      ); // Pass the value to the handler
                                    }}
                                    className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                  >
                                    <option value="">Select Result</option>
                                    <option value="Positive">Positive</option>
                                    <option value="Negative">Negative</option>
                                  </select>
                                ) : field.fieldName === "Cholera" ? (
                                  <select
                                    // value={selectedCholerae}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // setSelectedCholerae(value); // Update state for Typhoid selection
                                      handleInputChange(
                                        sectionIndex,
                                        index,
                                        value
                                      ); // Pass the value to the handler
                                    }}
                                    className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                  >
                                    <option value="">Select Result</option>
                                    <option value="Positive">Positive</option>
                                    <option value="Negative">Negative</option>
                                  </select>
                                ) : field.fieldName === "Chikungunya" ? (
                                  <select
                                    // value={selectedChikungunya}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // setSelectedChikungunya(value); // Update state for Typhoid selection
                                      handleInputChange(
                                        sectionIndex,
                                        index,
                                        value
                                      ); // Pass the value to the handler
                                    }}
                                    className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                  >
                                    <option value="">Select Result</option>
                                    <option value="Positive">Positive</option>
                                    <option value="Negative">Negative</option>
                                  </select>
                                ) : field.fieldName === "Leptospira" ? (
                                  <select
                                    // value={selectedLeptospira}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // setSelectedLeptospira(value); // Update state for Typhoid selection
                                      handleInputChange(
                                        sectionIndex,
                                        index,
                                        value
                                      ); // Pass the value to the handler
                                    }}
                                    className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                  >
                                    <option value="">Select Result</option>
                                    <option value="Positive">Positive</option>
                                    <option value="Negative">Negative</option>
                                  </select>
                                ) : field.fieldName === "HIV" ? (
                                  <select
                                    // value={selectedHIV}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // setSelectedHIV(value); // Update state for Typhoid selection
                                      handleInputChange(
                                        sectionIndex,
                                        index,
                                        value
                                      ); // Pass the value to the handler
                                    }}
                                    className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                  >
                                    <option value="">Select Result</option>
                                    <option value="Reactive">Reactive</option>
                                    <option value="Non-Reactive">
                                      Non-Reactive
                                    </option>
                                  </select>
                                ) : field.fieldName === "H. Pylori" ? (
                                  <select
                                    // value={selectedHPylori}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // setSelectedHPylori(value); // Update state for Typhoid selection
                                      handleInputChange(
                                        sectionIndex,
                                        index,
                                        value
                                      ); // Pass the value to the handler
                                    }}
                                    className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                  >
                                    <option value="">Select Result</option>
                                    <option value="Positive">Positive</option>
                                    <option value="Negative">Negative</option>
                                  </select>
                                ) : field.fieldName === "Hepatitis B Ag" ? (
                                  <select
                                    // value={selectedHepatitisBAg}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // setSelectedHepatitisBAg(value); // Update state for Typhoid selection
                                      handleInputChange(
                                        sectionIndex,
                                        index,
                                        value
                                      ); // Pass the value to the handler
                                    }}
                                    className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                  >
                                    <option value="">Select Result</option>
                                    <option value="Positive">Positive</option>
                                    <option value="Negative">Negative</option>
                                  </select>
                                ) : field.fieldName === "Scrub Typhus" ? (
                                  <select
                                    // value={selectedScrubTyphus}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // setSelectedScrubTyphus(value); // Update state for Typhoid selection
                                      handleInputChange(
                                        sectionIndex,
                                        index,
                                        value
                                      ); // Pass the value to the handler
                                    }}
                                    className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                  >
                                    <option value="">Select Result</option>
                                    <option value="Positive">Positive</option>
                                    <option value="Negative">Negative</option>
                                  </select>
                                ) : field.fieldName === "Hepatitis C" ? (
                                  <select
                                    // value={selectedHepatitisC}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // setSelectedHepatitisC(value); // Update state for Typhoid selection
                                      handleInputChange(
                                        sectionIndex,
                                        index,
                                        value
                                      ); // Pass the value to the handler
                                    }}
                                    className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                  >
                                    <option value="">Select Result</option>
                                    <option value="Positive">Positive</option>
                                    <option value="Negative">Negative</option>
                                  </select>
                                ) : field.fieldName === "Elephantiasis" ? (
                                  <select
                                    // value={selectedElephantiasis}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // setSelectedElephantiasis(value); // Update state for Typhoid selection
                                      handleInputChange(
                                        sectionIndex,
                                        index,
                                        value
                                      ); // Pass the value to the handler
                                    }}
                                    className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                  >
                                    <option value="">Select Result</option>
                                    <option value="Positive">Positive</option>
                                    <option value="Negative">Negative</option>
                                  </select>
                                ) : field.fieldName === "Malaria pf PAN" ? (
                                  <select
                                    // value={selectedMalariapfPAN}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // setSelectedMalariapfPAN(value); // Update state for Typhoid selection
                                      handleInputChange(
                                        sectionIndex,
                                        index,
                                        value
                                      ); // Pass the value to the handler
                                    }}
                                    className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                  >
                                    <option value="">Select Result</option>
                                    <option value="Positive">Positive</option>
                                    <option value="Negative">Negative</option>
                                  </select>
                                ) : field.fieldName === "Malaria Pf/Pv" ? (
                                  <select
                                    // value={selectedMalariaPfPv}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // setSelectedMalariaPfPv(value); // Update state for Typhoid selection
                                      handleInputChange(
                                        sectionIndex,
                                        index,
                                        value
                                      ); // Pass the value to the handler
                                    }}
                                    className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                  >
                                    <option value="">Select Result</option>
                                    <option value="Reactive">Reactive</option>
                                    <option value="Non-Reactive">
                                      Non-Reactive
                                    </option>
                                  </select>
                                ) : field.fieldName ===
                                  "Syphillis/HIV Combo" ? (
                                  <select
                                    // value={selectedSyphillisHIVCombo}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // setSelectedSyphillisHIVCombo(value); // Update state for Typhoid selection
                                      handleInputChange(
                                        sectionIndex,
                                        index,
                                        value
                                      ); // Pass the value to the handler
                                    }}
                                    className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                  >
                                    <option value="">Select Result</option>
                                    <option value="Positive">Positive</option>
                                    <option value="Negative">Negative</option>
                                  </select>
                                ) : field.fieldName === "Dengue Duo" ? (
                                  <select
                                    // value={selectedDengueDuo}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // setSelectedDengueDuo(value); // Update state for Typhoid selection
                                      handleInputChange(
                                        sectionIndex,
                                        index,
                                        value
                                      ); // Pass the value to the handler
                                    }}
                                    className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                  >
                                    <option value="">Select Result</option>
                                    <option value="Positive">Positive</option>
                                    <option value="Negative">Negative</option>
                                  </select>
                                ) : field.fieldName === "Leishmania" ? (
                                  <select
                                    // value={selectedLeishmania}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // setSelectedLeishmania(value); // Update state for Typhoid selection
                                      handleInputChange(
                                        sectionIndex,
                                        index,
                                        value
                                      ); // Pass the value to the handler
                                    }}
                                    className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                  >
                                    <option value="">Select Result</option>
                                    <option value="Positive">Positive</option>
                                    <option value="Negative">Negative</option>
                                  </select>
                                ) : (
                                  <input
                                    type="text"
                                    value={
                                      field.fieldName === "HDL/LDL"
                                        ? hdlldlvalue
                                        : field.value || ""
                                    }
                                    onChange={(e) => {
                                      if (field.fieldName === "HbA1c") {
                                        setHbA1cValue(e.target.value);
                                      }
                                      if (
                                        field.fieldName ===
                                        "High Density Lipoprotein (HDL)"
                                      ) {
                                        hdlValue.current = e.target.value;
                                      }
                                      if (
                                        field.fieldName ===
                                        "Low Density Lipoprotein (LDL)"
                                      ) {
                                        ldlValue.current = e.target.value;
                                        setHdlldlvalue(
                                          hdlValue.current / ldlValue.current
                                        );
                                      }
                                      handleInputChange(
                                        sectionIndex,
                                        index,
                                        e.target.value
                                      );
                                    }}
                                    className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-1 ml-2"
                                  />
                                )}
                              </div>

                              {/* Field Unit and Range */}
                              <div className="w-[12%] text-right px-4 py-1 items-center font-poppins text-sm font-medium text-[#845BB3]">
                                {removeNumericWords(field.unitAndRange)}
                              </div>
                              <div className="w-[10%] text-sm ml-4">
                                {processStatus(status, field.fieldId) ===
                                "last data" ? (
                                  <p className="text-[#3fce52]">
                                    {processStatus(status, field.fieldId)}
                                  </p>
                                ) : (
                                  <p className="text-[red]">
                                    {processStatus(status, field.fieldId)}
                                  </p>
                                )}
                              </div>
                              {field.fieldName === "Hemoglobin" ? (
                                <>
                                  <button
                                    disabled
                                    className="max-w-[8rem] py-1 bg-[transparent] text-[transparent] text-sm rounded-md "
                                  >
                                    Fetch Result
                                  </button>
                                </>
                              ) : field.fieldName === "ENT" ? (
                                <>
                                  {capturedImage?.length !== 0 ? (
                                    <button
                                      className="bg-[#845BB3] rounded-md text-white text-sm font-semibold py-2 px-4 flex items-center"
                                      onClick={() => removeImage(index)}
                                    >
                                      <span className="mr-2">Delete</span>
                                    </button>
                                  ) : (
                                    <button
                                      className="bg-[#b791e3] rounded-md text-white text-sm font-semibold py-2 px-4 flex items-center"
                                      onClick={() =>
                                        handlePreviousResults().then((res) =>
                                          setStatus(res)
                                        )
                                      }
                                    >
                                      <span className="mr-2">No pictures</span>
                                    </button>
                                  )}
                                </>
                              ) : (
                                <>
                                  <button
                                    onClick={() =>
                                      handleLatestResult(field.fieldId).then(
                                        (res) => {
                                          setStatus(res);
                                        }
                                      )
                                    }
                                    className="w-1/3 max-w-[8rem] py-1 bg-[#845BB3] text-white text-sm rounded-md hover:bg-[#7535bd]"
                                  >
                                    Fetch Result
                                  </button>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
              {test.testFieldName === "Complete Blood Count" ||
              test.testFieldName === "Blood Markers" ? (
                <div className="flex items-center mt-2">
                  <button
                    className="bg-[#845BB3] rounded-md text-white text-sm font-semibold py-2 px-4 flex items-center"
                    onClick={() =>
                      handlePreviousResults().then((res) => setStatus(res))
                    }
                  >
                    <span className="mr-2">Fetch Previous Results</span>
                  </button>
                  {status?.res === "last data" ? (
                    <label className="ml-4 text-[#3fce52]">{status?.res}</label>
                  ) : (
                    <label className="ml-4 text-[red]">{status?.res}</label>
                  )}
                </div>
              ) : (
                <></>
              )}
              {capturedImage?.length !== 0 ? (
                <div className="relative">
                  <button
                    onClick={() => openModal("ENT")}
                    className="bg-[#845BB3] rounded-md text-white text-sm font-semibold py-2 px-4 items-center"
                  >
                    <span className="mr-2">Add Images</span>
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
        <div className="flex w-full justify-between items-center py-6 px-5 border-t-2">
          <div className="flex flex-row justify-start items-center">
            <p className="text-2xl text-[#845BB3]">
              {translations[langData].referrdBY} :
            </p>
            <input
              type="text"
              onChange={handleReferBy}
              value={referby}
              className="w-1/2 rounded-md bg-[#D7D4F8] text-center ml-2 px-3 py-2 text-base outline-1"
            />
          </div>

          <div>
            {console.log("2137", testData[0].fields[0].fieldName)}
            {!(
              testData?.[0]?.fields?.[0]?.fieldName === "Stethoscope amplifier"
            ) && (
              <button
                onClick={handleSave}
                className={`bg-${
                  isAllFieldsFilled ? "[#845BB3]" : "[#D7D4F8]"
                } rounded-md text-white text-base font-semibold py-2 px-2 flex items-center`}
                disabled={!isAllFieldsFilled}
              >
                <span className="mr-2">
                  {translations[langData].generateReport}
                </span>
                <img src={report} alt="icon" className="w-6 h-6 align-middle" />
              </button>
            )}
          </div>
        </div>
      </div>
      {isModalOpen && activeModal === "Cough Analysis" && (
        <CoughTestModal
          isOpen={isModalOpen}
          onClose={closeModal}
          title="Cough Modal"
        />
      )}
      {isModalOpen && activeModal === "Cancer-Octoscope" && (
        <CameraModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onCapture={handleCapture}
        />
      )}
      {isModalOpen && activeModal === "ENT" && (
        <MultipleCaptureModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onCapture={handleMultipleCapture}
          captureLimit={capturedImage.length}
        />
      )}
      {isModalOpen && activeModal === "Stethoscope amplifier" && (
        <StethoscopeModal
          isOpen={isModalOpen}
          onClose={closeModal}
          title="Stethoscope amplifier"
        />
      )}
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="w-10 h-10 border-4 border-t-red-500 border-gray-300 rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
};

export default GeneralTest;
