const baseUrl = "https://api.superceuticals.in";

const API_ENDPOINTS = {
  signUpApi: `${baseUrl}/technicians/login`,
  addPatient: `${baseUrl}/patient/add-patient`,
  getTestResults: `${baseUrl}/patient/get-patientTest/SC23F000005`,
  addTestReport: `${baseUrl}/test/add-testResult`,
  calculateTestPrice :`${baseUrl}/testPrice/get-total-price`,
  payOnline:`${baseUrl}/payment/makePayment`,
  promoCode :`${baseUrl}/promocode/validate`,
  addReport :`${baseUrl}/report/add-report/`,
  bodyCompostionApi: `https://localhost:3003/api/data`,
  getFactor: `${baseUrl}/heightFactor`,
  getTestResultsLocal: `${baseUrl}/test/getTestResult/`,
  getPaymentStatus: `${baseUrl}/payment/verifyPayment`,
  checkHead : `${baseUrl}/patient/checkHead`,
};

export default API_ENDPOINTS;
