// const lipidProfileTests = [
//   { testId: "test-001", testName: "HCG", checked: "false" },
//   { testId: "test-002", testName: "LH", checked: "false" },
//   { testId: "test-003", testName: "T4", checked: "false" },
//   { testId: "test-004", testName: "fT4", checked: "false" },
//   { testId: "test-005", testName: "TSH WB", checked: "false" },
//   { testId: "test-006", testName: "TSH", checked: "false" },
// ];

const formattedTranslations = [
  {
    testFieldName: "Body Analysis",
    fields: [
      {
        fieldId: "",
        fieldName: "General",
        subFields: [
          {
            fieldId: "",
            fieldName: "Blood Pressure",
            unitAndRange: "mm of Hg", 
            parentField: "General",
            value: "",
            bioReferenceInterval: "Systolic - 120m Diastolic - 80mm",
          },
          {
            fieldId: "",
            fieldName: "Temperature",
            parentField: "General",
            unitAndRange: "F",
            value: "",
            bioReferenceInterval: "97 - 99",
          },
          {
            fieldId: "",
            fieldName: "Spo2",
            unitAndRange: "Varies by method",
            parentField: "General",
            value: "",
          },
          {
            fieldId: "",
            fieldName: "Height",
            parentField: "General",
            unitAndRange: "cm",
            value: "",
          },
          {
            fieldId: "",
            fieldName: "Weight",
            parentField: "General",
            unitAndRange: "Kg",
            value: "",
          },
          {
            fieldId: "",
            fieldName: "BMI",
            parentField: "General",
            unitAndRange: "Kg/ m2",
            value: "",
          },
          {
            fieldId: "",
            fieldName: "Body Fat",
            parentField: "General",
            unitAndRange: "%",
            value: "",
          },
          {
            fieldId: "",
            fieldName: "Visceral Fat",
            unitAndRange: "%",
            parentField: "General",
            value: "",
          },
          {
            fieldId: "",
            fieldName: "Skeletal Muscle",
            parentField: "General",
            unitAndRange: "%",
            value: "",
          },
          {
            fieldId: "",
            fieldName: "Body Age",
            parentField: "General",
            unitAndRange: "-",
            value: "",
          },
          {
            fieldId: "",
            fieldName: "Resting Metabolism",
            unitAndRange: "Kcal",
            parentField: "General",
            value: "",
          },
        ],
        unitAndRange: "4.0-5.6%",
      },
    ],
  },

  {
    testFieldName: "AI Analysis",
    fields: [
      {
        fieldName: "Cancer Screening",
        subFields: [
          {
            fieldId: "",
            fieldName: "Cancer-Octoscope",
            parentField: "Canser Screening",
            unitAndRange: "Varies by test",
            value: "true",
          },
          {
            fieldId: "",
            fieldName: "Confidence",
            parentField: "Canser Screening",
            unitAndRange: "Varies by test",
            value: "",
          },
          {
            fieldId: "",
            fieldName: "Prediction",
            parentField: "Canser Screening",
            unitAndRange: "Varies by test",
            value: "",
          },
        ],
      },
      {
        fieldName: "Cough Analysis",
        unitAndRange: "Varies by test",
        bioReferenceInterval: "",
      },
      {
        fieldName: "ENT",
        unitAndRange: "Varies by test",
        bioReferenceInterval: "",
      },
      {
        fieldName: "Stethoscope amplifier",
        unitAndRange: "Varies by test",
        bioReferenceInterval: "",
      },
    ],
  },
  {
    testFieldName: "Lipid Profile",
    fields: [
      {
        fieldId: "",
        fieldName: "Lipid Profile",
        subFields: [
          {
            // fieldId: "test-001",
            fieldName: "Total Cholesterol",
            unitAndRange: "mg/dl",
            parentField: "Lipid Profile",
            bioReferenceInterval: "<200",
          },
          {
            // fieldId: "test-002",
            fieldName: "Triglycerides (TG)",
            parentField: "Lipid Profile",
            unitAndRange: "mg/dl",
            bioReferenceInterval: "<150",
          },
          {
            // fieldId: "test-003",
            fieldName: "High Density Lipoprotein (HDL)",
            parentField: "Lipid Profile",
            unitAndRange: "mg/dl",
            bioReferenceInterval: "<40",
          },
          {
            // fieldId: "test-004",
            fieldName: "Low Density Lipoprotein (LDL)",
            parentField: "Lipid Profile",
            unitAndRange: "mg/ dL",
            bioReferenceInterval: "<100",
          },
          {
            // fieldId: "test-006",
            fieldName: "HDL/LDL",
            parentField: "Lipid Profile",
            unitAndRange: "NA",
            bioReferenceInterval: "",
          },
          {
            // fieldId: "test-005",
            fieldName: "Non HDL Cholesterol",
            parentField: "Lipid Profile",
            unitAndRange: "mg/ dL",
            bioReferenceInterval: "<130",
          },
        ],
        unitAndRange: "ng/mL",
      },
    ],
  },
  {
    testFieldName: "Cardiac",
    fields: [
      {
        fieldId: "49551-5",
        fieldName: "CK – MB (Creatine Kinase – MB)",
        unitAndRange: "ng/mL",
        bioReferenceInterval: "<5.0",
        value: "3",
      },
      {
        fieldId: "42757-5",
        fieldName: "TnI",
        unitAndRange: "ng/mL",
        bioReferenceInterval: "<0.05",
        value: "4",
      },
      {
        fieldId: "71427-9",
        fieldName: "D DIMER",
        unitAndRange: "ng/mL",
        bioReferenceInterval: "<500",
        value: "4",
      },
      {
        fieldId: "SDB-0550",
        fieldName: "NT proBNP",
        unitAndRange: "pg/ml",
        bioReferenceInterval: "<125",
        value: "5",
      },
      {
        fieldId: "30522-7",
        fieldName: "hsCRP",
        unitAndRange: "mg/L",
        bioReferenceInterval: "<1.0",
        value: "6",
      },
      {
        fieldId: "",
        fieldName: "Lipid Profile",
        subFields: [
          {
            // fieldId: "test-001",
            fieldName: "Total Cholesterol",
            unitAndRange: "mg/dl",
            parentField: "Lipid Profile",
            bioReferenceInterval: "<200",
          },
          {
            // fieldId: "test-002",
            fieldName: "Triglycerides (TG)",
            parentField: "Lipid Profile",
            unitAndRange: "mg/dl",
            bioReferenceInterval: "<150",
          },
          {
            // fieldId: "test-003",
            fieldName: "High Density Lipoprotein (HDL)",
            parentField: "Lipid Profile",
            unitAndRange: "mg/dl",
            bioReferenceInterval: "<40",
          },
          {
            // fieldId: "test-004",
            fieldName: "Low Density Lipoprotein (LDL)",
            parentField: "Lipid Profile",
            unitAndRange: "mg/ dL",
            bioReferenceInterval: "<100",
          },
          {
            // fieldId: "test-006",
            fieldName: "HDL/LDL",
            parentField: "Lipid Profile",
            unitAndRange: "NA",
            bioReferenceInterval: "",
          },
          {
            // fieldId: "test-005",
            fieldName: "Non HDL Cholesterol",
            parentField: "Lipid Profile",
            unitAndRange: "mg/ dL",
            bioReferenceInterval: "<130",
          },
        ],
        unitAndRange: "ng/mL",
      },
    ],
  },
  {
    testFieldName: "Hormonal",
    fields: [
      {
        fieldId: "SDB-0190",
        fieldName: "Beta-HCG",
        unitAndRange: "mIU/mL",
        bioReferenceInterval: "<5.0",
      },
      {
        fieldId: "SDB-330",
        fieldName: "LH (Luteinizing Hormone)",
        unitAndRange: "IU/L",
        bioReferenceInterval: "1.50-9.30",
      },
      {
        fieldId: "83120-6",
        fieldName: "Total T4 (Thyroxine)",
        unitAndRange: "µg/dL",
        bioReferenceInterval: "5.01-12.45",
      },
      {
        fieldId: "83120-6",
        fieldName: "FT4 (Free Thyroxine)",
        unitAndRange: "ng/dL",
        bioReferenceInterval: "0.89-1.76",
      },
      {
        fieldId: "SDB-0830",
        fieldName: "Total T3 (Triiodothyronine)",
        unitAndRange: "ng/ dL",
        bioReferenceInterval: "60.0-181.1",
      },
      {
        fieldId: "3016-3",
        fieldName: "TSH",
        unitAndRange: "mIU/mL",
        bioReferenceInterval: "0.45-4.50",
      },
    ],
  },

  {
    testFieldName: "Infection Marker",
    fields: [
      {
        fieldId: "48421-2",
        fieldName: "CRP",
        unitAndRange: "mg/L",
        bioReferenceInterval: "<5.0",
      },
      {
        fieldId: "SDB-0260",
        fieldName: "PCT",
        unitAndRange: "ng/mL",
        bioReferenceInterval: "<0.05",
      },
    ],
  },

  {
    testFieldName: "Diabetic",
    fields: [
      {
        fieldId: "4548-4",
        fieldName: "HbA1c",
        subFields: [
          {
            fieldId: "4548-4",
            fieldName: "HbA1c",
            unitAndRange: "%",
            value: "",
            bioReferenceInterval: "<5.7",
          },
          {
            fieldId: "SDB",
            fieldName: "eAG",

            unitAndRange: "mg/dL ",
            value: "",
            bioReferenceInterval: "",
          },
        ],
        unitAndRange: "4.0-5.6%",
      },

      {
        fieldId: "",
        fieldName: "Glucose",
        unitAndRange: "mg/ dL",
        bioReferenceInterval: "70-100",
      },
    ],
  },

  {
    testFieldName: "Respiratory",
    fields: [
      {
        fieldId: "",
        fieldName: "RSV Ag",
        unitAndRange: "-",
        bioReferenceInterval: "<1.0",
      },
      {
        fieldId: "",
        fieldName: "Legionella Ag",
        unitAndRange: "-",
        bioReferenceInterval: "<1.0",
      },
      {
        fieldId: "",
        fieldName: "S.Pneumonia Ag",
        unitAndRange: "-",
        bioReferenceInterval: "<1.0",
      },
      {
        fieldId: "",
        fieldName: "COVID 19 Ag",
        unitAndRange: "-",
        bioReferenceInterval: "<1.0",
      },
      // { fieldId: "", fieldName: "TSH WB", unitAndRange: "0.4-4.0 µIU/mL" },
      {
        fieldId: "",
        fieldName: "Streptococcus A Ag",
        unitAndRange: "-",
        bioReferenceInterval: "<1.0",
      },
    ],
  },
  {
    testFieldName: "Vector Borne",
    fields: [
      {
        fieldId: "",
        fieldName: "Dengue NS1 AG",
        unitAndRange: "-",
        bioReferenceInterval: "<1.0",
      },
      {
        fieldId: "",
        fieldName: "Dengue IgM/IgG",
        unitAndRange: "-",
        bioReferenceInterval: "<1.0",
      },
      {
        fieldId: "",
        fieldName: "Chikungunya IgM/IgG",
        unitAndRange: "-",
        bioReferenceInterval: "<1.0",
      },
    ],
  },
  {
    testFieldName: "Blood Markers",
    fields: [
      {
        fieldId: "718-7",
        fieldName: "Hemoglobin",
        unitAndRange: "g/dL",
        bioReferenceInterval: "14 - 17",
      },
      {
        fieldId: "",
        fieldName: "Complete Blood Count",
        subFields: [
          {
            fieldId: "6690-2",
            fieldName: "Total Leucocyte Count",
            parentField: "Complete Blood Count",
            unitAndRange: "10^3/µL",
            value: "",
            bioReferenceInterval: { Male: "4.5-10.5", Female: "4.5-10.5" },
          },
          {
            fieldId: "789-8",
            fieldName: "RBC Count",
            unitAndRange: "mill/µL",
            parentField: "Complete Blood Count",
            value: "",
            bioReferenceInterval: { Male: "4.7 - 6.1", Female: "4.2 - 5.4" },
          },
          {
            fieldId: "718-7",
            fieldName: "Hemoglobin",
            secfieldName: "Hemoglobin ",
            parentField: "Complete Blood Count",
            unitAndRange: "g/dL",
            value: "",
            bioReferenceInterval: {
              Male: "13.5 - 17.5",
              Female: "12.0 - 15.5",
            },
          },
          {
            fieldId: "4544-3",
            fieldName: "Hematocrit",
            parentField: "Complete Blood Count",
            unitAndRange: "%",
            value: "",
            bioReferenceInterval: {
              Male: "41.0 - 50.0",
              Female: "36.0 - 48.0",
            },
          },
          {
            fieldId: "787-2",
            fieldName: "Mean Corpuscular Volume",
            parentField: "Complete Blood Count",
            unitAndRange: "fL",
            value: "",
            bioReferenceInterval: {
              Male: "80.0 - 100.0",
              Female: "80.0 - 100.0",
            },
          },
          {
            fieldId: "785-6",
            fieldName: "Mean Corpuscular Hemoglobin",
            parentField: "Complete Blood Count",
            unitAndRange: "pg",
            value: "",
            bioReferenceInterval: {
              Male: "27.5 - 33.2",
              Female: "27.5 - 33.2",
            },
          },
          {
            fieldId: "786-4",
            fieldName: "Mean Corpuscular Hemoglobin Concentration",
            parentField: "Complete Blood Count",
            unitAndRange: "g/dL",
            value: "",
            bioReferenceInterval: { Male: "33.4-35.5", Female: "33.4 - 35.5" },
          },
          {
            fieldId: "788-0",
            fieldName: "RBC Distribution Width - CV",
            parentField: "Complete Blood Count",
            unitAndRange: "%",
            value: "",
            bioReferenceInterval: {
              Male: "11.5 - 15.4",
              Female: "11.5 - 15.4",
            },
          },
          {
            fieldId: "21000-5",
            fieldName: "RBC Distribution Width - SD",
            unitAndRange: "fL",
            parentField: "Complete Blood Count",
            value: "",
            bioReferenceInterval: {
              Male: "35.0 - 56.0",
              Female: "35.0 - 56.0",
            },
          },
          {
            fieldId: "777-3",
            fieldName: "Platelet Count",
            parentField: "Complete Blood Count",
            unitAndRange: "10^3/µL",
            value: "",
            bioReferenceInterval: {
              Male: "150.0 - 450.0",
              Female: "150.0 - 450.0",
            },
          },
          {
            fieldId: "32623-1",
            fieldName: "Mean Platelet Volume",
            parentField: "Complete Blood Count",
            unitAndRange: "fL",
            value: "",
            bioReferenceInterval: { Male: "8.6 - 15.5", Female: "8.6 - 15.5" },
          },
          {
            fieldId: "11003",
            fieldName: "Plateletcrit",
            parentField: "Complete Blood Count",
            unitAndRange: "%",
            value: "",
            bioReferenceInterval: {
              Male: "0.20 - 0.36",
              Female: "0.20 - 0.36",
            },
          },
          {
            fieldId: "11090",
            fieldName: "Platelet Distribution Width - CV",
            parentField: "Complete Blood Count",
            unitAndRange: "%",
            value: "",
            bioReferenceInterval: { Male: "8.1 - 20.0", Female: "8.1 - 20.0" },
          },
          {
            fieldId: "32207-3",
            fieldName: "Platelet Distribution Width - SD",
            parentField: "Complete Blood Count",
            unitAndRange: "fL",
            value: "",
            bioReferenceInterval: { Male: "9.0 - 17.0", Female: "9.0 - 17.0" },
          },
          {
            fieldId: "48386-7",
            fieldName: "Platelet Large Cell Ratio",
            parentField: "Complete Blood Count",
            unitAndRange: "%",
            value: "",
            bioReferenceInterval: {
              Male: "11.0 - 45.0",
              Female: "11.0 - 45.0",
            },
          },
          {
            fieldId: "34167-7",
            fieldName: "Platelet Large Cell Count",
            parentField: "Complete Blood Count",
            unitAndRange: "10^3/µL",
            value: "",
            bioReferenceInterval: {
              Male: "30.0 - 90.0",
              Female: "30.0 - 90.0",
            },
          },
          {
            fieldId: "736-9",
            fieldName: "Lymphocytes %",
            parentField: "Complete Blood Count",
            unitAndRange: "%",
            value: "",
            bioReferenceInterval: {
              Male: "18.0 - 45.0",
              Female: "18.0 - 45.0",
            },
          },
          {
            fieldId: "731-0",
            fieldName: "Lymphocyte #",
            unitAndRange: "10^3/µL",
            parentField: "Complete Blood Count",
            value: "",
            bioReferenceInterval: { Male: "0.8 - 5.0", Female: "0.8 - 5.0" },
          },
          {
            fieldId: "32155-4",
            fieldName: "Mid Cell %",
            unitAndRange: "%",
            parentField: "Complete Blood Count",
            value: "",
            bioReferenceInterval: { Male: "2.0 - 8.0", Female: "2.0 - 8.0" },
          },
          {
            fieldId: "32154-7",
            fieldName: "Mid Cell #",
            unitAndRange: "10^3/µL",
            parentField: "Complete Blood Count",
            value: "",
            bioReferenceInterval: { Male: "0.1 - 0.8", Female: "0.1 - 0.8" },
          },
          {
            fieldId: "20482-6",
            fieldName: "Granulocytes %",
            unitAndRange: "%",
            parentField: "Complete Blood Count",
            value: "",
            bioReferenceInterval: {
              Male: "51.4 - 74.0",
              Female: "51.4 - 74.0",
            },
          },
          {
            fieldId: "19023-1",
            fieldName: "Granulocytes #",
            unitAndRange: "10^3/ µL",
            parentField: "Complete Blood Count",
            value: "",
            bioReferenceInterval: { Male: "2.3 - 8.8", Female: "2.3 - 8.0" },
          },
        ],
      },
    ],
  },

  // {
  //   testFieldName: "Rapid",
  //   fields: [
  //     { fieldId: "", fieldName: "Typhoid", unitAndRange: "Negative" },
  //     { fieldId: "", fieldName: "Cholera", unitAndRange: "Negative" },
  //     { fieldId: "", fieldName: "HIV 1&2", unitAndRange: "Negative" },
  //     { fieldId: "", fieldName: "HBsAG", unitAndRange: "Negative" },
  //     { fieldId: "", fieldName: "HCV", unitAndRange: "Negative" },
  //     { fieldId: "", fieldName: "Malaria", unitAndRange: "Negative" },
  //     { fieldId: "", fieldName: "Dengue", unitAndRange: "Negative" },
  //     { fieldId: "", fieldName: "Chikungunya", unitAndRange: "Negative" },
  //     { fieldId: "", fieldName: "Leptospira", unitAndRange: "Negative" },
  //     { fieldId: "", fieldName: "Elephantiasis", unitAndRange: "Negative" },
  //     { fieldId: "", fieldName: "Leishmania", unitAndRange: "Negative" },
  //     { fieldId: "", fieldName: "Urine Dip Test", unitAndRange: "Negative" },
  //   ],
  // },

  {
    testFieldName: "Glucose",
    fields: [
      {
        fieldId: "",
        fieldName: "Glucose",
        unitAndRange: "mg/ dL",
        bioReferenceInterval: "70-140",
      },
    ],
  },
  {
    testFieldName: "Urinalysis",
    fields: [
      {
        fieldId: "",
        fieldName: "Urine Test",
        unitAndRange: "Varies by parameter",
      },
    ],
  },

  {
    testFieldName: "Liver Markers",
    fields: [
      {
        fieldId: "",
        fieldName: "SGOT/ AST",
        parentField: "Liver Markers",
        unitAndRange: "U/L",
        bioReferenceInterval: "10 - 40",
      },
      {
        fieldId: "",
        fieldName: "SGPT/ ALT",
        parentField: "Liver Markers",
        unitAndRange: "U/L",
        bioReferenceInterval: "10 - 49",
      },
      {
        fieldId: "",
        fieldName: "GGTP",
        parentField: "Liver Markers",
        unitAndRange: "U/L",
        bioReferenceInterval: "0 - 73",
      },
      {
        fieldId: "",
        fieldName: "ALP",
        parentField: "Liver Markers",
        unitAndRange: "U/L",
        bioReferenceInterval: "30 - 120",
      },
      {
        fieldId: "",
        fieldName: "Bilirubin Total",
        parentField: "Liver Markers",
        unitAndRange: "mg/dL",
        bioReferenceInterval: "0.3 - 1.2",
      },
      {
        fieldId: "",
        fieldName: "Bilirubin Direct",
        parentField: "Liver Markers",
        unitAndRange: "mg/dL",
        bioReferenceInterval: "0.0 – 0.3",
      },
      {
        fieldId: "",
        fieldName: "Total Protein",
        parentField: "Liver Markers",
        unitAndRange: "g/dL",
        bioReferenceInterval: "5.7 - 8.3",
      },
      {
        fieldId: "",
        fieldName: "Albumin",
        secfieldName: "Albumin ",
        unitAndRange: "g/dL",
        bioReferenceInterval: "3.2-5.0",
      },
      {
        fieldId: "",
        fieldName: "Amylase",
        unitAndRange: "U/L",
        bioReferenceInterval: "30-118",
      },
      {
        fieldId: "",
        fieldName: "Lipase",
        unitAndRange: "U/L",
        bioReferenceInterval: "<160.0",
      },
      {
        fieldId: "",
        fieldName: "Complete Liver Function Tests",
        parentField: "Liver Markers",
        unitAndRange: "",
        subFields: [
          {
            fieldId: "",
            fieldName: "SGOT/ AST",
            secfieldName: "SGOT/ AST ",
            parentField: "Complete Liver Function Tests",
            unitAndRange: "U/L",
            bioReferenceInterval: "10 – 40",
          },
          {
            fieldId: "",
            fieldName: "SGPT/ ALT",
            secfieldName: "SGPT/ ALT ",
            parentField: "Complete Liver Function Tests",
            unitAndRange: "U/L",
            bioReferenceInterval: "10 – 49",
          },
          {
            fieldId: "",
            fieldName: "Ratio SGOT: SGPT",
            parentField: "Complete Liver Function Tests",
            unitAndRange: "Ratio",
            value: "",
            bioReferenceInterval: "<1.0",
          },
          {
            fieldId: "",
            fieldName: "GGTP",
            secfieldName: "GGTP ",
            parentField: "Complete Liver Function Tests",
            unitAndRange: "U/L",
            bioReferenceInterval: "0-73",
          },
          {
            fieldId: "",
            fieldName: "ALP",
            secfieldName: "ALP ",
            parentField: "Complete Liver Function Tests",
            unitAndRange: "U/L",
            bioReferenceInterval: "30 - 120",
          },
          {
            fieldId: "",
            fieldName: "Bilirubin Total",
            secfieldName: "Bilirubin Total ",
            parentField: "Complete Liver Function Tests",
            unitAndRange: "mg/dL",
            bioReferenceInterval: "0.3 - 1.2",
          },
          {
            fieldId: "",
            fieldName: "Bilirubin Direct",
            secfieldName: "Bilirubin Direct ",
            parentField: "Complete Liver Function Tests",
            unitAndRange: "mg/dL",
            bioReferenceInterval: "<0.3",
          },
          {
            fieldId: "",
            fieldName: "Total Protein",
            secfieldName: "Total Protein ",
            parentField: "Complete Liver Function Tests",
            unitAndRange: "g/dL",
            bioReferenceInterval: "5.7 - 8.3",
          },
          {
            fieldId: "",
            fieldName: "Bilirubin Indirect",
            parentField: "Complete Liver Function Tests",
            unitAndRange: "mg/dL",
            value: "",
            bioReferenceInterval: "<1.10",
          },
          {
            fieldId: "",
            fieldName: "Albumin",
            secfieldName: "Albumin ",
            parentField: "Complete Liver Function Tests",
            unitAndRange: "g/dL",
            bioReferenceInterval: "3.2-5.0",
          },
          {
            fieldId: "",
            fieldName: "Globulin",
            parentField: "Complete Liver Function Tests",
            unitAndRange: "g/dL",
            value: "",
            bioReferenceInterval: "2.0 - 3.5",
          },
          {
            fieldId: "",
            fieldName: "Ratio Albumin: Globulin",
            parentField: "Complete Liver Function Tests",
            unitAndRange: "Ratio",
            value: "",
            bioReferenceInterval: "0.9 - 2.2",
          },
        ],
      },
    ],
  },
  {
    testFieldName: "Kidney Markers",
    fields: [
      {
        fieldId: "",
        fieldName: "Creatinine",
        unitAndRange: "mg/dL",
        bioReferenceInterval: "0.5 – 1.2",
        subFields: [
          {
            fieldName: "Creatinine",
            unitAndRange: "mg/dL",
            parentField: "Creatinine",
            value: "",
            fieldId: "",
            bioReferenceInterval: "0.5 - 1.2",
          },
          {
            fieldName: "Estimated GFR",
            unitAndRange: "mL/min/1.73m2",
            parentField: "Creatinine",
            value: "",
            fieldId: "",
            bioReferenceInterval: "<90",
          },
          {
            fieldName: "GFR Category",
            unitAndRange: "-",
            fieldId: "",
            parentField: "Creatinine",
            // unitAndRange: "-",
            value: "",
          },
        ],
      },
      {
        fieldId: "",
        fieldName: "Urea",
        unitAndRange: "mg/dL",
        bioReferenceInterval: "15 – 45",
        subFields: [
          {
            fieldId: "",
            fieldName: "Urea",
            unitAndRange: "mg/dL",
            value: "",
            bioReferenceInterval: "15 – 45",
            parentField: "Urea",
          },
          {
            fieldName: "Blood Urea Nitrogen",
            unitAndRange: "mg/dL",
            parentField: "Urea",
            value: "",
            fieldId: "",
            bioReferenceInterval: "7-20",
          },
        ],
      },
      {
        fieldId: "",
        fieldName: "Uric Acid",
        unitAndRange: "mg/dL",
        bioReferenceInterval: "3.5 - 7.2",
      },
      {
        fieldId: "",
        fieldName: "Sodium",
        unitAndRange: "mEq/L",
        bioReferenceInterval: "135 - 145",
      },
      {
        fieldId: "",
        fieldName: "Potassium",
        unitAndRange: "mEq/L",
        bioReferenceInterval: "3.5 - 5.1",
      },
      {
        fieldId: "",
        fieldName: "Total Protein",
        unitAndRange: "g/dL",
        bioReferenceInterval: "5.7 - 8.3",
      },
      {
        fieldId: "",
        fieldName: "Albumin",
        secfieldName: "Albumin ",
        unitAndRange: "g/dL",
        bioReferenceInterval: "3.5-5.0",
      },

      {
        fieldId: "",
        fieldName: "Urine Creatinine + Microalbumin",
        unitAndRange: "",
        subFields: [
          {
            parentField: "Urine Creatinine + Microalbumin",
            fieldName: "Urine Creatinine",
            unitAndRange: "mg/dL",
            value: "",
            fieldId: "",
            bioReferenceInterval: "20 – 300",
          },
          {
            fieldName: "Urine Microalbumin",
            unitAndRange: "mg/L",
            parentField: "Urine Creatinine + Microalbumin",
            value: "",
            fieldId: "14957-5",
            bioReferenceInterval: "<30 (Normal)",
          },
        ],
      },
      {
        fieldName: "Urine Albumin",
        unitAndRange: "mg/L",
        fieldId: "",
        bioReferenceInterval: "<30 (Normal)",
      },
      // {
      //   fieldName: "Urine Albumine",
      //   unitAndRange: "g/ dL",
      //   fieldId: "",
      //   bioReferenceInterval: "3.2 - 5.0",
      // },
      {
        fieldName: "Routine Panel",
        unitAndRange: "",

        subFields: [
          {
            fieldName: "Urine Glucose",
            unitAndRange: "md/dL",
            value: "",
            fieldId: "",
            parentField: "Routine Panel",
            bioReferenceInterval: "Negative",
          },
          {
            fieldName: "Urine Blood",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Routine Panel",
            bioReferenceInterval: "Negative",
          },
          {
            fieldName: "Urine Protein",
            unitAndRange: "mg/dL",
            fieldId: "",

            parentField: "Routine Panel",
            bioReferenceInterval: "Negative",
          },
          {
            fieldName: "Urine Ketones",
            unitAndRange: "mg/dL",

            value: "",
            parentField: "Routine Panel",
            fieldId: "",
            bioReferenceInterval: "Negative",
          },
          {
            fieldName: "Urine Urobilinogen",
            unitAndRange: "EU/dL",

            parentField: "Routine Panel",

            value: "",
            fieldId: "",
            bioReferenceInterval: "Negative",
          },
          {
            fieldId: "",
            fieldName: "Urine Nitrite",
            unitAndRange: "-",
            parentField: "Routine Panel",
            value: "",
            bioReferenceInterval: "Negative",
          },
          {
            fieldName: "Urine Leukocyte",
            unitAndRange: "",
            parentField: "Routine Panel",
            value: "",
            fieldId: "",
            bioReferenceInterval: "Negative",
          },
          {
            fieldName: "Urine Specific Gravity",
            unitAndRange: "",
            parentField: "Routine Panel",
            value: "",
            fieldId: "",
            bioReferenceInterval: "1.005-1.030",
          },
          {
            fieldName: "Urine pH",
            unitAndRange: "",
            parentField: "Routine Panel",
            value: "",
            fieldId: "",
            bioReferenceInterval: "4.5-8.0",
          },
          {
            fieldName: "Urine Bilirubin",
            unitAndRange: "-",
            parentField: "Routine Panel",
            value: "",
            fieldId: "",
            bioReferenceInterval: "Negative",
          },
        ],
      },
      {
        fieldName: "Complete Renal Profile",
        testFieldName: "Complete Renal Profile",
        unitAndRange: "",
        fieldId: "",
        subFields: [
          {
            fieldId: "",
            fieldName: "Urea",
            secfieldName: "Urea ",
            bioReferenceInterval: "15-45",
            parentField: "Complete Renal Profile",
            unitAndRange: "mg/dL",
          },
          {
            fieldId: "",
            fieldName: "Uric Acid",
            secfieldName: "Uric Acid ",
            parentField: "Complete Renal Profile",
            unitAndRange: "mg/dL",
            bioReferenceInterval: "3.5 - 7.2",
          },
          {
            fieldId: "",
            fieldName: "Sodium",
            secfieldName: "Sodium ",
            parentField: "Complete Renal Profile",
            unitAndRange: "mEq/L",
            bioReferenceInterval: "135 – 145",
          },
          {
            fieldId: "",
            fieldName: "Potassium",
            secfieldName: "Potassium ",
            parentField: "Complete Renal Profile",
            unitAndRange: "mEq/L",
            bioReferenceInterval: "3.5 - 5.1",
          },
          {
            fieldId: "",
            fieldName: "Total Protein",
            secfieldName: "Total Protein ",
            parentField: "Complete Renal Profile",
            unitAndRange: "g/dL",
            bioReferenceInterval: "6.0 – 8.3",
          },
          {
            fieldName: "Blood Urea Nitrogen",
            unitAndRange: "mg/dL",
            parentField: "Complete Renal Profile",
            value: "",
            fieldId: "",
            bioReferenceInterval: "7-20",
          },
          {
            fieldName: "Creatinine",
            secfieldName: "Creatinine ",
            unitAndRange: "mg/dL",
            parentField: "Complete Renal Profile",
            value: "",
            fieldId: "",
            bioReferenceInterval: "0.5 - 1.2",
          },
          {
            fieldName: "Ratio BUN: Creatinine",
            unitAndRange: "Ratio",
            parentField: "Complete Renal Profile",
            value: "",
            fieldId: "",
            bioReferenceInterval: "10:1 - 20:1",
          },
          {
            fieldId: "",
            fieldName: "Albumin",
            secfieldName: "Albumin ",
            parentField: "Complete Renal Profile",
            unitAndRange: "g/dL",
            bioReferenceInterval: "3.2-5.0",
          },
          {
            fieldName: "Globulin",
            unitAndRange: "g/dL",
            parentField: "Complete Renal Profile",
            value: "",
            fieldId: "",
            bioReferenceInterval: "2.5 - 3.5",
          },
          {
            fieldName: "Ratio Albumin: Globulin",
            unitAndRange: "Ratio",
            parentField: "Complete Renal Profile",
            value: "",
            fieldId: "",
            bioReferenceInterval: "1.1 - 2.2",
          },
          {
            fieldName: "Estimated GFR",
            unitAndRange: "mL/min/1.73m²",
            parentField: "Complete Renal Profile",
            value: "",
            fieldId: "",
            bioReferenceInterval: ">60 (Normal for adults)",
          },
          {
            fieldName: "GFR Category",
            unitAndRange: "-",
            fieldId: "",
            parentField: "Complete Renal Profile",
            // unitAndRange: "",
            value: "",
            bioReferenceInterval: "G1 – G5 (based on eGFR)",
          },
        ],
      },
    ],
  },
  {
    testFieldName: "Hemoglobin",
    fields: [
      {
        fieldId: "",
        fieldName: "Hemoglobin",
        unitAndRange: "12.0 - 17.5 g/dL",
        value: "",
        bioReferenceInterval: "14-17",
      },
    ],
  },
  {
    testFieldName: "Others",
    fields: [
      // { fieldId: "", fieldName: "iFOB", unitAndRange: "Negative" },
      // { fieldId: "", fieldName: "Electro Cardiogram", unitAndRange: "Normal" },
      // { fieldId: "", fieldName: "Colon Cancer - iFOB", unitAndRange: "Normal" },
      {
        fieldId: "",
        fieldName: "Typhoid IgM/ IgG",
        unitAndRange: "-",
        bioReferenceInterval: "Negative",
        subFields: [
          {
            fieldName: "Typhoid IgM",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Typhoid",
            bioReferenceInterval: "Negative",
          },
          {
            fieldName: "Typhoid IgG",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Typhoid",
            bioReferenceInterval: "Negative",
          },
        ],
      },
      {
        fieldId: "",
        fieldName: "Cholera Ag",
        unitAndRange: "-",
        bioReferenceInterval: "Negative",
        subFields: [
          {
            fieldName: "Vibrio cholerae O1 Antigen",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Cholera",
            bioReferenceInterval: "Negative",
          },
          {
            fieldName: "Vibrio cholerae O139 Antigen",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Cholera",
            bioReferenceInterval: "Negative",
          },
        ],
      },
      {
        fieldId: "",
        fieldName: "Chikungunya IgM/ IgG",
        unitAndRange: "-",
        bioReferenceInterval: "Negative",
        subFields: [
          {
            fieldName: "Chikungunya IgM",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Chikungunya",
            bioReferenceInterval: "Negative",
          },
          {
            fieldName: "Chikungunya IgG",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Chikungunya",
            bioReferenceInterval: "Negative",
          },
        ],
      },
      {
        fieldId: "",
        fieldName: "Leptospira IgM/ IgG",
        unitAndRange: "-",
        bioReferenceInterval: "Negative",
        subFields: [
          {
            fieldName: "Leptospira IgM",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Leptospira",
            bioReferenceInterval: "Negative",
          },
          {
            fieldName: "Leptospira IgG",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Leptospira",
            bioReferenceInterval: "Negative",
          },
        ],
      },
      {
        fieldId: "",
        fieldName: "HIV Antibody",
        unitAndRange: "-",
        bioReferenceInterval: "Non-Reactive",
        subFields: [
          {
            fieldName: "HIV 1",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "HIV",
            bioReferenceInterval: "Non-Reactive",
          },
          {
            fieldName: "HIV 2",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "HIV",
            bioReferenceInterval: "Non-Reactive",
          },
        ],
      },
      {
        fieldId: "",
        fieldName: "H. Pylori FIA",
        unitAndRange: "-",
        bioReferenceInterval: "Negative",
        subFields: [
          {
            fieldName: "H. Pylori Antigen",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "H. Pylori",
            bioReferenceInterval: "Negative",
          },
        ],
      },
      {
        fieldId: "",
        fieldName: "Hepatitis B Ag",
        unitAndRange: "-",
        bioReferenceInterval: "Negative",
        subFields: [
          {
            fieldName: "Hepatitis B Surface Antigen (HBsAg)",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Hepatitis B Ag",
            bioReferenceInterval: "Negative",
          },
        ],
      },
      {
        fieldId: "",
        fieldName: "Scrub Typhus IgM/ IgG",
        unitAndRange: "-",
        bioReferenceInterval: "Negative",
        subFields: [
          {
            fieldName: "Scrub Typhus IgM",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Scrub Typhus",
            bioReferenceInterval: "Negative",
          },
          {
            fieldName: "Scrub Typhus IgG",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Scrub Typhus",
            bioReferenceInterval: "Negative",
          },
        ],
      },
      {
        fieldId: "",
        fieldName: "Hepatitis C Ag",
        unitAndRange: "-",
        bioReferenceInterval: "Negative",
        subFields: [
          {
            fieldName: "Hepatitis C Surface Antigen (HBsAg)",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Hepatitis C",
            bioReferenceInterval: "Negative",
          },
        ],
      },
      {
        fieldId: "",
        fieldName: "Elephantiasis Ag",
        unitAndRange: "-",
        bioReferenceInterval: "Negative",
        subFields: [
          {
            fieldName: "Filarial Antigen Detection",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Elephantiasis",
            bioReferenceInterval: "Negative",
          },
        ],
      },
      {
        fieldId: "",
        fieldName: "Maleria pf PAN Ag",
        unitAndRange: "-",
        bioReferenceInterval: "Negative",
        subFields: [
          {
            fieldName: "Plasmodium falciparum HRP-II",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Malaria pf PAN",
            bioReferenceInterval: "Negative",
          },
          {
            fieldName: "Plasmodium species pLDH",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Malaria pf PAN",
            bioReferenceInterval: "Negative",
          },
        ],
      },
      {
        fieldId: "",
        fieldName: "Maleria Pf/Pv Ag",
        unitAndRange: "-",
        bioReferenceInterval: "Non-Reactive",
        subFields: [
          {
            fieldName: "Plasmodium falciparum HRP-II",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Malaria Pf/Pv",
            bioReferenceInterval: "Negative",
          },
          {
            fieldName: "Plasmodium vivax pLDH",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Malaria Pf/Pv",
            bioReferenceInterval: "Negative",
          },
        ],
      },
      {
        fieldId: "",
        fieldName: "Syphillis Antibody",
        unitAndRange: "-",
        bioReferenceInterval: "Negative",
        subFields: [
          {
            fieldName: "HIV-1 Antibodies",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Syphillis/HIV Combo",
            bioReferenceInterval: "Non-Reactive",
          },
          {
            fieldName: "HIV-2 Antibodies",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Syphillis/HIV Combo",
            bioReferenceInterval: "Non-Reactive",
          },
          {
            fieldName: "Syphilis Antibodies (TPP)",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Syphillis/HIV Combo",
            bioReferenceInterval: "Non-ReactiveNon-Reactive",
          },
        ],
      },
      {
        fieldId: "",
        fieldName: "Dengue Duo - Ag + Ab",
        unitAndRange: "-",
        bioReferenceInterval: "Negative",
        subFields: [
          {
            fieldName: "Dengue NS1 Antigen",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Dengue Duo",
            bioReferenceInterval: "Negative",
          },
          {
            fieldName: "Dengue IgM Antibodies",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Dengue Duo",
            bioReferenceInterval: "Negative",
          },
          {
            fieldName: "Dengue IgG Antibodies",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Dengue Duo",
            bioReferenceInterval: "Negative",
          },
        ],
      },
      {
        fieldId: "",
        fieldName: "Leishmania Ag",
        unitAndRange: "-",
        bioReferenceInterval: "Negative",
        subFields: [
          {
            fieldName: "Leishmania Antigen",
            unitAndRange: "-",
            value: "",
            fieldId: "",
            parentField: "Leishmania",
            bioReferenceInterval: "Negative",
          },
        ],
      },
    ],
  },
];

export default formattedTranslations;
