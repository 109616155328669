import React from "react";
// import DownloadApp from '../../components/AppDownload/DownloadApp';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Nav from "../Components/Navbar";
import Footer from "../Components/Footer";

function TermsandCondition() {
  return (
    <div>
      <Nav />
      <div className="bg-[#C7CEE5] px-4 sm:px-8 md:px-10 lg:px-30 bg-wave-background py-[4rem]">
        <div>
          <h3 className="font-extrabold px-[1rem] py-[2rem] text-[40px] text-purple-500">
            Terms and Condition
          </h3>
        </div>
        <div className="bg-white shadow-mg p-4">
          <AccordionDetails>
            <Typography>
              <p className="text-left py-1">
                These terms and conditions of use (“Terms of Use“) of the
                Website (as defined below) between Superceuticals Private
                Limited, (hereinafter referred to as “Superceuticals”) and the
                users/registrants of the Website (“You” or “Your” or “Yourself”
                or “User“) describe the terms on which Superceuticals offers You
                access to the Website and the Services (as defined below)
                through the Website.
              </p>

              <p className="text-left py-1">
                <strong>
                  PLEASE READ THE TERMS OF USE CAREFULLY BEFORE USING OR
                  REGISTERING ON THE WEBSITE OR ACCESSING ANY MATERIAL,
                  INFORMATION OR SERVICES THROUGH THE WEBSITE. YOUR USE OF THE
                  WEBSITE OR THE SERVICES PROVIDED BY THE WEBSITE SHALL SIGNIFY
                  YOUR ACCEPTANCE OF THE TERMS OF USE AND YOUR AGREEMENT TO BE
                  LEGALLY BOUND BY THE SAME. YOUR USE OF THE WEBSITE IMPLIES
                  THAT YOU AGREE WITH THE TERMS OF USE.
                </strong>
              </p>

              <h1 className="font-bold text-xl py-2">1. General</h1>
              <p className="text-left py-1">
                1.1{" "}
                <a href="http://www.superceuticals.in">www.superceuticals.in</a>{" "}
                and its extensions, i.e,
                <a href="http://app.superceuticals.in">
                  app.superceuticals.in
                </a>{" "}
                (“Website“) is an Internet based portal owned and operated by
                Superceuticals, a company incorporated under the laws of India,
                with its registered office at No. 19, Info City 2, Near Hero
                Honda Chowk, Sector 33, Gurgaon - 122001, India and having CIN
                U24290HR2020PTC090180. Use of the Website is offered to You
                conditioned on acceptance of all the terms, conditions and
                notices contained in these Terms, along with any amendments made
                by Superceuticals at its sole discretion and posted on the
                Website, including by way of imposing an additional charge for
                access to or use of a Service.
              </p>

              <p className="text-left py-1">
                1.2 Superceuticals shall not be required to notify You, whether
                as a registered user or not, of any changes made to the Terms of
                Use. The revised Terms of Use shall be made available on the
                Website. Your use of the Website and the Services is subject to
                the most current version of the Terms of Use made available on
                the Website at the time of such use. You are requested to
                regularly visit the home page{" "}
                <a href="http://www.superceuticals.in">www.superceuticals.in</a>{" "}
                to view the most current Terms of Use. You can determine when
                Superceuticals last modified the Terms of Use by referring to
                the “Last Updated” legend above. It shall be Your responsibility
                to check these Terms of Use periodically for changes.
                Superceuticals may require You to provide Your consent to the
                updated Terms of Use in a specified manner before prior to any
                further use of the Website and the Services is provided on the
                Website. If no such separate consent is sought, Your continued
                use of the Website, following changes to the Terms of Use, will
                constitute Your acceptance of those changes.
              </p>

              <p className="text-left py-1">
                1.3 By (i) using this Website or any facility or Service
                provided by this Website in any way; or (ii) merely browsing the
                Website, You agree that You have read, understood and agreed to
                be bound by these Terms of Use and the Website's Privacy Policy
                available at the homepage,{" "}
                <a href="http://www.superceuticals.on">www.superceuticals.on</a>
                .
              </p>

              <p className="text-left py-1">
                1.4 Superceuticals agrees to deliver the merchandise ordered by
                you only at such locations as per the Order Confirmation Form.
                Superceuticals shall not be liable to deliver any merchandize or
                Services purchased by Users for delivery in locations outside
                India shall not be entertained unless clearly stated in writing.
              </p>

              <h1 className="font-bold text-xl py-2">2. Services</h1>
              <p className="text-left py-1">
                2.1 The Website is a platform that facilitates the online sale
                and purchase of branded merchandise and services offered by
                Superceuticals's various affiliate/registered
                merchants/vendors/service providers at a discounted price
                (“Services“). The Services are offered to the Users through
                various modes which shall include, issue of coupons and vouchers
                that can be redeemed for various services. The purchase of
                products and services on the Website shall be governed by the
                Terms of Offer For Sale (“Terms of Offer For Sale“)
              </p>
              <h1 className="font-bold text-xl py-2">3. Eligibility to Use</h1>
              <p className="text-left py-1">
                3.1 The Services are not available to minors under the age of
                eighteen (18) or to any Users suspended or removed from the
                Superceuticals system by Superceuticals for any reason
                whatsoever. If You do not conform to the above qualification,
                You shall not be permitted to avail of the Services or use the
                Website. You represent that You are of legal age to form a
                binding contract and are not a person barred from receiving
                services under the laws as applicable in India. Notwithstanding
                the foregoing, if You are below the age of eighteen (18) years,
                You may avail the Services provided by Superceuticals through
                Your legal guardian in accordance with the applicable laws.
              </p>
              <p className="text-left py-1">
                3.2 Superceuticals reserves the right to refuse access to use
                the Services offered at the Website to new Users or to terminate
                access granted to existing Users at any time without according
                any reasons for doing so.
              </p>
              <p className="text-left py-1">
                3.3 You shall not have more than one active Account on the
                Website. Additionally, You are prohibited from selling, trading,
                or otherwise transferring Your Account to another party.
              </p>
              <h1 className="font-bold text-xl py-2">
                4. User Account, Password, and Security
              </h1>
              <p className="text-left py-1">
                4.1 Superceuticals makes the Services available to You through
                the Website only if You have provided Superceuticals certain
                required User information and created an account (“Account“)
                through Superceuticals ID and password or other log-in ID and
                password, which can include a facebook, gmail, yahoo ID or any
                other valid email ID(collectively, the “Account Information“).
                The Services may also be subject to procedures for use of the
                Website, Terms of Offer For Sale, uploaded guidelines, rules,
                additional terms of service, or other disclaimer & notices, if
                any (“Additional Terms“). If there is any conflict between the
                Terms of Use and the Additional Terms, the Additional Terms
                shall take precedence in relation to that service.
              </p>
              <p className="text-left py-1">
                4.2 The Website requires You to register as a User by creating
                an Account in order to avail of the Services provided by the
                Website. You will be responsible for maintaining the
                confidentiality of the Account Information, and are fully
                responsible for all activities that occur under Your Account.
                You agree to (a) immediately notify Superceuticals of any
                unauthorized use of Your Account Information or any other breach
                of security, and (b) ensure that You exit from Your Account at
                the end of each session. Superceuticals cannot and will not be
                liable for any loss or damage arising from Your failure to
                comply with this Section 4.2. You may be held liable for losses
                incurred by Superceuticals or any other user of or visitor to
                the Website due to authorized or unauthorized use of Your
                Account as a result of Your failure in keeping Your Account
                Information secure and confidential.
              </p>
              <p className="text-left py-1">
                4.3 The Website also allows restricted access to the Services
                for unregistered Users.
              </p>
              <p className="text-left py-1">
                4.4 You shall ensure that the Account Information provided by
                You in the Website's registration form is complete, accurate and
                up-to-date. Use of another user's Account Information for
                availing the Services is expressly prohibited.
              </p>
              <p className="text-left py-1">
                4.5 If You provide any information that is untrue, inaccurate,
                not current or incomplete (or becomes untrue, inaccurate, not
                current or incomplete), or Superceuticals has reasonable grounds
                to suspect that such information is untrue, inaccurate, not
                current or incomplete, Superceuticals has the right to suspend
                or terminate Your Account and refuse any and all current or
                future use of the Website (or any portion thereof).
              </p>
              <h1 className="font-bold text-xl py-2">5. Pricing Information</h1>
              <p className="text-left py-1">
                5.1 Superceuticals strives to provide You with the best prices
                possible on products and services You buy or avail of from the
                Website. The pricing details for the purchase of products and
                services from the Website are detailed under the Terms of Offer
                For Sale.
              </p>
              <p className="text-left py-1">
                5.2 You agree to provide correct and accurate credit/debit card
                details to the approved payment gateway for availing Services on
                the Website. You shall not use the credit/debit card which is
                not lawfully owned by You, i.e. in any transaction, You must use
                Your own credit/debit card. The information provided by You will
                not be utilized or shared with any third party unless required
                in relation to fraud verifications or by law, regulation, or
                court order. You will be solely responsible for the security and
                confidentiality of Your credit/debit card details.
                Superceuticals expressly disclaims all liabilities that may
                arise as a consequence of any unauthorized use of Your
                credit/debit card.
              </p>
              <p className="text-left py-1">
                5.3 Superceuticals shall be under no liability whatsoever in
                respect of any loss or damage arising directly or indirectly out
                of the decline of authorization for any transaction, on account
                of you/cardholder having exceeded the pre-set limit mutually
                agreed by Superceuticals with our acquiring bank from time to
                time.
              </p>
              <h1 className="font-bold text-xl py-2">6. User Obligations</h1>
              <p className="text-left py-1">
                6.1 Subject to compliance with the Terms of Use, Superceuticals
                grants You a non-exclusive, limited privilege to access and use
                this Website and the Services provided therein.
              </p>
              <p className="text-left py-1">
                6.2 You agree to use the Services, Website, and the materials
                provided therein only for purposes that are permitted by: (a)
                the Terms of Use; and (b) any applicable law, regulation, or
                generally accepted practices or guidelines in the relevant
                jurisdictions.
              </p>
              <p className="text-left py-1">
                6.3 You agree to adhere to all limitations on dissemination,
                use, and reproduction of any materials (such as the product
                catalogues) that You access on the Website in accordance with
                Section 7.
              </p>
              <p className="text-left py-1">
                6.4 You agree not to access (or attempt to access) the Website
                and the materials or Services by any means other than through
                the interface that is provided by Superceuticals. You shall not
                use any deep-link, robot, spider, or other automatic device,
                program, algorithm, or methodology, or any similar or equivalent
                manual process, to access, acquire, copy, or monitor any portion
                of the Website or Content (as defined below), or in any way
                reproduce or circumvent the navigational structure or
                presentation of the Website, materials, or any Content, to
                obtain or attempt to obtain any materials, documents, or
                information through any means not specifically made available
                through the Website.
              </p>
              <p className="text-left py-1">
                6.5 You acknowledge and agree that by accessing or using the
                Website or Services, You may be exposed to content from other
                users that You may consider offensive, indecent, or otherwise
                objectionable. Superceuticals disclaims all liabilities arising
                in relation to such offensive content on the Website. Further,
                You may report such offensive content in the manner prescribed
                under Section 17 herein.
              </p>
              <p className="text-left py-1">
                6.6 If the Website allows You to post and upload any material on
                the Website, You hereby undertake to ensure that such material
                is not offensive and in accordance with applicable laws.
                Further, You undertake not to:
              </p>
              <ul className="text-left py-1">
                <li>
                  Defame, abuse, harass, threaten or otherwise violate the legal
                  rights of others;
                </li>
                <li>
                  Impersonate any person or entity, or falsely state or
                  otherwise misrepresent Your affiliation with a person or
                  entity;
                </li>
                <li>
                  Publish, post, upload, distribute, or disseminate any
                  inappropriate, profane, defamatory, infringing, obscene,
                  indecent, or unlawful topic, name, material or information
                  through any bookmark, tag, or keyword;
                </li>
                <li>
                  Upload files that contain software or other material protected
                  by applicable intellectual property laws unless You own or
                  control the rights thereto or have received all necessary
                  consents;
                </li>
                <li>
                  Upload or distribute files that contain viruses, corrupted
                  files, or any other similar software or programs that may
                  damage the operation of the Website or another's computer;
                </li>
                <li>
                  Engage in any activity that interferes with or disrupts access
                  to the Website or the Services (or the servers and networks
                  which are connected to the Website);
                </li>
                <li>
                  Attempt to gain unauthorized access to any portion or feature
                  of the Website, any other systems or networks connected to the
                  Website, to any Superceuticals server, or to any of the
                  Services offered on or through the Website, by hacking,
                  password mining or any other illegitimate means;
                </li>
                <li>
                  Probe, scan or test the vulnerability of the Website or any
                  network connected to the Website, nor breach the security or
                  authentication measures on the Website or any network
                  connected to the Website.
                </li>
                <li>
                  You may not reverse look-up, trace or seek to trace any
                  information on any other user, of or visitor to, the Website,
                  or any other customer of Superceuticals, including any
                  Superceuticals Account not owned by You, to its source, or
                  exploit the Website or Service or information made available
                  or offered by or through the Website, in any way whether or
                  not the purpose is to reveal any information, including but
                  not limited to personal identification information, other than
                  Your own information, as provided for by the Website;
                </li>
                <li>
                  Disrupt or interfere with the security of, or otherwise cause
                  harm to, the Website, systems resources, accounts, passwords,
                  servers or networks connected to or accessible through the
                  Websites or any affiliated or linked sites;
                </li>
                <li>
                  Collect or store data about other users in connection with the
                  prohibited conduct and activities set forth in this Section.
                </li>
                <li>
                  Use any device or software to interfere or attempt to
                  interfere with the proper working of the Website or any
                  transaction being conducted on the Website, or with any other
                  person's use of the Website;
                </li>
                <li>
                  Use the Website or any material or Content for any purpose
                  that is unlawful or prohibited by these Terms of Use, or to
                  solicit the performance of any illegal activity or other
                  activity which infringes the rights of Superceuticals or other
                  third parties;
                </li>
                <li>
                  Conduct or forward surveys, contests, pyramid schemes or chain
                  letters;
                </li>
                <li>
                  Download any file posted by another user of a Service that you
                  know, or reasonably should know, cannot be legally distributed
                  in such manner;
                </li>
                <li>
                  Falsify or delete any author attributions, legal or other
                  proper notices or proprietary designations or labels of the
                  origin or source of software or other material contained in a
                  file that is uploaded;
                </li>
                <li>
                  Violate any code of conduct or other guidelines, which may be
                  applicable for or to any particular Service;
                </li>
                <li>
                  Violate any applicable laws or regulations for the time being
                  in force within or outside India;
                </li>
                <li>
                  Violate the Terms of Use including but not limited to any
                  applicable Additional Terms of the Website contained herein or
                  elsewhere; and
                </li>
                <li>
                  Reverse engineer, modify, copy, distribute, transmit, display,
                  perform, reproduce, publish, license, create derivative works
                  from, transfer, or sell any information or software obtained
                  from the Website.
                </li>
              </ul>
              <p className="text-left py-1">
                6.7 You agree that You are solely responsible to Superceuticals
                and to any third party for any breach of Your obligations under
                the Terms of Use and for the consequences (including any loss or
                damage which Superceuticals or its affiliates or its vendors may
                suffer) for any such breach.
              </p>
              <p className="text-left py-1">
                6.8 You agree and acknowledge that Superceuticals is not the
                seller of the products and the services and Superceuticals shall
                in no manner be deemed to be the seller of the products or
                services on this Website. Superceuticals is only facilitating
                the purchase of the products or services by You from the vendor
                by providing the Services to You.
              </p>
              <p className="text-left py-1">
                6.9 You agree that Superceuticals may, at any time, modify or
                discontinue all or part of the Website, charge, modify or waive
                fees required to use the Website, or offer opportunities to some
                or all Website Users.
              </p>
              <h1 className="font-bold text-xl py-2">7. Use of Materials</h1>
              <p className="text-left py-1">
                7.1 Except as expressly indicated to the contrary in any
                applicable Additional Terms, Superceuticals hereby grants You a
                non-exclusive, freely revocable (upon notice from
                Superceuticals), non-transferable access to view, download and
                print product catalogues or any other materials available on the
                Website, subject to the following conditions:
              </p>
              <ul className="text-left py-1">
                <li>
                  You may access and use the materials solely for personal,
                  informational, and internal purposes, in accordance with the
                  Terms of Use;
                </li>
                <li>
                  You may not modify or alter product catalogues or any other
                  materials available on the Website;
                </li>
                <li>
                  You may not distribute or sell, rent, lease, license or
                  otherwise make the product catalogues or any other materials
                  available on the Website available to others; and
                </li>
                <li>
                  You may not remove any text, copyright or other proprietary
                  notices contained in the product catalogues or any other
                  materials available on the Website.
                </li>
              </ul>
              <p className="text-left py-1">
                7.2 The rights granted to You in the product catalogues or any
                other materials as specified above are not applicable to the
                design, layout or look and feel of the Website. Such elements of
                the Website are protected by intellectual property rights and
                may not be copied or imitated in whole or in part. The product
                catalogues or any other materials available on the Website may
                be copied or retransmitted unless expressly permitted by
                Superceuticals.
              </p>
              <p className="text-left py-1">
                7.3 Any software that is available on the Website is the
                property of Superceuticals or its vendors. You may not use,
                download or install any software available at the Website unless
                otherwise expressly permitted by these Terms of Use or by the
                express written permission of Superceuticals.
              </p>
              <p className="text-left py-1">
                7.4 Any purchase of the merchandise or Services from the Website
                will be strictly for personal use of the User. The User hereby
                expressly agrees that any merchandise or Services purchased by
                the User will not be sold, resold, bartered or in any way used
                for any commercial purposes or for profit. The User hereby
                acknowledges that the Services or merchandise purchased are not
                transferable to any third party for profit.
              </p>
              <p className="text-left py-1">
                7.5 Delivery of the Product Your shipping address, pin code will
                be verified with the database of Superceuticals before You
                proceed to pay for Your purchase. In the event case your order
                is not serviceable by our delivery partners or the merchant or
                the area is not covered, we would request you to provide us with
                an alternate shipping address which we expect to have on our
                partner/merchant's delivery list. In case there is any dispute
                regarding the shipment of the product or services for the area
                not covered by Superceuticals, in such cases Superceuticals will
                not be responsible for the non-delivery of the product.
                Superceuticals doesn't deliver items internationally unless by
                express written consent by Superceuticals. However, you can make
                purchases on the website from anywhere in the world but at the
                same time ensuring the shipping address is within India. In case
                you book multiple orders for the Products and Services in one
                transaction, Superceuticals would endeavour to ship all Products
                together. However, this may not always be possible due to some
                product characteristics and/or logistics' issues. If You
                purchase multiple Products in a single transaction, then all the
                Products would be dispatched to a single shipping address given
                by You. If You wish to ship Products to different addresses,
                then You should book the orders separately based on the delivery
                addresses.
              </p>
              <h1 className="font-bold text-xl py-2">8. Usage Conduct</h1>
              <p className="text-left py-1">
                8.1 You shall solely be responsible for maintaining the
                necessary computer equipment and Internet connections that may
                be required to access, use, and transact on the Website.
              </p>
              <p className="text-left py-1">
                8.2 You are also under an obligation to use this Website for
                reasonable and lawful purposes only and shall not indulge in any
                activity that is not envisaged through the Website.
              </p>
              <p className="text-left py-1">
                8.3 You shall use this Website, and any voucher/coupons
                purchased through it, for personal, non-commercial use only and
                shall not resell the same to any other person.
              </p>
              <p className="text-left py-1">
                8.4 Superceuticals shall be under no liability whatsoever in
                respect of any loss or damage arising directly or indirectly out
                of the decline of authorization for any transaction, on account
                of you/cardholder having exceeded the pre-set limit mutually
                agreed by Superceuticals with our acquiring bank from time to
                time.
              </p>
              <h1 className="font-bold text-xl py-2">
                9. Intellectual Property Rights
              </h1>
              <p className="text-left py-1">
                9.1 The Website and the processes, and their selection and
                arrangement, including but not limited to all text, graphics,
                user interfaces, visual interfaces, sounds and music (if any),
                artwork, and computer code (collectively, the “Content”) on the
                Website is owned and controlled by Superceuticals and the
                design, structure, selection, coordination, expression, look and
                feel and arrangement of such Content is protected by copyright,
                patent, and trademark laws, and various other intellectual
                property rights.
              </p>
              <p className="text-left py-1">
                9.2 The trademarks, logos, and service marks displayed on the
                Website (“Marks”) are the property of Superceuticals or their
                vendors or respective third parties. You are not permitted to
                use the Marks without the prior consent of Superceuticals, the
                vendor, or the third party that may own the Marks.
              </p>
              <p className="text-left py-1">
                9.3 Unless otherwise indicated or anything contained to the
                contrary or any proprietary material owned by a third party and
                so expressly mentioned, Superceuticals owns all intellectual
                property rights to and into the trademark “Superceuticals”,
                “SC-AN”, and the Website, including, without limitation, any and
                all rights, title and interest in and to copyright, related
                rights, patents, utility models, designs, know-how, trade
                secrets and inventions (patent pending), goodwill, source code,
                meta tags, databases, text, content, graphics, icons, and
                hyperlinks.
              </p>
              <p className="text-left py-1">
                9.4 Except as expressly provided herein, You acknowledge and
                agree that You shall not copy, republish, post, display,
                translate, transmit, reproduce, or distribute any Content
                through any medium without obtaining the necessary authorization
                from Superceuticals or the third party owner of such Content.
              </p>

              <h1 className="font-bold text-xl py-2">
                10. Third Party Content
              </h1>
              <p className="text-left py-1">
                The Website makes available general third party information such
                as, product catalogues, lists of authorized dealers, reports on
                news, entertainment, technology, and features, advertisements
                including videos, images, and photographs of the products and
                other data from external sources (“Third Party Content”).
                Similar Third Party Content would also be available to You on
                the email received by You from Superceuticals. The provision of
                Third Party Content is for general informational purposes only.
                You acknowledge that the Third Party Content provided to You is
                obtained from sources believed to be reliable. Superceuticals
                does not provide any guarantee with respect to any the Third
                Party Content and Superceuticals shall not be held liable for
                any loss suffered by You based on Your reliance on or use of
                such data.
              </p>
              <h1 className="font-bold text-xl py-2">
                11. Disclaimer of Warranties & Liability
              </h1>
              <p className="text-left py-1">
                11.1 THE WEBSITE, SERVICES AND OTHER MATERIALS ARE PROVIDED BY
                SUPERCEUTICALS ON AN “AS IS” BASIS WITHOUT WARRANTY OF ANY KIND,
                EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING THE IMPLIED
                WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR
                FITNESS FOR A PARTICULAR PURPOSE. WITHOUT LIMITING THE
                FOREGOING, SUPERCEUTICALS MAKES NO WARRANTY THAT (I) THE WEBSITE
                OR THE SERVICES WILL MEET YOUR REQUIREMENTS OR YOUR USE OF THE
                WEBSITE OR THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR
                ERROR-FREE; (II) THE RESULTS THAT MAY BE OBTAINED FROM THE USE
                OF THE WEBSITE, SERVICES OR MATERIALS WILL BE EFFECTIVE,
                ACCURATE OR RELIABLE; (III) THE QUALITY OF THE WEBSITE, SERVICES
                OR OTHER MATERIALS WILL MEET YOUR EXPECTATIONS; OR THAT (IV) ANY
                ERRORS OR DEFECTS IN THE WEBSITE, SERVICES OR OTHER MATERIALS
                WILL BE CORRECTED. NO ADVICE OR INFORMATION, WHETHER ORAL OR
                WRITTEN, OBTAINED BY YOU FROM SUPERCEUTICALS OR THROUGH OR FROM
                USE OF THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY
                STATED IN THE TERMS OF USE.
              </p>
              <p className="text-left py-1">
                11.2 TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
                SUPERCEUTICALS WILL HAVE NO LIABILITY RELATED TO USER CONTENT
                ARISING UNDER INTELLECTUAL PROPERTY RIGHTS, LIBEL, PRIVACY,
                PUBLICITY, OBSCENITY OR OTHER LAWS. SUPERCEUTICALS ALSO
                DISCLAIMS ALL LIABILITY WITH RESPECT TO THE MISUSE, LOSS,
                MODIFICATION OR UNAVAILABILITY OF ANY USER CONTENT.
              </p>
              <p className="text-left py-1">
                11.3 SUPERCEUTICALS WILL NOT BE LIABLE FOR ANY LOSS THAT YOU MAY
                INCUR AS A CONSEQUENCE OF UNAUTHORIZED USE OF YOUR ACCOUNT OR
                ACCOUNT INFORMATION IN CONNECTION WITH THE WEBSITE OR ANY
                SERVICES OR MATERIALS, EITHER WITH OR WITHOUT YOUR KNOWLEDGE.
                SUPERCEUTICALS HAS ENDEAVORED TO ENSURE THAT ALL THE INFORMATION
                ON THE WEBSITE IS CORRECT, BUT SUPERCEUTICALS NEITHER WARRANTS
                NOR MAKES ANY REPRESENTATIONS REGARDING THE QUALITY, ACCURACY OR
                COMPLETENESS OF ANY DATA, INFORMATION, PRODUCT OR SERVICE.
                SUPERCEUTICALS SHALL NOT BE RESPONSIBLE FOR THE DELAY OR
                INABILITY TO USE THE WEBSITE OR RELATED FUNCTIONALITIES, THE
                PROVISION OF OR FAILURE TO PROVIDE FUNCTIONALITIES, OR FOR ANY
                INFORMATION, SOFTWARE, PRODUCTS, FUNCTIONALITIES AND RELATED
                GRAPHICS OBTAINED THROUGH THE WEBSITE, OR OTHERWISE ARISING OUT
                OF THE USE OF THE WEBSITE, WHETHER BASED ON CONTRACT, TORT,
                NEGLIGENCE, STRICT LIABILITY OR OTHERWISE. FURTHER,
                SUPERCEUTICALS SHALL NOT BE HELD RESPONSIBLE FOR
                NON-AVAILABILITY OF THE WEBSITE DURING PERIODIC MAINTENANCE
                OPERATIONS OR ANY UNPLANNED SUSPENSION OF ACCESS TO THE WEBSITE
                THAT MAY OCCUR DUE TO TECHNICAL REASONS OR FOR ANY REASON BEYOND
                SUPERCEUTICALS CONTROL. THE USER UNDERSTANDS AND AGREES THAT ANY
                MATERIAL OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE
                WEBSITE IS DONE ENTIRELY AT THEIR OWN DISCRETION AND RISK AND
                THEY WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO THEIR COMPUTER
                SYSTEMS OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH
                MATERIAL OR DATA. SUPERCEUTICALS IS NOT RESPONSIBLE FOR ANY
                TYPOGRAPHICAL ERROR LEADING TO AN INVALID COUPON. SUPERCEUTICALS
                ACCEPTS NO LIABILITY FOR ANY ERRORS OR OMISSIONS, WITH RESPECT
                TO ANY INFORMATION PROVIDED TO YOU WHETHER ON BEHALF OF ITSELF
                OR THIRD PARTIES.
              </p>
              <p className="text-left py-1">
                11.4 SUPERCEUTICALS SHALL NOT BE LIABLE FOR ANY THIRD PARTY
                PRODUCT OR SERVICES. THE ADVERTISEMENT AVAILABLE ON E-MAIL OR
                WEBSITE WITH RESPECT TO THE THIRD PARTY WEBSITE OR THE PRODUCTS
                AND SERVICES ARE FOR INFORMATION PURPOSE ONLY.
              </p>
              <h1 className="font-bold text-xl py-2">
                12. Indemnification and Limitation of Liability
              </h1>
              <p className="text-left py-1">
                12.1 You agree to indemnify, defend and hold harmless
                Superceuticals including but not limited to its affiliate
                vendors, agents and employees from and against any and all
                losses, liabilities, claims, damages, demands, costs and
                expenses (including legal fees and disbursements in connection
                therewith and interest chargeable thereon) asserted against or
                incurred by Superceuticals that arise out of, result from, or
                may be payable by virtue of, any breach or non-performance of
                any representation, warranty, covenant or agreement made or
                obligation to be performed by You pursuant to these Terms of
                Use. Further, You agree to hold Superceuticals harmless against
                any claims made by any third party due to, or arising out of, or
                in connection with, Your use of the Website, any claim that Your
                material caused damage to a third party, Your violation of the
                Terms of Use, or Your violation of any rights of another,
                including any intellectual property rights.
              </p>
              <p className="text-left py-1">
                12.2 Notwithstanding anything to contrary, Superceuticals entire
                liability to You under this Terms of Use or otherwise shall be
                the refund of the money charged from You for any specific
                voucher or product or service, under which the unlikely
                liability arises.
              </p>
              <p className="text-left py-1">
                12.3 In no event shall Superceuticals, its officers, directors,
                employees, partners or suppliers be liable to You, the vendor or
                any third party for any special, incidental, indirect,
                consequential or punitive damages whatsoever, including those
                resulting from loss of use, data or profits, whether or not
                foreseeable or whether or not Superceuticals has been advised of
                the possibility of such damages, or based on any theory of
                liability, including breach of contract or warranty, negligence
                or other tortious action, or any other claim arising out of or
                in connection with Your use of or access to the Website,
                Services or materials.
              </p>
              <p className="text-left py-1">
                12.4 The limitations and exclusions in this section apply to the
                maximum extent permitted by applicable law.
              </p>

              <h1 className="font-bold text-xl py-2">
                13. Violation of the Terms of Use
              </h1>
              <p className="text-left py-1">
                13.1 You agree that Superceuticals may, in its sole discretion
                and without prior notice, terminate Your access to the Website
                and block Your future access to the Website if Superceuticals
                determines that You have violated these Terms of Use or
                Additional Terms. You also agree that any violation by You of
                these Terms of Use will constitute an unlawful and unfair
                business practice, and will cause irreparable harm to
                Superceuticals, for which monetary damages would be inadequate,
                and You consent to Superceuticals obtaining any injunctive or
                equitable relief that Superceuticals deems necessary or
                appropriate in such circumstances. These remedies are in
                addition to any other remedies Superceuticals may have at law or
                in equity.
              </p>
              <p className="text-left py-1">
                13.2 You agree that Superceuticals may, in its sole discretion,
                and without prior notice, terminate Your access to the Website,
                for cause, which includes (but is not limited to): (1) requests
                by law enforcement or other government agencies; (2) a request
                by You (self-initiated account deletions); (3) discontinuance or
                material modification of the Website or any service offered on
                or through the Website; or (4) unexpected technical issues or
                problems.
              </p>
              <p className="text-left py-1">
                13.3 If Superceuticals does take any legal action against You as
                a result of Your violation of these Terms of Use, Superceuticals
                will be entitled to recover from You, and You agree to pay, all
                reasonable attorneys's fees and costs of such action, in
                addition to any other relief granted to Superceuticals.
              </p>

              <h1 className="font-bold text-xl py-2">14. Termination</h1>
              <p className="text-left py-1">
                14.1 The Terms of Use will continue to apply until terminated by
                either You or Superceuticals as set forth below. If You want to
                terminate Your agreement with Superceuticals, You may do so by
                (i) not accessing the Website; or (ii) closing Your accounts for
                all of the Services that You use, where Superceuticals has made
                this option available to You.
              </p>
              <p className="text-left py-1">
                14.2 Superceuticals may, at any time, with or without notice,
                terminate the Terms of Use (or portion thereof, such as any
                individual Additional Terms) with You if: You breach any of the
                provisions of the Terms of Use, the Privacy Policy or any other
                terms, conditions, or policies that may be applicable to You
                from time to time (or have acted in a manner that clearly shows
                that You do not intend to, or are unable to, comply with the
                same); Superceuticals is required to do so by law (for example,
                where the provision of the Services to You is, or becomes,
                unlawful); The provision of the Services to You by
                Superceuticals is, in Superceuticals' opinion, no longer
                commercially viable; Superceuticals has elected to discontinue,
                with or without reason, access to the Website, the Services (or
                any part thereof); or
              </p>
              <p className="text-left py-1">
                14.3 Superceuticals may also terminate or suspend all or a
                portion of Your account or access to the Services with or
                without reason. Except as may be set forth in any Additional
                Terms applicable to a particular Service, termination of Your
                Account may include: (i) removal of access to all offerings
                within the Website or with respect to the Services; (ii)
                deletion of Your materials and Account Information, including
                Your personal information, log-in ID and password, and all
                related information, files and materials associated with or
                inside Your Account (or any part thereof); and (iii) barring of
                further use of the Services.
              </p>
              <p className="text-left py-1">
                14.4 You agree that all terminations shall be made in
                Superceuticals' sole discretion and that Superceuticals shall
                not be liable to You or any third party for any termination of
                Your Account (and accompanying deletion of Your Account
                Information), or Your access to the Website and Services.
              </p>
              <p className="text-left py-1">
                14.5 Notwithstanding the foregoing, these Terms of Use will
                survive indefinitely unless and until Superceuticals chooses to
                terminate them.
              </p>
              <p className="text-left py-1">
                14.6 If You or Superceuticals terminates Your use of the
                Website, Superceuticals may delete any content or other
                materials relating to Your use of the Website and Superceuticals
                will have no liability to You or any third party for doing so.
              </p>

              <h1 className="font-bold text-xl py-2">15. Governing Law</h1>
              <p className="text-left py-1">
                15.1 These Terms of Use and all transactions entered into on or
                through the Website and the relationship between You and
                Superceuticals shall be governed in accordance with the laws of
                India without reference to conflict of laws principles.
              </p>
              <p className="text-left py-1">
                15.2 You agree that all claims, differences and disputes arising
                under or in connection with or in relation hereto the Website,
                the Terms of Use or any transactions entered into on or through
                the Website or the relationship between You and Superceuticals
                shall be subject to the exclusive jurisdiction of the courts at
                New Delhi, India and You hereby accede to and accept the
                jurisdiction of such courts.
              </p>

              <h1 className="font-bold text-xl py-2">16. Report Abuse</h1>
              <p className="text-left py-1">
                In the event You come across any abuse or violation of these
                Terms of Use or if You become aware of any objectionable content
                on the Website, please report to Superceuticals customer
                support.
              </p>

              <h1 className="font-bold text-xl py-2">17. Privacy Policy</h1>
              <p className="text-left py-1">
                The User hereby consents, expresses and agrees that he has read
                and fully understands the Privacy Policy of Superceuticals in
                respect of the Website. You further consent that the terms and
                contents of such Privacy Policy are acceptable to You.
              </p>

              <h1 className="font-bold text-xl py-2">18. Communications</h1>
              <p className="text-left py-1">
                18.1 You hereby expressly agree to receive communications from
                Superceuticals by way of SMS, e-mails and other mediums related
                to the services provided. And irrespective of the fact if also
                you have registered yourself under DND or DNC or NCPR service,
                you still authorize us to give you a call from Superceuticals.
              </p>

              <h1 className="font-bold text-xl py-2">19. General Provisions</h1>
              <p className="text-left py-1">
                19.1 Notice: All notices of Superceuticals will be served by
                email or by general notification on the Website. Any notice
                provided to Superceuticals pursuant to the Terms of Use should
                be sent to Superceuticals customer support
              </p>
              <p className="text-left py-1">
                19.2 Assignment: You cannot assign or otherwise transfer the
                Terms of Use, or any rights granted hereunder to any third
                party. Superceuticals' rights under the Terms of Use are freely
                transferable by Superceuticals to any third parties without the
                requirement of seeking Your consent.
              </p>
              <p className="text-left py-1">
                19.3 Severability: If, for any reason, a court of competent
                jurisdiction finds any provision of the Term of Use, or portion
                thereof, to be unenforceable, that provision shall be enforced
                to the maximum extent permissible so as to give effect to the
                intent of the parties as reflected by that provision, and the
                remainder of the Terms of Use shall continue in full force and
                effect.
              </p>
              <p className="text-left py-1">
                19.4 Waiver: Any failure by Superceuticals to enforce or
                exercise any provision of the Terms of Use, or any related
                right, shall not constitute a waiver by Superceuticals of that
                provision or right.
              </p>

              <h1 className="font-bold text-xl py-2">
                20. Feedback and Information
              </h1>
              <p className="text-left py-1">
                Any feedback You provide to this Website shall be deemed to be
                non-confidential. Superceuticals shall be free to use such
                information on an unrestricted basis. Further, by submitting the
                feedback, You represent and warrant that (i) Your feedback does
                not contain confidential or proprietary information of You or of
                third parties; (ii) Superceuticals is not under any obligation
                of confidentiality, express or implied, with respect to the
                feedback; (iii) Superceuticals may have something similar to the
                feedback already under consideration or in development; and (iv)
                You are not entitled to any compensation or reimbursement of any
                kind from Superceuticals for the feedback under any
                circumstances.
              </p>

              <h1 className="font-bold text-xl py-2">
                21. Superceuticals reserves the right to decide/alter/change the
                expiration period of the activities at any time with or without
                notice.
              </h1>
              <p className="text-left py-1">
                21.1 Superceuticals reserves the right at any time, without
                notice, to add/alter/change/or vary any or all of these terms
                and conditions or to replace, wholly or in part, with any
                feature, whether similar to this or not, or to withdraw it
                altogether.
              </p>

              <p className="text-left py-1">
                21.2 Superceuticals reserves the right to disqualify any
                customers from the benefits mention herein above. In case of any
                fraudulent activity, prosecution will be carried out against the
                concerned customer.
              </p>

              <p className="text-left py-1">
                Copyright © The Copyright Act, 1957.
              </p>

              <p className="text-left py-1">Terms Of Offer For Sale</p>

              <p className="text-left py-1">
                These terms of offer for sale (“Terms of Offer For Sale“)
                between Superceuticals Pvt Ltd, Superceuticals (hereinafter
                referred to as “Superceuticals”) and the users of the Website
                (“You” or “Your” or “Yourself” or “User“) describe, inter alia,
                the terms of offer for sale, purchase of goods and services
                through the redemption of cash vouchers/ coupons (“Voucher/s“)
                or otherwise on the Website, www.superceuticals.in (“Website“).
              </p>

              <p className="text-left py-1">
                PLEASE READ THE TERMS OF OFFER FOR SALE CAREFULLY BEFORE
                PURCHASING ANY PRODUCTS OR AVAILING ANY SERVICES ON THE WEBSITE.
                ANY PURCHASE MADE BY YOU THROUGH THE WEBSITE OF THE PRODUCTS/
                SERVICES SHALL SIGNIFY YOUR ACCEPTANCE OF THE TERMS OF OFFER FOR
                SALE AND YOUR AGREEMENT TO BE LEGALLY BOUND BY THE SAME.
              </p>

              <p className="text-left py-1">
                IN ADDITION TO THE FOREGOING, YOU SHALL ALSO BE BOUND BY THE
                TERMS OF USE OF THE WEBSITE, TERMS OF OFFER FOR SALE ISSUED BY
                THE VENDOR OR ADDITIONAL TERMS OF SERVICE WHICH ARE DISPLAYED
                WITH THE SELECTION OF THE PRODUCT, IF ANY (“ADDITIONAL TERMS“)
                AT THE WEBSITE. IF THERE IS ANY CONFLICT BETWEEN THE TERMS OF
                OFFER FOR SALE AND THE ADDITIONAL TERMS, THE ADDITIONAL TERMS
                SHALL TAKE PRECEDENCE IN RELATION TO THAT SALE.
              </p>

              <p className="text-left py-1">
                IF YOU DO NOT AGREE WITH THE TERMS OF OFFER FOR SALE, PLEASE DO
                NOT ACCESS THE WEBSITE FOR ANY PURCHASE.
              </p>

              <h1 className="font-bold text-xl py-2">1. Business</h1>
              <p className="text-left py-1">
                1.1 The Website is a platform that facilitates the online sale
                and purchase of branded merchandise and services (“Services”)
                offered by Superceuticals various affiliates/ registered
                merchants/ vendors/ service providers (“Vendor/s”). The Services
                are offered to the Users through various modes which shall
                include but not be limited to, sale of Vouchers that can be
                redeemed for various products and services, sale of products
                etc. The Vendors are the sellers of products and services on the
                Website and will be solely responsible to You for the products
                sold or for redemption of any Voucher.
              </p>

              <p className="text-left py-1">
                1.2 You acknowledge that Superceuticals has the right to change
                or discontinue any Service at any time, without notice. You
                further acknowledge that Superceuticals may add or modify the
                procedures, modes, processes or conditions of purchase at any
                time to adapt to changes that Superceuticals may make to the
                Services. You agree that Superceuticals shall not be liable to
                You or to any third party for any modification, suspension or
                discontinuance of any aspect of the Services.
              </p>

              <h1 className="font-bold text-xl py-2"> 2. Vouchers </h1>
              <p className="text-left py-1">
                2.1 In furtherance of the Services provided and the eligibility
                criteria stated under the Terms of Use located at the homepage
                www.superceuticals.in, You shall be eligible to purchase
                Vouchers of a specific monetary value.
              </p>

              <p className="text-left py-1">
                2.2 Superceuticals will facilitate the provision of the best,
                pre-negotiated deals for a particular product/service at a given
                price for a given place. Purchase of such Vouchers will be
                subject to the Terms of Offer for Sale and such other terms, if
                any, as specified by the Vendor.
              </p>

              <p className="text-left py-1">
                2.3 Each Voucher will have the necessary redemption instructions
                printed on it. The Vouchers may contain an offer period, or
                expiry date mentioned on it, You shall use it accordingly.
                Unless otherwise stated, You will not be entitled to receive any
                credit or cash-back for the value of the Voucher You don't
                redeem or use within the stipulated time period as mentioned in
                the Voucher, if any. You agree that the Voucher issued is
                specific to the product or service selected and cannot be used
                for the purchase of any other product or service as offered by
                the Website and cannot be used for the purchase of any other
                product or service and cannot be combined with other special
                offers or promotions that may be made available on the Website.
              </p>

              <p className="text-left py-1">
                2.4 On making payment for the Voucher through any mode,
                including and not limiting to cash on delivery, You will be
                issued a Voucher which must be presented to the venue/ store of
                the Vendor, pursuant to which You can complete Your purchase of
                the relevant product or avail of the relevant service from such
                Vendor. The displayed price stated on the Voucher shall be
                inclusive of applicable taxes unless mentioned otherwise.
              </p>

              <p className="text-left py-1">
                2.5 Vouchers once sold are non-refundable, whether You use it or
                not. Only one Voucher is allowed per transaction.
              </p>

              <p className="text-left py-1">
                2.6 You agree that neither Superceuticals nor the Vendors shall
                be liable in the event You lose, misplace or destroy Your
                Voucher at any time.
              </p>

              <h1 className="font-bold text-xl py-2"> 3. Product</h1>
              <p className="text-left py-1">
                3.1 Superceuticals, through the Website also makes available of
                variety of branded products and merchandise at an agreed price
                to you. Purchase of such products will be subject to the Terms
                of Offer for Sale and such other additional terms, if any, as
                specified by us or the Vendor.
              </p>

              <p className="text-left py-1">
                3.2 Superceuticals offers SHIPPING for all the products on the
                Website as per the company's policy, which may be changed by
                Superceuticals without any notice to You. Octroi charges,
                wherever applicable, is borne and paid by the Superceuticals and
                not to be borne by User.
              </p>

              <p className="text-left py-1">
                3.3 All the Products are governed by the terms of warranties
                provided by the respective manufacturer/ brands. However, in
                case any product is covered under seller warranty, it shall be
                specifically mentioned under the product details.
              </p>

              <p className="text-left py-1">
                3.4 The price of products offered on the Website is either equal
                to or lesser than the Maximum Retail Price (“MRP“) i.e., the
                discounted rate prescribed for those products. The MRP and other
                statutory declarations shall be mentioned on the products and/or
                on its packaging in accordance with applicable laws.
              </p>

              <p className="text-left py-1">
                3.5 In the event you purchase any electronic product/s from the
                Vendors, Superceuticals shall not be liable for any fault
                arising from this electronic product/s. You are requested to
                visit the nearest service station as per user manual, if any or
                as indicated by the Vendor/s for any default in the electronic
                product/s. In case if there is no service station located close
                to you, then, in such circumstances, Superceuticals shall recall
                the product/s upon your request and send it to the service
                station on your behalf. The personnel of service station shall
                directly get in touch with you for the cost incurred.
              </p>

              <h1 className="font-bold text-xl py-2"> 4. Services </h1>
              <p className="text-left py-1">
                4.1 You should take all responsibility for your own actions in
                utilizing the services purchased by you and Superceuticals shall
                not be liable for any such action.
              </p>

              <p className="text-left py-1">
                4.2 You represents that you are of legal age to form a binding
                contract with Superceuticals and are not a person barred from
                receiving services under the laws as applicable in India.
              </p>

              <p className="text-left py-1">
                4.3 You confirm that Superceuticals shall not be responsible for
                any deficiency in payment of consideration payable towards the
                services purchased from the Website.
              </p>

              <p className="text-left py-1">
                4.4 Superceuticals does not offer any refunds against any
                services already purchased from the Website unless, any error
                has occurred during the purchase or redemption of such services
                which is directly attributable to Superceuticals.
              </p>

              <h1 className="font-bold text-xl py-2">
                {" "}
                5. Pricing Information{" "}
              </h1>
              <p className="text-left py-1">
                5.1 Superceuticals strives to provide You with the best prices
                possible on products and services You buy or avail of from the
                Website. However, Superceuticals does not guarantee that the
                price will be the lowest in the city, region or geography.
                Prices and availability are subject to change without notice or
                any consequential liability to you.
              </p>

              <p className="text-left py-1">
                5.2 While Superceuticals strives to provide accurate products,
                services and pricing information, typographical and other errors
                may occur. In the event that a product or service is listed at
                an incorrect price or with incorrect information due to an error
                in pricing or product or service information, Superceuticals
                may, at its discretion, either contact You for instructions or
                cancel Your order and notify You of such cancellation.
                Superceuticals will have the right to modify the price of the
                product or service and contact You for further instructions via
                e-mail address provided by You at the time of registration, or
                cancel the order and notify You of such cancellation. If
                Superceuticals cancels the order after the payment has been
                processed, the said amount will be remitted to Your account from
                which the payment was made.
              </p>

              <h1 className="font-bold text-xl py-2">
                {" "}
                6. Credit Card Details{" "}
              </h1>
              <p className="text-left py-1">
                6. In certain cases, specifically with regard to particular
                products/ services, You might be required to provide Your credit
                or debit card details to the approved payment gateways while
                making the payment. In this regard You agree to provide correct
                and accurate credit/ debit card details to the approved payment
                gateways for availing Services on the Website. You shall not use
                the credit/ debit card which is not lawfully owned by You, i.e.
                in any transaction, You must use Your own credit/ debit card.
                The information provided by You will not be utilized or shared
                with any third party unless required in relation to fraud
                verifications or by law, regulation or court order. You will be
                solely responsible for the security and confidentiality of Your
                credit/ debit card details. Superceuticals expressly disclaims
                all liabilities that may arise as a consequence of any
                unauthorized use of Your credit/ debit card.
              </p>

              <h1 className="font-bold text-xl py-2">
                {" "}
                7. Delivery of the Product
              </h1>
              <p className="text-left py-1">
                7. Your shipping address, pin code will be verified with the
                database of Superceuticals before You proceed to pay for Your
                purchase. In the event case your order is not serviceable by our
                delivery partners or the merchant or the area is not covered, we
                would request you to provide us with an alternate shipping
                address which we expect to have on our partner/merchant's
                delivery list. In case there is any dispute regarding the
                shipment of the product or services for the area not covered by
                Superceuticals, in such cases Superceuticals will not be
                responsible for the non-delivery of the product. Superceuticals
                doesn't deliver items internationally, unless through express
                written consent by Superceuticals. However, you can make
                purchases on the website from anywhere in the world but at the
                same time ensuring the shipping address is within India. In case
                you book multiple orders for the Products and Services in one
                transaction, Superceuticals would endeavour to ship all Products
                together. However, this may not always be possible due to some
                product characteristics and/or logistics' issues. If You
                purchase multiple Products in single transaction, then all the
                Products would be dispatched to a single shipping address given
                by You. If You wish to ship Products to different addresses,
                then You should book the orders separately based on the delivery
                addresses.
              </p>

              <h1 className="font-bold text-xl py-2">
                {" "}
                8. Return Policy for Products{" "}
              </h1>
              <p className="text-left py-1">
                8.1 In the event You receive a damaged / defective product or a
                product that does not comply with the specifications as per your
                original order, You are required to get in touch with the
                customer service team through any of the below mentioned
                channels: a. Centre at +91 70423 47476; b. Writ form; to us at :
                info@superceuticals.in
              </p>

              <p className="text-left py-1">
                8.2 Upon receiving Your complaint, Superceuticals shall verify
                the authenticity and the nature of the complaint and if
                Superceuticals is convinced that the complaint is genuine,
                Superceuticals will inform the relevant Vendor of such complaint
                and request for a replacement. However, in the event of
                frivolous and baseless complaints regarding the quality and
                content of the products, Superceuticals reserves the right to
                take necessary legal actions against You and You will be solely
                liable for all costs incurred by Superceuticals in this regard.
                You expressly acknowledge that the Vendor selling the defective
                product/ service will be solely responsible to You for any
                claims that You may have in relation to such defective product/
                service and Superceuticals shall not in any manner be held
                liable for the same.
              </p>
              <p className="text-left py-1">
                8.3 Before accepting shipment of any product, kindly ensure that
                the product's packaging is not damaged or tampered. If you
                observe that the package is damaged or tampered, request you to
                refuse to accept delivery and inform Superceuticals at the
                earliest. The return process of the product may be restricted by
                Superceuticals depending on the nature and category of the
                product.
              </p>

              <p className="text-left py-1">
                8.4 In order to return any products sold through the Website,
                You are required to comply with the below mentioned conditions,
                viz:
              </p>
              <ol className="list-decimal list-inside">
                <li className="text-left py-1">
                  1. Please notify Superceuticals of receipt of a
                  damaged/defective product/service within 48-hours of delivery
                  to you. If You are unable to do so within 48-hours,
                  Superceuticals shall not be held liable for the failure to
                  replace the order;
                </li>
                <li className="text-left py-1">
                  2. Products should be unused;
                </li>
                <li className="text-left py-1">
                  3. Superceuticals will arrange pick-up of the
                  damaged/defective product through its own logistics partner.
                  In the event Superceuticals is unable to do so, Superceuticals
                  will notify You regarding the same and You will be required to
                  dispatch the product using a reputed courier in Your
                  respective area within one (1) day from receipt of such
                  notice. Courier freight charges will be reimbursed in such
                  form as is determined by the logistics team upon prior
                  consultation with Superceuticals;
                </li>
                <li className="text-left py-1">
                  4. Products should be returned in their original packaging
                  along with the original price tags, labels, barcodes, user
                  manual, warranty card and invoices etc.;
                </li>
                <li className="text-left py-1">
                  5. It is advised that the return packets should be strongly
                  and adequately packaged so that there is no further damage of
                  products during transit;
                </li>
                <li className="text-left py-1">
                  6. The returned products are subject to verification and
                  checks by Superceuticals in order to determine the legitimacy
                  of the complaint/return.
                </li>
              </ol>

              <p className="text-left py-1">
                8.5 In the event the return of a product is duly accepted by
                Superceuticals, the value of such product, as originally paid by
                You during acceptance of delivery of the product or otherwise,
                will be refunded to You. Refund will be processed based on the
                mode of payment and Superceuticals or the approved payment
                gateway will credit Your refunds directly into Your debit/credit
                card or online accounts or provide You with a cheque in this
                regard. Refunds will be subject to the following:
              </p>
              <ol className="list-decimal list-inside">
                <li className="text-left py-1">
                  1. Orders paid online will be refunded within 7-21 working
                  days through the online account or via cheque, depending on
                  the then current circumstances as determined by
                  Superceuticals;
                </li>
                <li className="text-left py-1">
                  2. For Cash on Delivery payments, You will be provided with a
                  refund cheque;
                </li>
                <li className="text-left py-1">
                  3. If the product can be repaired by the service centre,
                  Superceuticals would get the same repaired and send it back to
                  You;
                </li>
                <li className="text-left py-1">
                  4. A promo code, once used shall not be refunded in case of
                  cancellation of order either by customer or Superceuticals.
                </li>
              </ol>

              <h1 className="font-bold text-xl py-2">9. Cancellation</h1>

              <p className="text-left py-1">
                9.1 Cancellation by Superceuticals: There may be certain orders
                that Superceuticals is unable to accept and must cancel.
                Superceuticals reserves the right, at Superceuticals' sole
                discretion, to refuse or cancel any order for any reason
                whatsoever. Some situations that may result in Your order being
                cancelled include, without limitation, non-availability of the
                product or quantities ordered by You, non-availability of the
                service, inaccuracies or errors in pricing information, or
                problems identified by Superceuticals' credit and fraud
                avoidance department. Superceuticals may also require additional
                verifications or information before accepting any order.
                Superceuticals will contact You if all or any portion of Your
                order is cancelled or if additional information is required to
                accept Your order. If Your order is cancelled after Your credit
                card has been charged, the said amount will be reversed back in
                Your credit card account. A promo code, once used shall not be
                refunded in case of cancellation of order either by Customer or
                Superceuticals.
              </p>

              <p className="text-left py-1">
                9.2 Cancellation by the User: In case of requests for order
                cancellations, Superceuticals reserves the right to accept or
                reject requests for order cancellations for any reason
                whatsoever. As part of usual business practice, if
                Superceuticals receives a cancellation notice and the order has
                not been processed/approved by Superceuticals, Superceuticals
                shall cancel the order and refund the entire amount to You
                within a reasonable period of time. Superceuticals will not be
                able to cancel orders that have already been processed.
                Superceuticals has the full right to decide whether an order has
                been processed or not. You agree not to dispute the decision
                made by Superceuticals and accept Superceuticals' decision
                regarding the cancellation.
              </p>

              <p className="text-left py-1">
                9.3 Superceuticals reserves the right to cancel any orders that
                classify as 'Bulk Order' as determined by Superceuticals as per
                certain criteria. Any Superceuticals Reward Point or Promo Code
                used for placing the 'Bulk Order' will not be refunded as per
                this cancellation policy. An order can be classified as 'Bulk
                Order' if it meets with the below-mentioned criteria, which may
                not be exhaustive, viz:
              </p>
              <ol className="list-decimal list-inside">
                <li className="text-left py-1">
                  Products ordered are not for self-consumption but for
                  commercial resale;
                </li>
                <li className="text-left py-1">
                  Multiple orders placed for the same product at the same
                  address;
                </li>
                <li className="text-left py-1">
                  Bulk quantity of the same product ordered;
                </li>
                <li className="text-left py-1">
                  Invalid address given in order details;
                </li>
                <li className="text-left py-1">
                  Any malpractice used to place the order.
                </li>
              </ol>

              <h1 className="font-bold text-xl py-2">
                {" "}
                10. Failure to Sell by the Vendor{" "}
              </h1>
              <p className="text-left py-1">
                Unless due to Your negligence, in the event the Vendor fails to
                complete a sale of a product or a service when presented by You
                with a valid Voucher or otherwise, You acknowledge that the
                vendor will be solely liable to reimburse such amount as has
                already been paid by You for the purchase of such order.
                Further, You agree that Superceuticals will not be held liable
                for the failure of the Vendor to make available any product or
                service therein.
              </p>

              <h1 className="font-bold text-xl py-2">11. Disclaimer </h1>

              <p className="text-left py-1">
                11.1 SUPERCEUTICALS DOES NOT CONTROL, ENDORSE OR ACCEPT
                RESPONSIBILITY FOR ANY PRODUCT (INCLUDING BUT NOT LIMITED TO
                PRODUCT CATALOGUES) OR SERVICES OFFERED BY THIRD PARTIES
                ACCESSIBLE THROUGH THE WEBSITE OR ANY LINKED SITES.
                SUPERCEUTICALS MAKES NO REPRESENTATIONS OR WARRANTIES WHATSOEVER
                ABOUT, AND SHALL NOT BE LIABLE FOR, THE VENDOR OR ANY SUCH THIRD
                PARTIES, THEIR PRODUCTS OR SERVICES, INCLUDING REPRESENTATIONS
                RELATING TO NON-INFRINGEMENT OF THIRD PARTY INTELLECTUAL
                PROPERTY RIGHTS. ANY TRANSACTIONS THAT YOU MAY HAVE WITH SUCH
                THIRD PARTIES ARE AT YOUR OWN RISK. THE PRODUCTS SHALL BE
                SUBJECT TO VENDOR'S TERMS AND CONDITIONS FOR WARRANTY, SERVICE
                AND MAINTENANCE, AND SUPERCEUTICALS DOES NOT ACCEPT ANY
                RESPONSIBILITY FOR THE SAME.
              </p>

              <p className="text-left py-1">
                11.2 SUPERCEUTICALS SPECIFICALLY DISCLAIMS ANY LIABILITY WITH
                REGARD TO ANY DEFECTIVE OR COUNTERFEIT PRODUCTS PURCHASED OR
                SERVICE AVAILED BY YOU FROM THE VENDOR AND SUPERCEUTICALS SHALL
                NOT ASSUME ANY LIABILITY IF THE PRODUCT PURCHASED OR SERVICE
                AVAILED BY YOU FROM THE VENDOR IS NOT EXACTLY AS PER
                SPECIFICATIONS DETAILED IN THE PURCHASE CONFIRMATION ORDER.
              </p>

              <h1 className="font-bold text-xl py-2">
                {" "}
                12. Indemnification and Limitation of Liability
              </h1>

              <p className="text-left py-1">
                12.1 You agree to indemnify, defend and hold harmless
                Superceuticals from and against any and all losses, liabilities,
                claims, damages, demands, costs and expenses (including legal
                fees and disbursements in connection therewith and interest
                chargeable thereon) asserted against or incurred by
                Superceuticals that arise out of, result from, or may be payable
                by virtue of, any breach of any representation, warranty,
                covenant or agreement made or obligation to be performed by You
                pursuant to these Terms of Offer For Sale or any Additional
                Terms applicable to the purchase of products and services on the
                Website.
              </p>

              <p className="text-left py-1">
                12.2 In no event shall Superceuticals, its officers, directors,
                employees, partners, or vendors be liable to You, the Vendor or
                any third party for any special, incidental, indirect,
                consequential or punitive damages whatsoever, including those
                resulting from the loss of use, data, or profits, whether or not
                foreseeable or whether or not Superceuticals has been advised of
                the possibility of such damages, or based on any theory of
                liability, including breach of contract or warranty, negligence
                or other tortious action, or any other claim arising out of or
                in connection with Your purchase of the products and services
                herein. Notwithstanding anything to the contrary,
                Superceuticals' entire liability to You under this Terms of
                Offer For Sale or otherwise shall be the refund of the money
                charged from You for any specific Voucher or product or service,
                under which the unlikely liability arises.
              </p>

              <p className="text-left py-1">
                12.3 Superceuticals shall not assume any liability for the
                non-availability of the product, delivery of the product, and
                the installation of the product where required or any action or
                inaction that might be taken by the Vendor after the issue of
                the Voucher to the User.
              </p>

              <h1 className="font-bold text-xl py-2">13. Governing Law </h1>

              <p className="text-left py-1">
                13.1 These Terms of Offer For Sale and the relationship between
                You and Superceuticals shall be governed in accordance with the
                laws of India without reference to conflict of laws principles.
              </p>

              <p className="text-left py-1">
                13.2 You agree that all claims, differences and disputes arising
                under or in connection with or in relation to the Terms of Offer
                For Sale or any transactions entered into on or through the
                Website or the relationship between You and Superceuticals shall
                be subject to the exclusive jurisdiction of the courts at Mumbai
                and You hereby accede to and accept the jurisdiction of such
                courts. ALL LINKED TERMS AND CONDITIONS HEREUNDER ARE ASSUMED TO
                BE READ, UNDERSTOOD AND AGREED BY YOU.
              </p>
            </Typography>
          </AccordionDetails>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TermsandCondition;
